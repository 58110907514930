import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// API
import axios from 'axios';

// 리코일
import { Recoil_Email, Recoil_Phone } from "../../state/PwFind";
import { useRecoilState} from 'recoil';

function PwFind2() {
	let navigate = useNavigate();
	
	// 이메일
	let [email , setEmail] = useState("");
	// 휴대폰 번호
	let [phone , setPhone] = useState("");
	// 리코일로 이베일, 전화번호 저장
	let [recoilEmail , setRecoilEmail] = useRecoilState(Recoil_Email);
	let [recoilPhone , setRecoilPhone] = useRecoilState(Recoil_Phone);

	// 인증하기
	function Authenticating() {
		if (!email) {
			alert("이메일을 입력해주세요");
		}else if (!phone) {
			alert("인증할 번호를 입력해주세요");
		}else{
			axios.post("https://api.kjeonse.com/api/v1/find/password/certificate", {
				email: email,
				phone: phone,
			})
			.then(function (response) {
				console.log(response.data);
				if (response.data.data.invalid) {
					stop_watch();
				}else{
					alert(response.data.msg);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	}

	// 인증하기 누른후 인증하기버튼 비활성화
	let [button_activation, setButton_activation] = useState(true);
	// 인증번호
	let [code , setCode] = useState("");
	// 인증완료 확인
	let [authentication_Chak, setAuthentication_Chak] = useState(false);

	
	

	// 타이머
	let [timer, setTimer] = useState();
	let time = 180;
	let min = ""; //분
	let sec = ""; //초

	// 타이머
	function stop_watch() {
		setButton_activation(false);
		
		let x = setInterval(() => {	
			min = Math.floor(time/60);
			sec = time%60;
			setTimer(min + "분" + sec + "초");
			time--
			if (time < 0) {
				clearInterval(x)
				setTimer("3분00초")
			}
		}, 1000);

		setTimeout(function(){
			setButton_activation(true);
		}, 180000);
	};

	// 인증확인
	function certified() {
		if (!code) {
			alert("인증번호를 입력해주세요")
		}else{
			axios.post("https://api.kjeonse.com/api/v1/find/password/certificate/check", {
				email: email,
				phone: phone,
				code: code,
			})
			.then(function (response) {
				console.log(response.data);
				alert(response.data.msg);
				if (response.data.data == 0) {
					setAuthentication_Chak(true);
					setButton_activation(true);
					setRecoilEmail(email);
					setRecoilPhone(phone);
				}else{
					setAuthentication_Chak(false);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	}

	// 다음
	function next_btn() {
		if (authentication_Chak) {
			navigate("/Account/PwFind3");
		}else{
			alert("인증이 완료되지 않았습니다.");
		}
	}

    return (
        <div className='account-page PWfind'>
			<div className='account-bg'>
				<div className='logo-wrap'>
					<img src={require("../../images/LOGO.png")} alt="" />
				</div>
				<div className='w-box'>
					<div className='h2-wrap'>
						<h2>비밀번호 찾기</h2>
						<p>비밀번호를 찾으려는 아이디를 입력해주세요.</p>
					</div>
					<div className='input-wrap'>
						<div className='input-box'>
							<input type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='아이디를 입력해 주세요.' />
						</div>
						{
							authentication_Chak ?
							<div className='input_btn_box'>
								<input type="number"  className='Deactivation' value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder='휴대폰 번호를 입력하세요(-없이 숫자만)' />
								<button className='Completion'>인증완료</button>
							</div>
							:
							button_activation ?
							<div className='input_btn_box'>
								<input type="number" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder='휴대폰 번호를 입력하세요(-없이 숫자만)' />
								<button onClick={()=>{Authenticating()}}>인증하기</button>
							</div>
							:
							<div className='input_btn_box'>
								<input type="number" className='Deactivation' value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder='휴대폰 번호를 입력하세요(-없이 숫자만)' />
								<button className='Deactivation' >인증하기</button>
							</div>
						}
						{
							!button_activation ?
							<div className='input_btn_box'>
								<input type="text" value={code} onChange={(e)=>{setCode(e.target.value)}} placeholder='인증번호를 입력하세요(일 최대 5회)' />
								<button onClick={()=>{certified()}}>인증확인</button>
								<p className='timer'>{timer}</p>
							</div>
							: ""
						}
					</div>
					<div className='btn-wrap'>
						<button className='next-btn' onClick={()=>{next_btn()}}>다음</button>
					</div>
				</div>
			</div>
		</div>
    )
}

export default PwFind2;