import 'antd/dist/antd.css';
import './index.css';
import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
// 스크롤시 최상단
import ScrollToTop from './components/ScrollTop';

import axios from 'axios';

// Recoil
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';


// 페이지 ======================================================================
// 해드푸터
import Mould from './components/Mould';
// 메인 
import Main from './pages/Main';
// 투자목록
import Investment from "./pages/Investment/Investment";
// 투자-상세
import InvestmentDT from "./pages/Investment/InvestmentDT";
// 이용안내
import Announcement from "./pages/Bulletin/Announcement"
// 뉴수
import News from "./pages/Bulletin/News"
// 이용안내상세
import AnnouncementDT from "./pages/Bulletin/AnnouncementDT"
// 뉴스상세
import NewsDT from "./pages/Bulletin/NewsDT"
// 자주하는 질문
import Question from "./pages/Bulletin/Question"

// 마이페이지============
// 대시보드
import Dashboard from "./pages/Mypage/Dashboard"
// 관심 투자 목록
import InterestInvestment from "./pages/Mypage/InterestInvestment"
// 정보 변경
import ChangeInfo from "./pages/Mypage/ChangeInfo"
// 포트폴리오
import Portfolio from "./pages/Mypage/Portfolio"
// 수익관리
import ProfitMg from "./pages/Mypage/ProfitMg"
// 전세 부동산 관리 인트로
import CharterIntro from "./pages/Mypage/CharterIntro"
// 임대인 등록
import LessorRG from "./pages/Mypage/LessorRG"
// 임차인 등록
import TenantRG from "./pages/Mypage/TenantRG"
// 전세 현황
import CharterCR from "./pages/Mypage/CharterCR"
// 임대차 계약 관리
import ContractMG from "./pages/Mypage/ContractMG"
// 임대차 계약 등록
import ContractMG_Registration from "./pages/Mypage/ContractMG_Registration"
// 임대차 계약 수정
import ContractMG_update from "./pages/Mypage/ContractMG_update"
// 임대차 계약 상세
import ContractMG_Details from "./pages/Mypage/ContractMG_Details"

// 시설 관리 / 문의
import FacilityMG from "./pages/Mypage/FacilityMG"
// 시설 관리 등록
import FacilityMG_Registration from "./pages/Mypage/FacilityMG_Registration"
// 시설 관리 상세
import FacilityMG_Details from "./pages/Mypage/FacilityMG_Details"

// 관리자 공지
import ManagerAN from "./pages/Mypage/ManagerAN"
// 관리자 공지 상세
import ManagerAN_Details from "./pages/Mypage/ManagerAN_Details"

// 마이페이지 상세
import MyDetails from "./pages/Mypage/MyDetails"

// 계정============
// 로그인
import LoginPage from "./pages/Account/Login"
// 회원가입 list
import JoinMBList from "./pages/Account/JoinMBList"
// 회원가입하기
import JoinMB from "./pages/Account/JoinMB"
// 회원가입완료
import JoinMBCompletion from "./pages/Account/JoinMBCompletion"
// 비밀번호 찾기1
import PwFind1 from "./pages/Account/PwFind1"
// 비밀번호 찾기2
import PwFind2 from "./pages/Account/PwFind2"
// 비밀번호 변경3
import PwFind3 from "./pages/Account/PwFind3"
// 비밀번호 변경완료
import PwCompletion from "./pages/Account/PwCompletion"
// 아이디찾기1
import IdFind1 from "./pages/Account/IdFind1"
// 아이디찾기2
import IdFind2 from "./pages/Account/IdFind2"
// 아이디찾기 완료
import IdCompletion from "./pages/Account/IdCompletion"

// 이용약관 ====================
// 이용약관
import Utilization from "./pages/Clause/Utilization"
// 개인정보처리방침
import Privacy_policy from "./pages/Clause/Privacy_policy"
// 개인정보처리방침
import Marketing from "./pages/Clause/Marketing"

// 이메일무단수집거부
import Reject_collection from "./pages/Clause/Reject_collection"

import OAuthNaver from './oauth/naver/OAuthNaver';

// 데이터
import { data } from './date';
import AddInfoJoin from './pages/Account/AddInfoJoin';

let obj = { name : 'kim' };

localStorage.setItem( 'data', JSON.stringify(obj) );
let josnObj = JSON.parse(localStorage.getItem('data'));
console.log(josnObj);

function App() {
  let [shose ,setShose] = useState(data);
  let navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('watched')) == '') {
      localStorage.setItem( 'watched' , JSON.stringify( [] ) )
    }
  }, [])


  // const [ isDetail, setIsDetail ] = useRecoilState(asdasdState);
  return (
    <div className="App">
      <RecoilRoot>
        <ScrollToTop /> 
        <Routes>
          <Route path='/' element={<Mould/>}>
            {/* 메인====================================================== */}
            <Route path='/' element={<Main shose={shose}></Main>}></Route>
            
            {/* 투자목록================================================== */}
            <Route path='/Investment' element={<Investment></Investment>}></Route>
            <Route path='/Investment/InvestmentDT/:id' element={<InvestmentDT></InvestmentDT>}></Route>

            {/* 게시판==================================================== */}
            {/* 이용안내 */}
            <Route path='/Bulletin/Announcement' element={<Announcement></Announcement>}></Route>
            {/* 뉴수 */}
            <Route path='/Bulletin/News' element={<News></News>}></Route>
            {/* 이용안내상세 */}
            <Route path='/Bulletin/AnnouncementDT/:id' element={<AnnouncementDT></AnnouncementDT>}></Route>
            {/* 뉴스상세 */}
            <Route path='/Bulletin/NewsDT/:id' element={<NewsDT></NewsDT>}></Route>
            {/* 자주하는 질문 */}
            <Route path='/Bulletin/Question' element={<Question></Question>}></Route>

            {/* 마이페이지================================================ */}
            {/* 대시보드 */}
            <Route path='/Mypage/Dashboard' element={<Dashboard></Dashboard>}></Route>
            {/* 관심 투자 목록 */}
            <Route path='/Mypage/InterestInvestment' element={<InterestInvestment></InterestInvestment>}></Route>
            {/* 정보 변경 */}
            <Route path='/Mypage/ChangeInfo' element={<ChangeInfo></ChangeInfo>}></Route>
            {/* 포트폴리오 */}
            <Route path='/Mypage/Portfolio' element={<Portfolio></Portfolio>}></Route>
            {/* 수익관리 */}
            <Route path='/Mypage/ProfitMg' element={<ProfitMg></ProfitMg>}></Route>
            {/* 전세 부동산 관리============= */}
            <Route path='/Mypage/CharterIntro' element={<CharterIntro></CharterIntro>}></Route>
            {/* 임대인 전세 등록 */}
            <Route path='/Mypage/LessorRG' element={<LessorRG></LessorRG>}></Route>
            {/* 임차인 전세 등록 */}
            <Route path='/Mypage/TenantRG' element={<TenantRG></TenantRG>}></Route>
            {/* 전세 현황 */}
            <Route path='/Mypage/CharterCR' element={<CharterCR></CharterCR>}></Route>

            {/* 임대차 계약 관리 */}
            <Route path='/Mypage/ContractMG/:id' element={<ContractMG></ContractMG>}></Route>
            {/* 임대차 계약 글등록 */}
            <Route path='/Mypage/ContractMG_Registration/:id' element={<ContractMG_Registration></ContractMG_Registration>}></Route>
            {/* 임대차 계약 글수정 */}
            <Route path='/Mypage/ContractMG_update/:id' element={<ContractMG_update></ContractMG_update>}></Route>
            {/* 임대차 계약 상세 */}
            <Route path='/Mypage/ContractMG_Details/:id' element={<ContractMG_Details></ContractMG_Details>}></Route>


            {/* 시설 관리 / 문의 */}
            <Route path='/Mypage/FacilityMG/:id' element={<FacilityMG></FacilityMG>}></Route>
            {/* 시설관리 글등록 */}
            <Route path='/Mypage/FacilityMG_Registration/:id' element={<FacilityMG_Registration></FacilityMG_Registration>}></Route>
            {/* 시설관리 상세 */}
            <Route path='/Mypage/FacilityMG_Details/:id' element={<FacilityMG_Details></FacilityMG_Details>}></Route>


            {/* 관리자 공지 */}
            <Route path='/Mypage/ManagerAN/:id' element={<ManagerAN></ManagerAN>}></Route>
            {/* 관리자 공지 상세 */}
            <Route path='/Mypage/ManagerAN_Details/:id' element={<ManagerAN_Details></ManagerAN_Details>}></Route>


            {/* 마이페이지 상세 */}
            <Route path='/Mypage/MyDetails' element={<MyDetails></MyDetails>}></Route>

            {/* 이메일무단수집거부 */}
            <Route path='/Clause/Reject_collection' element={<Reject_collection></Reject_collection>}></Route>

            {/* 404 */}
            <Route path='*' element={<div>404</div>}></Route>
          </Route>

          {/* 계정관리========================================================= */}
          {/* 로그인 */}
          <Route path='/Account/Login' element={<LoginPage></LoginPage>}></Route>
          {/* 회원가입 list */}
          <Route path='/Account/JoinMBList' element={<JoinMBList></JoinMBList>}></Route>
          {/* 회원가입하기 */}
          <Route path='/Account/JoinMB' element={<JoinMB></JoinMB>}></Route>
          {/* 회원가입완료 */}
          <Route path='/Account/JoinMBCompletion' element={<JoinMBCompletion></JoinMBCompletion>}></Route>
          {/* 비밀번호 찾기1 */}
          <Route path='/Account/PwFind1' element={<PwFind1></PwFind1>}></Route>
          {/* 비밀번호 찾기2 */}
          <Route path='/Account/PwFind2' element={<PwFind2></PwFind2>}></Route>
          {/* 비밀번호 찾기3 */}
          <Route path='/Account/PwFind3' element={<PwFind3></PwFind3>}></Route>
          {/* 비밀번호 찾기 완료 */}
          <Route path='/Account/PwCompletion' element={<PwCompletion></PwCompletion>}></Route>
          {/* 아이디찾기1 */}
          <Route path='/Account/IdFind1' element={<IdFind1></IdFind1>}></Route>
          {/* 아이디찾기2 */}
          <Route path='/Account/IdFind2' element={<IdFind2></IdFind2>}></Route>
          {/* 아이디찾기 완료 */}
          <Route path='/Account/IdCompletion' element={<IdCompletion></IdCompletion>}></Route>

          {/* 이용약관========================================================== */}
          {/* 이용약관 */}
          <Route path='/Clause/Utilization' element={<Utilization></Utilization>}></Route>
          {/* 개인정보처리방침 */}
          <Route path='/Clause/Privacy_policy' element={<Privacy_policy></Privacy_policy>}></Route>
          {/* 마케팅 */}
          <Route path='/Clause/Marketing' element={<Marketing></Marketing>}></Route>

          <Route path='/oauth/naver' element={<OAuthNaver/>}></Route>

          <Route path='/info' element={<AddInfoJoin />}></Route>
          
        </Routes>
      </RecoilRoot>
    </div>
  );
}

export default App;
