import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import util from '../util/util';

// API
import axios from 'axios';

function LoginPage() {

	let navigate = useNavigate();

	// 메인 api 데이터
    let [data, setData] = useState();

	// 이메일, 비밀번호
	let [email, setEmail] = useState();
	let [password, setPassword] = useState();

	const { naver, google } = window;


	function inquiryEnroll() {
        axios.post("https://api.kjeonse.com/api/v1/login", {
			email: email,
			password: password,
		})
		.then(function (response) {
			setData(response.data.data.original)
		})
		.catch(function (error) {
			console.log(error);
		});
    }


	// 로그인
	useEffect(()=>{
		if (data) {
			console.log(data);
			if (data.data.token) {
				alert("로그인이 완료되었습니다")
				localStorage.setItem( 'k-Jeonse-token', data.data.token.access_token );
				localStorage.setItem( 'k-Jeonse-User_Name', data.data.name );
				navigate("/")
			}else{
				alert(data.msg);
			}
		}
	},[data])


	const handleOnKeyPress = e => {
		if (e.key === 'Enter') {
			inquiryEnroll(); // Enter 입력이 되면 클릭 이벤트 실행
		}
	};


	const kakaoLoginHandle = () => {

		const scope = 'account_email';

		window.Kakao.Auth.login({
			scope,
			success: function(response) {
				window.Kakao.Auth.setAccessToken(response.access_token);
				window.Kakao.API.request({
					url: "/v2/user/me",
					success: function({id}) {
						
						axios.post('https://api.kjeonse.com/api/v1/sns/kakao/login', {
						
							id: id,
							type: 'kakao',
		
						}).then((res) => {
		
							if(res.data.success) {
								
								if(res.data.data.code == 2) {
		
									window.localStorage.setItem('k-Jeonse-user-type', 'kakao');    
									window.localStorage.setItem('k-Jeonse-user_id', id);    
									window.location.href = '/info';
		
								} else {
		
									window.localStorage.setItem('k-Jeonse-token', res.data.data.token.access_token);
									window.location.href = '/Mypage/Dashboard';
								}
								
							} else {
		
								if(res.data.msg) {
									alert(res.data.msg);
								}
		
							}
						})
		
					},
					fail: function(error) {
						
					}
				});
			},
			fail: function(error) {
				kakaoLoginHandle();
				console.log('error', error);
			}
		});

	};


	const googleLoginHandle = () => {};


	const naverLoginHandle = () => {
		
		const naver_client_id="AF74uxAzabWw_ddU3zjx";
		
		const naverLogin = new naver.LoginWithNaverId({
			clientId: naver_client_id,
			callbackUrl: "http://kjeonse.com/oauth/naver",
			isPopup: false,
			loginButton: { color: 'green', type: 1},
		});

		naverLogin.init();
	};


	const setUpGoogleLogin = () => {

		const src = "https://accounts.google.com/gsi/client";

		let googleScript = util.checkScript('gsi/client', src, setUp, 'google');

		function setUp() {
			window.google.accounts.id.initialize({
				client_id: "300209435760-b0m425lpd5j396lidaa0t92iql0v69a1.apps.googleusercontent.com",
				callback: (res) => {
					
					const payload = util.decodePaylod(res.credential);

					axios.post('https://api.kjeonse.com/api/v1/sns/google/login', {
						
							id: payload.aud,
							type: 'google',
		
						}).then((res) => {
		
							if(res.data.success) {
								
								if(res.data.data.code == 2) {
		
									window.localStorage.setItem('k-Jeonse-user-type', 'google');    
									window.localStorage.setItem('k-Jeonse-user_id', payload.aud);    
									window.location.href = '/info';
		
								} else {
		
									window.localStorage.setItem('k-Jeonse-token', res.data.data.token.access_token);
									window.location.href = '/Mypage/Dashboard';
								}
								
							} else {
		
								if(res.data.msg) {
									alert(res.data.msg);
								}
		
							}
						})
					

					util.removeScript(googleScript)

					const headChildren = document.head.children

					for (let i = 0; i < headChildren.length; i++) {
						if (headChildren[i]?.id?.includes('googleidentityservice') ?? false) {
							util.removeScript(headChildren[i])
						}
					}
				}
			});

			window.google.accounts.id.renderButton(
				document.getElementById('googleLogin'),
				{ theme: "outline", type: "icon", shape: 'circle' }
			)
		}

	};
	
	
	// kakao, naver login sdk init
	useEffect(() => {

		const kakako_client_id="4c6ca53dec6e3fdc0d1073387f46dff2";

		if(!window.Kakao.isInitialized()) {
			window.Kakao.init(kakako_client_id);
		}

		naverLoginHandle();
		setUpGoogleLogin();
	
	}, [])


    return (
        <div className='account-page login-page'>
			<div className='logo-wrap'>
				<img 
					onClick={()=>{navigate("/")}} 
					src={require("../../images/W-LOGO.png")} 
					alt="" 
				/>
				<p>
					모두가 만족하는 안전한 전세, <br />
					K-전세에서 현실이 됩니다	
				</p>
			</div>
			<div className='login-wrap'>
				<div className='login-box'>
					<div className='login-top'>
						<h2>Log in</h2>
						<div className='login-input'>
							<input 
								type="text" 
								placeholder='이메일을 입력해 주세요.' 
								onChange={(e)=>{setEmail(e.target.value)}} 
								onKeyPress={handleOnKeyPress} 
							/>
							<input 
								type="password" 
								name="" 
								placeholder='비밀번호를 입력해 주세요.' 
								onChange={(e)=>{setPassword(e.target.value)}} 
								onKeyPress={handleOnKeyPress} 
							/>
						</div>
						<button 
							className='login-btn' 
							onClick={()=>{inquiryEnroll()}}
						>
							로그인
						</button>

						<div className='find-wrap'>
							<Link to={"/Account/IdFind1"}>아이디 찾기</Link>
							<span>|</span>
							<Link to={"/Account/PWfind1"}>비밀번호 찾기</Link>
						</div>

						<div className='sns-login-wrap'>
							<p>
								<span>SNS</span> 
								계정 로그인
							</p>
							<ul className='sns-list'>
								<li>
									<button className='kakao' onClick={kakaoLoginHandle}></button>
								</li>
								<li>
									<button className='naver' id='naverIdLogin' onClick={naverLoginHandle}></button>
								</li>
								<li>
									<button className='google' id='googleLogin' onClick={googleLoginHandle}></button>
								</li>
								<li>
									<button className='apple'></button>
								</li>
							</ul>
						</div>
					</div>
					<button 
						className='join-box' 
						onClick={()=>{navigate("/Account/JoinMBList")}}
					>
						<div className='join-txt-wrap'>
							<p className='txt-top'>아직 회원이 아니신가요?</p>
							<p className='txt-bt'>회원가입 후 다양한 서비스를 만나보세요.</p>
						</div>
						<i className='xi-arrow-right'></i>
					</button>
				</div>
			</div>
		</div>
    )
}

export default LoginPage;