import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// 엔트디자인
import { Input } from 'antd';
import { Radio, Collapse } from 'antd';
import { Progress } from 'antd';
import { Pagination } from 'antd';

// API
import axios from 'axios';

// 리코일
import { NewsState } from "../../state/AnnouncementState";
import { useRecoilState, 
    useRecoilValue, 
    useSetRecoilState, 
    useResetRecoilState 
} from 'recoil';

function News() {
	let navigate = useNavigate();

	// 리코일로 페이지 관리
    const [asdasd, setAsdasd] = useRecoilState(NewsState); 

	// 메인 api 데이터
    let [data, setData] = useState();
	 // api 데이터
	useEffect(()=>{
        axios.get("https://api.kjeonse.com/api/v1/list/notice?page=" + asdasd).then((res) => {
            setData(res.data.data);
        }).catch(() => {console.log("실패");});
    },[]);
	console.log(data);

	// 검색
	const { Search } = Input;
	const onSearch = (e) => {
		axios.get("https://api.kjeonse.com/api/v1/search/notice?search="+ e).then((res) => {
            setData(res.data.data);
        }).catch(() => {console.log("실패");});
		setAsdasd(1);
		setCurrent(1);
	}

	// 페이지네이션
	const [current, setCurrent] = useState(asdasd);
	const PaginationChange = (page) => {
		console.log(page);
		setCurrent(page);
		setAsdasd(page);
		axios.get("https://api.kjeonse.com/api/v1/list/notice?page=" + page).then((res) => {
            setData(res.data.data);
        }).catch(() => {console.log("실패");});
	};

	// 태그 지우기
    function contentReplace(content) {
        var text = content;
        return text.replace(/(<([^>]+)>)/ig,"");
    }

    return (
        <div className='sub Bulletin-page News-page'>
            <div className='section1'>
				<div className='container'>
					<ul className='tab-li'>
						<li onClick={()=>{navigate("/Bulletin/Announcement")}}>이용안내</li>
						<li className='now' onClick={()=>{navigate("/Bulletin/News")}}>K-전세 소식</li>
						<li onClick={()=>{navigate("/Bulletin/Question")}}>자주하는 질문</li>
					</ul>
				</div>
			</div>
			<div className='section2'>
				<div className='container'>
					<div className='top-title'>
						<p className='title-p'>K-전세 소식</p>
						<div className='search-wrap'>
							<Search
							placeholder="검색어를 입력하세요"
							enterButton={<i className='xi-search'></i>}
							size="large"
							onSearch={onSearch}
							/>
						</div>
					</div>
					<ul className='bulletin-list'>
						{
							data ?
								data.list.data == 0 ?
									<div className='noData-wrap'>
										<p>검색 결과가 없습니다.</p>
									</div>
								:
								data.list.data.map((item, i)=>{
									if (item.top == 1) {
										return(
											<li key={i} className='anno' onClick={()=>{navigate("/Bulletin/NewsDT/" + item.id)}}>
												<div className='title-wrpa'>
													<p className='date-p'>
														{item.created_at.substr(0, 10)}
													</p>
													<div className='title'>
														<span>공지</span>
														<p>{item.title}</p>
													</div>
												</div>
												<i className='xi-plus'></i>
											</li>
										)
									}else{
										return(
											<li key={i} onClick={()=>{navigate("/Bulletin/NewsDT/" + item.id)}}>
												<div className='title-wrpa'>
													<p className='date-p'>
														{item.created_at.substr(0, 10)}
													</p>
													<div className='title'>
													<p>{item.title}</p>
													</div>
												</div>
												<i className='xi-plus'></i>
											</li>
										)
									}
								})
							:""
						}
					</ul>
					<Pagination current={current} onChange={PaginationChange} total={data? data.list.total : ""} defaultPageSize={10} />
				</div>
			</div>
        </div>
    )
}

export default News;