import axios from 'axios';

let data = [
    {
        title : '11',
        link : '/1',
    },
    {
        title : '22',
        link : '/2',
    },
    {
        title : '33',
        link : '/3',
    },
]

let data2 = [
    {
        key : 0,
        title : '11',
        link : '/',
    },
    {
        key : 1,
        title : '22',
        link : '/',
    },
    {
        key : 2,
        title : '33',
        link : '/',
    },
]

export {data, data2};