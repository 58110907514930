import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';
// 차트그래프
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// API
import axios from 'axios';
ChartJS.register(ArcElement, Tooltip, Legend);


function Dashboard() {
	let navigate = useNavigate();

	// 회원 상태관리
    let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));

	// 메인 api 데이터
	let [stockData, setStockData] = useState();
    let [jeonseData, setJeonseData] = useState();
	let [stock_detail, setStock_detail] = useState();

	const [ totalAmount, 	setTotalAmount ] 	= useState('');
	const [ stockedAmount, 	setStockedAmount ] 	= useState('');
	const [ depositAmount, 	setDepositAmount ] 	= useState('');
	const [ dividendAmount, setDividendAmount ] = useState('');

	const [ canWithDrawAmount, 	setCanWithDrawAmount ] 	= useState('');
	const [ sumDividendAmount, 	setSumDividendAmount ] 	= useState('');
	const [ sumDividendPercent, setSumDividendPercent] 	= useState('');

	const [ stockedCount, 		setStockedCount ] 		= useState('');
	const [ canUseStockAmount, 	setCanUseStockAmount ] 	= useState('');

	const [ registerCount, 		setRegisterCount ] 		= useState('');
	

	// api 데이터
	useEffect(()=>{

		axios.post("https://api.kjeonse.com/api/v1/dashboard", { user_id: userState }, { headers: { Authorization: "Bearer " + tokenState , }})
		.then(function (response) {

			setJeonseData(response.data.data.jeonse_section);
			setStock_detail(response.data.data.stock_detail_section);

			const data = response.data.data.stock_info_section;
			
			/*
				stock_info_section
				- can_stock_amount 			: 투자 가능한 소지 금액
				- can_use_withDraw_amount 	: 출금 가능한 소지 금액
				- now_dividend_amount 		: 현재 수익(배당) 금액
				- now_stocked_amount 		: 현재 투자 중인 금액
				- sum_total_dividend_amount : 전체 누적수익(배당) 금액
				- sum_total_stock_amount 	: 전체 누적투자 금액
				- total_amount 				: 자산 총액
			*/

			setTotalAmount(comma(data.total_amount));  						// 자산 총액 ( 투자금액, 예치금 )
			setStockedAmount(comma(data.now_stocked_amount));				// 현재 투자 중인 금액		                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
			setDepositAmount(comma(data.can_stock_amount));					// 출금 가능한 소지 금액
			setDividendAmount(comma(data.now_dividend_amount));				// 현재 수익(배당) 금액

			setCanWithDrawAmount(comma(data.can_use_withDraw_amount));		// 출금 가능한 소지 금액
			setSumDividendAmount(comma(data.sum_total_dividend_amount));	// 전체 누적수익(배당) 금액

			// 모든 투자금액 대비 누적 배당금의 수익률
			const dividendPercent = (data.sum_total_dividend_amount / data.total_amount) * 100;
			setSumDividendPercent(((dividendPercent.toFixed(2))));

			setStockedCount(data.stock_ing_contest_count);				// 투자 중인 공모전 건수
			setCanUseStockAmount(comma(data.can_stock_amount));			// 투자 가능한 소지 금액

			setRegisterCount(comma(data.register_jeonse_count))			// 등록된 전세 건수
		})
		.catch(function (error) {
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})

    },[]);

	function comma(num){

		var len, point, str;  
		  
		num = num + "";  
		point = num.length % 3 ;
		len = num.length;  
	  
		str = num.substring(0, point);  
		while (point < len) {  
		    if (str != "") str += ",";  
		    str += num.substring(point, point + 3);  
		    point += 3;  
		}  
		
		return str;
	
	}

	// 콤마 지우기 함수
	function commaOut(e) {
		const number = Number(e.toString().split(',').join(""));
		return(number);
	}

	// Graph Data
	const expData = {
		// labels: ["투자금액", "예치금"],
		datasets: [
			{
			labels: ["투자금액", "예치금"],
			data: [commaOut(stockedAmount), commaOut(depositAmount)],
			borderWidth: 2,
			hoverBorderWidth: 3,
			backgroundColor: [
				"#005ac8",
				"#0ba1de",
				"#0bcfd4"
			],
			fill: false
			}
		]
	};

	// Graph Section "수익금 내역 보기" 버튼 클릭 시, 수익 관리 페이지로 이동
	const moveToPage = () => { window.location.href = '/Mypage/ProfitMg'; }

    return (
        <div className='Mypage MyDashboard big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map1"></MypageMap>
			</div>
			<div className='MyContent-wrap'>

				{/* Graph */}
				<div className='section1'>
					<div className='flex-wrap'>
						<div className='flex-le w-box'>
							<div className='chart-wrap'>
								<Doughnut data={expData} />
							</div>
							<div className='chart-le'>
								<div className='my-property'>
									<h3>내 보유자산</h3>
									<p>{totalAmount}원</p>
								</div>
								<ul className='split-list'>
									<li>
										<div className='color-wrap'>
											<span className='color'></span>
											<p>투자금액</p>
										</div>
										<p className='percentage'>100%</p>
										<p className='won'>{stockedAmount}원</p>
									</li>
									<li>
										<div className='color-wrap'>
											<span className='color'></span>
											<p>예치금</p>
										</div>
										<p className='percentage'>100%</p>
										<p className='won'>{depositAmount}원</p>
									</li>
									<li>
										<div className='color-wrap'>
											<span className='color'></span>
											<p>투자수익</p>
										</div>
										<p className='won'>{sumDividendAmount}원</p>
									</li>
								</ul>
							</div>
						</div>
						<div className='flex-ri w-box'>
							<div className='withdrawal'>
								<h3>출금 가능 금액</h3>
								<div className='btn-wrap'>
									<button onClick={()=>{navigate("/Mypage/ProfitMg")}}>출금</button>
								</div>
								<p className='won'>{canWithDrawAmount}원</p>
							</div>
							<div className='profit'>
								<h3>누적 수익금</h3>
								<div className='profit-view-wrap' onClick={moveToPage}>
									<p className='profit-view'>수익금 내역 보기</p>
								</div>
								<p className='won'>{sumDividendAmount}원</p>
								<div className='percentage-wrap'>
									<div className='percentage-box'>
										<p>누적수익</p>
										<span className='percentage'>+ {sumDividendPercent} % </span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Counter */}
				<div className='section2'>
					<div className='flex-wrap4'>
						<div className='flex-box w-box'>
							<h3>투자중인 상품</h3>
							<p>{stockedCount}건</p>
						</div>
						<div className='flex-box w-box'>
							<h3>등록된 전세</h3>
							<p>{registerCount}건</p>
						</div>
						<div className='flex-box w-box'>
							<h3>투자가능 예치금</h3>
							<p>{canUseStockAmount}원</p>
						</div>
						<div className='flex-box w-box'>
							<h3>투자중인 금액</h3>
							<p>{stockedAmount}원</p>
						</div>
					</div>
				</div>

				{/* Jeonse List */}
				<div className='section3'>
					<div className='h2-wrap'>
						<h2>전세현황</h2>
					</div>
					<div className='simple-wrap'>
						{
							jeonseData ?

								jeonseData.length != 0 ?

									jeonseData.map((item,i)=>{
										return(
											<div className='simple-box w-box' key={i}>
												<div className='title-wrap'>
													<p className='title'>{item.title}</p>
													{
														item.isLessor == 0 ? 
															<span>임차</span>
														:
															<span>임대</span>
													}
												</div>
												<p className='price'>
													{(item.contractor_total_amount).toLocaleString()}원
												</p>
												<ul className='Info-li'>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-maker'></i></div>
															<p>주 소</p>
														</div>
														<p className='content-p'>{item.address}</p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-calendar-check'></i></div>
															<p>전세기간</p>
														</div>
														<p className='content-p'>
															{item.contractor_open_at.slice(0,4)}년 {item.contractor_open_at.slice(5,7)}월 {item.contractor_open_at.slice(8,10)}일 
															~
															{item.contractor_closed_at.slice(2,4)}년 {item.contractor_closed_at.slice(5,7)}월 {item.contractor_closed_at.slice(8,10)}일 
														</p>
													</li>
												</ul>
											</div>
										)
									})
								: 
									<div className='no_content w-box'>전세내용이 없습니다</div>
							: 
								""
						}
					</div>
				</div>
					
				{/* Stock List */}
				<div className='section4'>
					
					<div className='h2-wrap'>
						<h2>투자현황</h2>
					</div>
					<div className='simple-wrap'>
						{
							stock_detail ?
								stock_detail.length != 0 ?
									stock_detail.map((item, i)=>{
										return(
											<div className='simple-box w-box' key={i}>
												<div className='yield-box'>
													<p>수익률</p>
													<span>+ {Math.round(Number(item.totalSumDividendAmount) / Number(item.totalSumStockAmount) * 100)}%</span>
												</div>
												<div className='title-wrap'>
													<p className='title'>{item.title}</p>
												</div>
												<p className='price price2'>
													{Number(item.totalSumStockAmount).toLocaleString()}원
												</p>
												<ul className='Info-li'>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-calendar-check'></i></div>
															<p>참 여 일</p>
														</div>
														<p className='content-p'>
															{item.involve_date.slice(0,4)}년 {item.involve_date.slice(5,7)}월 {item.involve_date.slice(8,10)}일 
														</p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-money'></i></div>
															<p>누적수익</p>
														</div>
														<p className='content-p'>{item.totalSumDividendAmount ? Number(item.totalSumDividendAmount).toLocaleString() : 0} 원</p>
													</li>
												</ul>
											</div>
										)
									})	
								: 
									<div className='no_content w-box'>투자내용이 없습니다</div>
							: 
								""
						}
					</div>
				</div>
			</div>
        </div>
    )
}

export default Dashboard;