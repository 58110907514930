import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';

// 앤트디자인
import { Select } from 'antd';
import { StarOutlined, PaperClipOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

// 에디터
import  {  useQuill  }  from  'react-quilljs' ; 
import  'quill/dist/quill.snow.css' ;

function ContractMG_Registration() {
	let navigate = useNavigate();

	let { id } = useParams();
    console.log(id);

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 등록할 데이터 관리
	// 임대차 계약 리스트의 PK
	let [estate,setEstate ] = useState(id);
	// 제목
	let [title, setTitle] = useState("");
	// 내용
	let [content, setContent] = useState();
	// 업로드 파일 리스트
	let [file_list, setFile_list] = useState();

	// 등록할 데이터들
	let formData = new FormData();
	formData.append('estate_id', estate);
	formData.append('title', title);
	formData.append('content', content);
	useEffect(()=>{
		if (file_list) {
			file_list.map(item => {
				formData.append('files[]', item.originFileObj);
				console.log(item.originFileObj)
			})
		}
	},[file_list])

	// 등록하기
	function Register() {
		if(title == ""){
			alert("제목을 입력해주세요")
		}else if(content == undefined){
			alert("내용을 입력해주세요")
		}else{
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/register/contractor", formData,
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
				alert("잘못된 접근입니다");
				navigate("/");
				window.location.reload();
			})
			alert("등록이 완료되었습니다");
			navigate(-1);
		}
	}


	// 파일업로드
	const props = {
		name: 'file',
		beforeUpload: file => {
			return false;	// 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
		},
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				// console.log(info.file, info.fileList);
				setFile_list(info.fileList);
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onRemove(info) {
			console.log(info);
		}
	};
	// console.log(file_list);

	// 내용 에디터
    const { quill, quillRef } = useQuill();
    // console.log(quill);    // undefined > Quill Object
    // console.log(quillRef);
	useEffect(()=>{
		if (quill) {
			quill.on('text-change', (delta, oldDelta, source) => {
				// console.log('Text change!');
				// console.log(quill.getText()); // Get text only
				// console.log(quill.getContents()); // Get delta contents
				console.log(quill.root.innerHTML); // Get innerHTML using quill
				// console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
				setContent(quill.root.innerHTML)
			});
		}
	},[quill])



    return (
        <div className='Mypage charter MyRegistration'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='MyRegistration-box w-box'>
					<div className='title-input'>
						<p>제목</p>
						<input type="text" name="" id="" value={title} placeholder='제목을 입력하세요' onChange={(e)=>{setTitle(e.target.value)}}/>
					</div>
					<div className='editor-wrap'>
						<div ref={quillRef} />
					</div>
					<div className='file-wrap2'>
						<h4>파일첨부</h4>
						<Upload {...props}>
							<Button icon={<UploadOutlined />}>Upload</Button>
						</Upload>
					</div>
					<div className='registration-btn-wrap'>
						<button className='cancel-btn' onClick={()=>{navigate(-1)}}>취소</button>
						<button className='completion-btn' onClick={()=>{
							Register()
						}}>등록하기</button>
					</div>
				</div>
			</div>
        </div>
    )
}

export default ContractMG_Registration;