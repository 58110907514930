import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function IdFind1() {
	let navigate = useNavigate();

    return (
        <div className='account-page PWfind'>
			<div className='account-bg'>
				<div className='logo-wrap'>
					<img src={require("../../images/LOGO.png")} alt="" />
				</div>
				<div className='w-box'>
					<div className='h2-wrap'>
						<h2>아이디 찾기</h2>
						<p>인증 방법을 선택해주세요.</p>
					</div>
					<div className='btn-wrap'>
						<button className='way-btn' onClick={()=>{
							navigate();
						}}>
							<img src={require("../../images/sns-login-icon-01.png")} alt="" />
							<p>네이버 간편인증</p>
						</button>
						<button className='way-btn' onClick={()=>{
							navigate();
						}}>
							<img src={require("../../images/sns-login-icon-02.png")} alt="" />
							<p>카카오 간편인증</p>
						</button>
						<button className='way-btn' onClick={()=>{
							navigate("/Account/IdFind2");
						}}>
							<img src={require("../../images/phone.png")} alt="" />
							<p>휴대폰 인증</p>
						</button>
					</div>
				</div>
			</div>
		</div>
    )
}

export default IdFind1;