import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';
// 엔트디자인
import { Radio, Collapse } from 'antd';
// 앤트디자인
import { Checkbox, Col, Row } from "antd";
// API
import axios from 'axios';

function ChangeInfo() {
	let navigate = useNavigate();

	// 회원 상태관리
    let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));

	// 기본 api 데이터
    let [data, setData] = useState();
	// 기본 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage", {
			id: userState
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data)
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);

	// 변경할 전화번호
	let [phone, setPhone] = useState("");
	// 기존 번호랑 같으면 버튼 비활성화
	let [phoneChalk, setPhoneChalk] = useState(true);

	useEffect(()=>{
		if (data) {
			if (phone == data.user.phone) {
				setPhoneChalk(true);
			}else{
				setPhoneChalk(false);
			}
		}
	},[phone])

	// 인증하기
	function Authenticating() {
		if (!phone) {
			alert("변경할 번호를 입력해주세요");
		}else{
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/phone/certificate", {
				email: data.user.email,
				phone: phone,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
				},
			})
			.then(function (response) {
				console.log(response.data);
				if (response.data.data.invalid) {
					stop_watch();
				}else{
					alert(response.data.msg);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	}

	// 인증하기 누른후 인증하기버튼 비활성화
	let [button_activation, setButton_activation] = useState(true);
	// 인증번호
	let [code , setCode] = useState("");
	// 인증완료 확인
	let [authentication_Chak, setAuthentication_Chak] = useState(false);

	// 타이머
	let [timer, setTimer] = useState();
	let time = 180;
	let min = ""; //분
	let sec = ""; //초

	// 타이머
	function stop_watch() {
		setButton_activation(false);
		
		let x = setInterval(() => {	
			min = Math.floor(time/60);
			sec = time%60;
			setTimer(min + "분" + sec + "초");
			time--
			if (time < 0) {
				clearInterval(x)
				setTimer("3분00초")
			}
		}, 1000);

		setTimeout(function(){
			setButton_activation(true);
		}, 180000);
	};

	// 인증확인
	function certified() {
		if (!code) {
			alert("인증번호를 입력해주세요")
		}else{
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/phone/certificate/check", {
				phone: phone,
				email: data.user.email,
				code: code,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
				},
			})
			.then(function (response) {
				console.log(response.data);
				alert(response.data.msg);
				if (response.data.data == 0) {
					setAuthentication_Chak(true);
					setButton_activation(true);
				}else{
					setAuthentication_Chak(false);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	}




	// 유저 정보 변경
	let [updatechecked, setUpdateChecked] = useState(true)
	useEffect(()=>{
		if (data) {
			if (data.user.privacy_marketing == 0) {
				setUpdateChecked(true)
			}else if(data.user.privacy_marketing == 1){
				setUpdateChecked(false)
			}
		}
		setPhone(data ? data.user.phone : "");
	},[data])

	function UpdateInfo() {
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/userInfoUpdate", {
			user_id: userState,
			privacy_marketing: updatechecked
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
			},
		})
		.then(function (response) {
			alert("정보가 수정되었습니다");
		})
		.catch(function (error) {
			console.log(error);
		})
	}

	// 팝업 =======================
	// 계정삭제
    let [popOpen1 , setPopOpen1] = useState("");
    let [popOpen2 , setPopOpen2] = useState("");
	let [popOpen3 , setPopOpen3] = useState("");

    return (
        <div className='Mypage ChangeInfo big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map3"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='flex-wrap'>
					<div className='flex-le w-box'>
						<div className='h2-wrap'>
							<h2>정보변경</h2>
							<button className='elimination-btn' onClick={()=>{setPopOpen1(" open")}}>계정삭제</button>
						</div>
						<ul className='member-Info'>
							<li>
								<p className='labal-wrap'>이메일 <span>*</span></p>
								<p className='content-wrap'>
									{data ? data.user.email : ""}
								</p>
							</li>
							<li>
								<p className='labal-wrap'>비밀번호 <span>*</span></p>
								<p className='content-wrap'>●●●●●●●●</p>
								<button className='pw-change' onClick={()=>{setPopOpen3(" open")}}>비밀번호 변경</button>
							</li>
							<li>
								<p className='labal-wrap'>연락처 <span>*</span></p>
								<div className='input-box'>

									{/* <input type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
									<button className='certified-btn' onClick={()=>{Authenticating()}}>재인증</button> */}
									
									{
										authentication_Chak ?
										<>
										<input type="number"  className='Deactivation' value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
										<button className='certified-btn Completion'>인증완료</button>
										</>
										:
										button_activation ?
										<>
										<input type="number" value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
										{
											phoneChalk ?
											<button className='certified-btn Deactivation' onClick={()=>{Authenticating()}}>재인증</button>
											:
											<button className='certified-btn' onClick={()=>{Authenticating()}}>재인증</button>
										}
										</>
										:
										<>
										<input type="number" className='Deactivation' value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
										<button className='certified-btn Deactivation' >재인증</button>
										</>
									}
									{
										!button_activation ?
										<>
										<input type="text" value={code} onChange={(e)=>{setCode(e.target.value)}} />
										<button className='certified-btn' onClick={()=>{certified()}}>인증확인</button>
										<p className='timer'>{timer}</p>
										</>
										: ""
									}
								</div>
							</li>
							<li>
								<p className='labal-wrap'>이름(실명) <span>*</span></p>
								<p className='content-wrap'>
									{data ? data.user.name : ""}
								</p>
							</li>
							<li>
								<p className='labal-wrap'>생년월일 <span>*</span></p>
								<p className='content-wrap'>
									{data ? data.user.birth : ""}
								</p>
							</li>
						</ul>
						<div className='consent-wrap'>
							<div className='consent-title'>
								<p className='title'>약관동의</p>
							</div>
							<ul className='Consent-input-wrap'>
								<li>
									<input type="checkbox" name="" id="" className='fixation' checked readOnly />
									<p><a href='/Clause/Utilization' target='_blank' rel='noreferrer'>이용약관</a> 및 <a href='/Clause/Privacy_policy' target='_blank' rel='noreferrer'>개인정보처리방침</a>에 동의합니다(필수)</p>
								</li>
								<li>
									<input type="checkbox" name="" id="" onChange={(e)=>{setUpdateChecked(e.target.checked)}} checked={updatechecked} />
									<p><a href="/Clause/Marketing" target='_blank' rel='noreferrer'>마케팅 정보 수신</a> 에 동의합니다(선택)</p>
								</li>
							</ul>
						</div>
						<button className='correct-btn' onClick={()=>{UpdateInfo()}}>정보수정</button>
					</div>
					<div className='flex-ri'>
						<div className='big-btn-box btn-box1 w-box'>
							<div className='txt-box'>
								<div className='txt-wrap'>
									<p className='txt-top'>
										<span>전세</span> 주고 <br />
										<span>월세</span> 받는 <br />
										신개념 전세 펀드
									</p>
									<p className='txt-bt'>
										K-전세에 전세관리 등록하고, <br />
										월세처럼 다달이 수익금을 받아보세요!
									</p>
								</div>
							</div>
						</div>
						<div className='big-btn-box btn-box2 w-box'>
							<div className='txt-box'>
								<div className='txt-wrap'>
									<p className='txt-top'>
										<span>깡통전세,</span> <br />
										전세사기 걱정 끝!
									</p>
									<p className='txt-bt'>
										회원님의 소중한 전세금을 <br />
										100% 안전하게 보호해 드립니다
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='Popup-wrap'>
				{/* 회원탈퇴 */}
                <EliminationPop1 popOpen1={popOpen1} setPopOpen1={setPopOpen1} setPopOpen2={setPopOpen2} data={data} tokenState={tokenState} userState={userState}></EliminationPop1>
                <EliminationPop2 popOpen2={popOpen2} setPopOpen2={setPopOpen2}></EliminationPop2>
				{/* 비밀변경 */}
				<PasswordPop popOpen3={popOpen3} setPopOpen3={setPopOpen3} tokenState={tokenState} userState={userState}></PasswordPop>
            </div>
        </div>
    )
}

function EliminationPop1({popOpen1, setPopOpen1, setPopOpen2 , data, tokenState, userState}) {

	let [leaveList ,setLeaveList] = useState([]);

	const onChange1 = (checkedValues) => {
		setLeaveList(checkedValues);
	};

	function UserLeave() {
		let formData = new FormData();

		formData.append('user_id', userState);
		leaveList.map(item => {
			formData.append('reason[]', item);
			console.log(formData);
		})

		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/leave", formData,
		{
			headers: {
				Authorization: "Bearer " + tokenState ,
			},
		})
		.then(function (response) {
			console.log(response)
		})
		.catch(function (error) {
			console.log(error);
		})
	}

    return(
        <div className={'popup-bg EliminationPop1' + popOpen1}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen1("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <h2><span>K-전세</span> 계정 삭제</h2>
					<p className='p-st1'>
						계정을 삭제하면 모든 데이터가 삭제되며, 복구하실 수 없습니다. <br />
						정말 삭제를 원하신다면 아래 계정삭제 버튼을 눌러주세요.						
					</p>
					<p className='p-st2'>
						전세계약 기간이 남아있거나 보유자산이 있는 경우 삭제가 불가능합니다.	
					</p>
					<div className='ck-wrap'>
                        <div className='question-wrap'>
                            <div className='Q-wrap'>Q</div>
                            <p>계정을 삭제하려는 이유를 말씀해 주세요.</p>
                        </div>
                        <div className='ck-list'>
                            <Checkbox.Group onChange={onChange1}>
                                <Row>
									{
										data ?
										data.leaved_list.map((item, i)=>{
											return(
												<Col span={8} key={i}>
													<Checkbox value={item.id}>{item.title}</Checkbox>
												</Col>
											)
										})
										: ""
									}
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
					<div className='pop-btn-wrap1'>
						<button className='close-btn' onClick={()=>{
							setPopOpen1("");
							setPopOpen2(" open");
							UserLeave();
						}}>계정삭제</button>
						<button className='Registration-btn' onClick={()=>{
							setPopOpen1("");
						}}>취소</button>
					</div>
                </div>
            </div>
        </div>
    )
}

function EliminationPop2({popOpen2 , setPopOpen2}) {
    let navigate = useNavigate();
    return(
        <div className={'popup-bg EliminationPop2' + popOpen2}>
            <div className='popup-box'>
                {/* <button className='close-btn'
                    onClick={()=>{setPopOpen2("")}}
                ><i className='xi-close-thin'></i></button> */}
                <div className='popup-content'>
                    <div className='pop-h2-wrap'>
                        <i className='xi-check-circle'></i>
                        <h2>계정 삭제 완료</h2>
                    </div>
                    <div className='pop-p-box'>
                        <p>
							지금까지 K-전세와 함께해 주셔서 감사합니다. <br />
							더욱 더 노력하고 발전하는 K-전세가 되겠습니다.
                        </p>
                    </div>
                    <div className='pop-btn-wrap2'>
                        <button className='close-btn' onClick={()=>{
							setPopOpen2("");
							navigate("/");
							localStorage.removeItem('k-Jeonse-token');
							localStorage.removeItem('k-Jeonse-user_id');
						}}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PasswordPop({popOpen3 , setPopOpen3, tokenState, userState}) {
    let navigate = useNavigate();

	// 기존비밀번호
	let [existingPw , setExistingPw] = useState();
	// 새로운비밀번호
	let [newPw , setNewPw] = useState();
	// 비밀번호확인
	let [checkPw , setCheckPw ] = useState();

	function PasswordChange() {
		if (newPw == checkPw) {
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/changePassword", {
				user_id: userState,
				password: newPw,
				old_password: existingPw,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState ,
				},
			})
			.then(function (response) {
				console.log(response.data)
				if (response.data.success == false) {
					alert(response.data.msg);
				}else if(response.data.success == true){
					alert("비밀번호가 변경되였습니다");
					setPopOpen3("")
					window.location.reload();
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}else{
			alert("비밀번호가 일치하지않습니다")
		}
	}

    return(
        <div className={'popup-bg PasswordPop' + popOpen3}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen3("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <div className='Password-h2-wrap'>
						<h2>비밀번호 변경</h2>
						<p>새로운 비밀번호를 등록해 주세요</p>
					</div>
					<ul className='Password-input-ul'>
						<li>
							<input type="password" placeholder='기존 비밀번호를 입력해주세요' onChange={(e)=>{setExistingPw(e.target.value)}} />
						</li>
						<li>
							<input type="password" placeholder='새로운 비밀번호를 입력해주세요' onChange={(e)=>{setNewPw(e.target.value)}} />
						</li>
						<li>
							<input type="password" placeholder='비밀번호 확인' onChange={(e)=>{setCheckPw(e.target.value)}} />
						</li>
					</ul>
                    <div className='pop-btn-wrap2'>
                        <button className='close-btn' onClick={()=>{setPopOpen3("")}}>취소</button>
                        <button className='Registration-btn' onClick={()=>{PasswordChange()}}>확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeInfo;