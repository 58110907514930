import React from "react";
import axios from "axios";

const OAuthNaver = () => {

    const { naver } = window;

    // const url = 'https://localhost:3000/oauth/naver';
    const url = 'https://kjeonse.com/oauth/naver';

    const naverLogin = new naver.LoginWithNaverId(
        {
            clientId: "AF74uxAzabWw_ddU3zjx",
            callbackUrl: url,
            isPopup: false,
            callbackHandle: true
            /* callback 페이지가 분리되었을 경우에 callback 페이지에서는 callback처리를 해줄수 있도록 설정합니다. */
        }
    );
    
    naverLogin.init();

    naverLogin.getLoginStatus(function(status) {

        console.log(status);

        if(status) {

            axios.post('https://api.kjeonse.com/api/v1/sns/naver/login', {
                
                id: naverLogin.user.id

            }).then((res) => {

                if(res.data.success) {

                    if(res.data.data.code == 2) {

                        window.localStorage.setItem('k-Jeonse-user-type', 'naver');    
                        window.localStorage.setItem('k-Jeonse-user_id', naverLogin.user.id);    
                        window.location.href = '/info';

                    } else {

                        window.localStorage.setItem('k-Jeonse-token', res.data.data.token.access_token);
                        window.location.href = '/Mypage/Dashboard';
                    }
                    
                } else {

                    if(res.data.msg) {
                        alert(res.data.msg);
                    }

                }
            })
        }

    });


}

export default OAuthNaver;