import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// API
import axios from 'axios';

// 리코일
import { Recoil_Email, Recoil_Phone } from "../../state/PwFind";
import { useRecoilState} from 'recoil';



function PwFind3() {
	let navigate = useNavigate();

	// 리코일로 이베일, 전화번호 가져오기
	let [recoilEmail , setRecoilEmail] = useRecoilState(Recoil_Email);
	let [recoilPhone , setRecoilPhone] = useRecoilState(Recoil_Phone);

	// 변결할 비밀번호
	let [password , setPassword] = useState("");
	// 비밀번호 확인
	let [chalk_password , setChalk_password] = useState("");

	function next_btn() {
		if (!password) {
			alert("비밀번호를 입력해주세요");
		}else if (password != chalk_password) {
			alert("새로운 비밀번호와 확인 비밀번호가 다릅니다");
		}else{
			axios.post("https://api.kjeonse.com/api/v1/find/password/update", {
				email: recoilEmail,
				phone: recoilPhone,
				password: password,
			})
			.then(function (response) {
				console.log(response.data.data);
				if (response.data.data.code == 1) {
					navigate("/Account/PwCompletion");
				}else if (response.data.data.code == 2) {
					alert(response.data.msg);
				}else if (response.data.data.code == 3) {
					alert(response.data.msg);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	}

	const preventClose = (e) => {
		e.preventDefault();
		e.returnValue = ""; //Chrome에서 동작하도록; deprecated
	};
	
	// useEffect(() => {
	// 	(() => {
	// 		window.addEventListener("beforeunload", preventClose);
	// 	})();
		
	// 	return () => {
	// 		window.removeEventListener("beforeunload", preventClose);
	// 	};
	// }, []);

    return (
        <div className='account-page PWfind'>
			<div className='account-bg'>
				<div className='logo-wrap'>
					<img src={require("../../images/LOGO.png")} alt="" />
				</div>
				<div className='w-box'>
					<div className='h2-wrap'>
						<h2>비밀번호 변경</h2>
						<p>새로운 비밀번호를 등록해 주세요</p>
					</div>
					<div className='input-wrap'>
						<input type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder='새로운 비밀번호를 입력해주세요' />
						<input type="password" value={chalk_password} onChange={(e)=>{setChalk_password(e.target.value)}} placeholder='비밀번호 확인' />
					</div>
					<div className='btn-wrap'>
						<button className='next-btn' onClick={()=>{next_btn();}}>다음</button>
					</div>
				</div>
			</div>
		</div>
    )
}

export default PwFind3;