import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// 엔트디자인
import { StarOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

// API
import axios from 'axios';

function NewsDT() {
	let navigate = useNavigate();

	let { id } = useParams();
    console.log(id);

	// 메인 api 데이터
    let [data, setData] = useState();
	const [ files, setFiles ] = useState([]);

	 // api 데이터
	useEffect(()=>{
		axios.put("https://api.kjeonse.com/api/v1/detail/notice", {
			id: id,
		})
		.then(function (response) {
			setData(response.data.data);
		})
		.catch(function (error) {
			console.log(error);
		});
    },[id]);
	console.log(data);

	// 첨부파일
	useEffect(()=>{
		initialziedFiles();
	},[data])
	const initialziedFiles = () => {
		let files = [];
		if(data) {
			data.files.map((item, idx) => {
				files.push(
					{
						uid: idx,
						name: item.originFileName,
						status: 'done',
						url: item.fileAddress,
					},
				)	
			})
		}
		console.log(files);
		setFiles(files);
	}

	const props = {
		action: 'https://kjeonse.com',
		showUploadList: {
		showDownloadIcon: true,
		downloadIcon: [<div key={"0"}>다운로드<i className='xi-download'></i></div>],
		showRemoveIcon: true,
		removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
		},
	};

    return (
        <div className='sub BulletinDT'>
            <div className='section1'>
				<div className='container'>
					<div className='title-wrap'>
					<p className='title-p'>
							{
								data ? data.detail.title :""
							}
						</p>
						<p className='date-p'>
							{
								data ? data.detail.created_at.substr(0, 10) :""
							}
						</p>
					</div>
					<div className='content-wrap'>
						{
							data ? 
							<div dangerouslySetInnerHTML={{__html: data.detail.content}}>
							</div>
							:""
						}
					</div>
					<div className='file-wrap'>
						<h3><PaperClipOutlined />파일첨부</h3>
						<div className='file-list'>
							<Upload {...props} fileList={files}></Upload>
						</div>
					</div>
					<div className='page-change'>
						<button className='change-bt' onClick={()=>{
							if (data.previous == null) {
								alert("이전글이 없습니다")
							}else{
								navigate("/Bulletin/NewsDT/" + data.previous.id)
							}
						}}>
							<i className='xi-long-arrow-left'></i>
							이전글
						</button>
						<button className='list-btn' onClick={()=>{navigate("/Bulletin/News")}}>목록</button>
						<button className='change-bt' onClick={()=>{
							if (data.next == null) {
								alert("다음글이 없습니다")
							}else{
								navigate("/Bulletin/NewsDT/" + data.next.id)
							}
						}}>
							다음글
							<i className='xi-long-arrow-right'></i>
						</button>
					</div>
				</div>
			</div>
        </div>
    )
}

export default NewsDT;