import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

// 엔트디자인
import { Radio, Collapse } from 'antd';
// 앤트디자인
import { Checkbox, Col, Row } from "antd";

// 스와이파
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { EffectFade, Pagination } from "swiper";
import { Autoplay } from "swiper";

// jquery
import $ from 'jquery';

// API
import axios from 'axios';

// 리코일
import asdasdState from "../state/AnnouncementState";
import { useRecoilState, 
    useRecoilValue, 
    useSetRecoilState, 
    useResetRecoilState 
} from 'recoil';


function Main({ shose, setShose }) {
    let navigate = useNavigate();

    // 메인 api 데이터
    let [data, setData] = useState();

    // 기업 슬라이드 리스트
    let [associate, setAssociate] = useState();

    // api 데이터
    useEffect(()=>{
        axios.get("https://api.kjeonse.com/api/v1/main").then((res) => {
            console.log(res.data.data);
            let associate = res.data.data.associate;
            setAssociate(associate);
            setData(res.data.data);
        }).catch(() => {console.log("실패");});
    },[]);

    // News & Notice ==========================
    let [news_notice , setNews_notice] = useState(1);
    const news_notice_Change = (e) => {
        setNews_notice(e.target.value);
    };
    // 태그 지우기
    function contentReplace(content) {
        var text = content;
        return text.replace(/(<([^>]+)>)/ig,"");
    }

    // 알림내역 팝업 열고닫기
    let [popOpen1 , setPopOpen1] = useState("");
    let [popOpen2 , setPopOpen2] = useState("");

    console.log(data);

    // section3======================================
    // 스와이퍼 버튼
    const swiperRef1 = useRef();

    // section6===================================
    // 스와이퍼 버튼
    const swiperRef2 = useRef();

    // section7==================================
    const { Panel } = Collapse;

    // 오늘하루보지 않기 팝업 ===================================================================
    useEffect(()=>{
        var myPopup = document.querySelector(".popup"),
            checkbox = document.querySelector("#popup"),
            popupClose = document.querySelector(".close");
        // 쿠키 생성
        function setCookie(name, value, day) {
            var date = new Date(); // 현재 날짜 지정.
            date.setDate(date.getDate() + day);
            var mycookie = '';
            mycookie += name + "=" + value + ";";
            mycookie += "Expires=" + date.toUTCString();
            document.cookie = mycookie; // 쿠키 설정, 생성
        }
        // 쿠키 삭제
        function delCookie(name) {
            var date = new Date();
            date.setDate(date.getDate() - 1);
        };
        //쿠키 확인
        function checkCookie(name) {
            var cookies = document.cookie.split(";");
            console.log(cookies);
            var visited = false; // 방문 거짓
            for (var i in cookies) {
                if (cookies[i].indexOf(name) > -1) {
                    visited = true;
                    console.log(visited);
                }
            }
            console.log(visited);
            if (visited) {
                // 재방문
                myPopup.style.display = "none";
            }else{
                // 신규방문
                myPopup.style.display = "block";
            }
        }
        checkCookie('kjeonse.com');
        popupClose.addEventListener("click", function () {
            if (checkbox.checked) {
                //팝업을 다시 안보겠다. 팝업 닫고, 쿠키 생성.
                setCookie("kjeonse.com", "Main", 1);
                myPopup.style.display = "none";
            }else{
                //팝업을 계속 본다. 팝업 닫고, 쿠키 제거.
                myPopup.style.display = "none";
                delCookie("kjeonse.com");
            }
        })
    },[])

    // 메인 팝업 내용없을때 안보이게
    let [popCs, setPopCs] = useState("");
    useEffect(()=>{
        console.log(data);
        if (data) {
            if (data.popup.data.length === 0) {
                setPopCs("")
            }else{
                setPopCs("open")
            }
        }
    },[data])
    // =====================================================================================================

    return (
        <div className='main big-container'>
            <div className={'popupWrap ' + popCs}>
                <div className='popup'>
                    <div className='slide-wrap'>
                        <Swiper
                            pagination={true}
                            modules={[ Autoplay, Pagination]}
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={false}
                            autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            }}
                            >
                            {
                                data ?
                                data.popup.data ?
                                    data.popup.data.map((item, i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <div className='img-wrap'
                                                onClick={()=> window.open(item.url, '_blank')}>
                                                    <img src={item.fileAddress} alt="" />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                    :""
                                : null
                            }
                        </Swiper>
                    </div>
                    <div className='popup-btn-wrap'>
                        <label htmlFor="popup"><input type="checkbox" name="" id="popup" />Let's not see each other today</label>
                        <button className='close'>close</button>
                    </div>
                </div>
            </div>
            <div className='section1'>
                <div className='slide-wrap'>
                    <Swiper
                    effect={"fade"}
                    pagination={{ clickable: true }}
                    modules={[EffectFade, Pagination, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={false}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    // breakpoints={{
                    //     768: {
                    //         slidesPerView: 3,
                    //         spaceBetween: 40
                    //     },
                    // }}
                    >
                        {
                            data ?
                                data.banners.map((item, i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <div className='sl-le'>
                                                <div className='txt-wrap'>
                                                    <div className='txt-top'>
                                                        <p>
                                                            {item.title}
                                                        </p>
                                                    </div>
                                                    <div className='txt-bt'>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='sl-ri'>
                                                <div className='img-bg-wrap'>
                                                    <img src={item.fileAddress} alt="" />
                                                </div>
                                                <div className='color-bg'></div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            : ""
                        }
                    </Swiper>
                </div>
            </div>
            <div className='section2'>
                <div className='main-ct'>
                    <div className='main-ct-le'>
                        <div className='bg-wrap'>
                            <img src={require("../images/main-st1-left.png")} alt="" />
                        </div>
                    </div>
                    <div className='main-ct-ri'>
                        <div className='btn-wrap'>
                            {
                                localStorage.getItem('k-Jeonse-token')?
                                <Link to={"/Mypage/Dashboard"} className="le-btn">
                                    <p>K-전세 가입하기</p>
                                    <img src={require("../images/arrow.png")} alt="" />
                                </Link>
                                :
                                <Link to={"/Account/JoinMBList"} className="le-btn">
                                    <p>K-전세 가입하기</p>
                                    <img src={require("../images/arrow.png")} alt="" />
                                </Link>
                            }
                            <Link to={"/Investment"} className="ri-btn">
                                <p>K-전세 투자상품</p>
                                <img src={require("../images/arrow.png")} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section3'>
                <div className='main-ct st3-top'>
                    <div className='main-ct-le'>
                        <div className='chapter-num'>
                            <p className='num'>01</p>
                            <h2>Partners</h2>
                        </div>
                        <div className='slide-btn'>
                            <button onClick={() => swiperRef1.current.slidePrev()}><i className='xi-angle-left'></i></button>
                            <button onClick={() => swiperRef1.current.slideNext()}><i className='xi-angle-right'></i></button>
                        </div>
                    </div>
                    <div className='main-ct-ri'></div>
                </div>
                <div className='main-ct st3-bt'>
                    <div className='main-ct-le'>
                        <p>
                            믿을 수 있는 기업, 기관이 <br />
                            <span>K-전세</span>와 함께합니다
                        </p>
                    </div>
                    <div className='main-ct-ri'>
                        <p>
                            믿을 수 있는 기업, 기관이 <br />
                            <span>K-전세</span>와 함께합니다
                        </p>
                        <Swiper
                            onSwiper={(swiper) => {
                                swiperRef1.current = swiper;
                            }}
                            modules={[Autoplay]}
                            spaceBetween={16}
                            slidesPerView={"auto"}
                            loop={true}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                1200: {
                                    centeredSlides: false,
                                },
                            }}
                            >
                            {
                                associate ?
                                    associate.map((item, i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <img src={item.fileAddress} alt="" />
                                            </SwiperSlide>
                                        )
                                    })
                                :<SwiperSlide>
                                    <img src="" alt="" />
                                </SwiperSlide>
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className='section4' id='K-Jeonse'>
                <div className='main-ct'>
                    <div className='main-ct-le'>
                        <div className='chapter-num'>
                            <p className='num'>02</p>
                            <h2>About Us</h2>
                        </div>
                    </div>
                    <div className='main-ct-ri'>
                        {
                            data ?
                            data.about ?
                                data.about.map((item, i)=>{
                                    if(item.type == 0){
                                        return(
                                            <img key={i} className='pc-img' src={item.fileAddress} alt="" />
                                        )
                                    }else{
                                        return(
                                            <img key={i} className='mb-img' src={item.fileAddress} alt="" />
                                        )
                                    }
                                })
                                :""
                            : ""
                        }
                    </div>
                </div>
            </div>
            <div className='section5' id='section5'>
                <div className='txt-wrap'>
                    <p className='txt-top'>
                        <span className='sp1'>K-전세의 소식</span>을 <br />
                        <span className='sp2'>메일</span>로 받아보세요.
                    </p>
                    <p className='txt-bt'>
                        K-전세 소식을 가장 먼저 알려드리겠습니다.
                    </p>
                </div>
                <button onClick={()=>{setPopOpen1(" open");}}>K-전세 소식 받기</button>
            </div>
            <div className='section6' id='Bulletin-board'>
                <div className='main-ct'>
                    <div className='main-ct-le'>
                        <div className='chapter-num'>
                            <p className='num'>03</p>
                            <h2>
                                News <br />
                                & <span>Notice</span>
                            </h2>
                        </div>
                        <div className='slide-btn'>
                            <button onClick={() => swiperRef2.current.slidePrev()}><i className='xi-angle-left'></i></button>
                            <button onClick={() => swiperRef2.current.slideNext()}><i className='xi-angle-right'></i></button>
                        </div>
                    </div>
                    <div className='main-ct-ri'>
                        <Radio.Group onChange={news_notice_Change} defaultValue="1">
                            <Radio.Button value="1">K-전세소식</Radio.Button>
                            <Radio.Button value="2">이용안내</Radio.Button>
                        </Radio.Group>
                        <Swiper
                            onSwiper={(swiper) => {
                                swiperRef2.current = swiper;
                            }}
                            spaceBetween={24}
                            slidesPerView={"auto"}
                            loop={true}
                            centeredSlides={true}
                            breakpoints={{
                                768: {
                                    centeredSlides: false,
                                },
                            }}
                            >
                                {
                                    data ?
                                        news_notice == 1 ?
                                        data.notice ?
                                            data.notice.map((item, i)=>{
                                                return(
                                                    <SwiperSlide key={i} onClick={()=>{navigate('/Bulletin/NewsDT/' + item.id)}}>
                                                        <div className='ani-box'>
                                                            <div className='date-wrap'>
                                                                <p className='news-p'>K-전세소식</p>
                                                                <p className='date-p'>{item.created_at.substr(0, 10)}</p>
                                                            </div>
                                                            <p className='title-p'>
                                                                {item.title}
                                                            </p>
                                                            <p className='content-p'>
                                                                {contentReplace(item.content)}
                                                            </p>
                                                            <div className='More-wrap'>
                                                                <span>Read More</span>
                                                                <div className='arrow-img'></div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                            :""
                                        :
                                        data.guide ?
                                        data.guide.map((item, i)=>{
                                            return(
                                                <SwiperSlide key={i} onClick={()=>{navigate("/Bulletin/AnnouncementDT/" + item.id)}}>
                                                    <div className='ani-box'>
                                                        <div className='date-wrap'>
                                                            <p className='news-p'>이용안내</p>
                                                            <p className='date-p'>{item.created_at.substr(0, 10)}</p>
                                                        </div>
                                                        <p className='title-p'>
                                                            {item.title}
                                                        </p>
                                                        <p className='content-p'>
                                                            {contentReplace(item.content)}
                                                        </p>
                                                        <div className='More-wrap'>
                                                            <span>Read More</span>
                                                            <div className='arrow-img'></div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                        :""
                                    : <SwiperSlide></SwiperSlide>
                                }
                        </Swiper>
                        <button to={"/Bulletin/Announcement"} onClick={()=>{
                            if (news_notice == 1) {
                                navigate("/Bulletin/News")
                            }else{
                                navigate("/Bulletin/Announcement")
                            }
                        }} className="add-btn">더보기</button>
                    </div>
                </div>
            </div>
            <div className='section7'>
                <div className='main-ct'>
                    <div className='main-ct-le'>
                        <div className='chapter-num'>
                            <p className='num'>04</p>
                            <h2>FAQ</h2>
                        </div>
                    </div>
                    <div className='main-ct-ri'>
                        <h3>FAQ</h3>
                        <Collapse accordion>
                            {
                                data ?
                                data.faq ?
                                    data.faq.map((item, i)=>{
                                        return(
                                            <Panel header={
                                                <div className='panerl-hd'>
                                                    <div className='question-wrap'>
                                                        <span className='classification'>
                                                            {item.category}
                                                        </span>
                                                        <p className='question'>
                                                            {item.title}
                                                        </p>
                                                    </div>
                                                    <i className='xi-angle-down'></i>
                                                </div>
                                            } key={i}>
                                                <p>
                                                    {item.content}
                                                </p>
                                            </Panel>
                                        )
                                    })
                                : ""
                                : ""
                            }
                        </Collapse>
                        <Link className='add-btn' to={"/Bulletin/Question"}>더보기</Link>
                    </div>
                </div>
            </div>
            <div className='Popup-wrap'>
                <EmailRegistration1 popOpen1={popOpen1} setPopOpen1={setPopOpen1} setPopOpen2={setPopOpen2}></EmailRegistration1>
                <EmailRegistration2 popOpen2={popOpen2} setPopOpen2={setPopOpen2}></EmailRegistration2>
            </div>
        </div>
    );
}

function EmailRegistration1({popOpen1, setPopOpen1, setPopOpen2}) {

    // 이메일
	let [email, setEmail] = useState("");

    // 질문
	let [question1, setQuestion1] = useState();
	let [question2, setQuestion2] = useState();
	let [question3, setQuestion3] = useState();
	let [question, setQuestion] = useState();

    // 약관동의
	// 이용약관,개인정보처리방침
	let [agree, setAgree] = useState(1);
	// 마케팅
	let [marketing, setMarketing] = useState(1);

    // 질문 저장============================================================
	const onChange1 = (checkedValues) => {
		console.log("checked = ", checkedValues);
		setQuestion1(checkedValues);
	};
	const onChange2 = (e) => {
		console.log('radio checked', e.target.value);
		setQuestion2(e.target.value);
	};
	const onChange3 = (checkedValues) => {
		console.log("checked = ", checkedValues);
		setQuestion3(checkedValues);
	};

    useEffect(()=>{
		let all_question = [];
		let copy = all_question.concat(question1, question2, question3)
		setQuestion(copy);
	},[question1, question2, question3]);

    // 등록할 데이터들
	let formData = new FormData();
    formData.append('email', email);
    formData.append('agree', agree);
	formData.append('marketing', marketing);

    function registration() {
        if (question) {
			question.map(item => {
				if (item != undefined) {
					formData.append('survy[]', item);
				}
			})
		}
        if (!email) {
            alert("이메일 주소를 입력해주세요");
        }else if (agree != 0) {
            alert("이용약관 및 개인정보처리방침에 동의해주세요");
        }else{
            axios.post("https://api.kjeonse.com/api/v1/mailing", formData)
            .then(function (response) {
                setPopOpen1("");
                setPopOpen2(" open");
            })
            .catch(function (error) {
                console.log(error);
                alert("잘못된 접근입니다");
                window.location.reload();
            })
        }
    }


    return(
        <div className={'popup-bg EmailRegistrationPop1' + popOpen1}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen1("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <h2><span className='h2-st1'>K-전세의 소식</span>을 <br />
                    <span className='h2-st2'>메일</span>로 받아보세요</h2>
                    <p className='p-st1'>입력하신 정보는 참고용으로만 사용되며 안전하게 보관됩니다.</p>
                    <div className='ck-wrap'>
                        <div className='question-wrap'>
                            <div className='Q-wrap'>Q</div>
                            <p>K-전세에서 관심있는 항목을 선택하세요 <span>(다중선택 가능)</span></p>
                        </div>
                        <div className='ck-list'>
                            <Checkbox.Group onChange={onChange1}>
                                <Row>
                                    <Col span={8}>
                                        <Checkbox value={1}>임대</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={2}>임차</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={3}>투자</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={4}>기타</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div className='ck-wrap'>
                        <div className='question-wrap'>
                            <div className='Q-wrap'>Q</div>
                            <p>임대 또는 임차하고자 하는 전세금은 대략 얼마인가요?</p>
                        </div>
                        <div className='ck-list'>
                            <Radio.Group onChange={onChange2} value={question2}>
                                <Radio value={5}>1억 이하</Radio>
                                <Radio value={6}>1억 ~ 2억</Radio>
                                <Radio value={7}>2억 ~ 3억</Radio>
                                <Radio value={8}>3억 ~ 4억</Radio>
                                <Radio value={9}>4억 ~ 5억</Radio>
                                <Radio value={10}>5억 ~ 7억</Radio>
                                <Radio value={11}>7억 ~10억</Radio>
                                <Radio value={12}>10억 이상</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className='ck-wrap'>
                        <div className='question-wrap'>
                            <div className='Q-wrap'>Q</div>
                            <p>임대 또는 임차하고자 하는 지역은 어디인가요? <span>(다중선택 가능)</span></p>
                        </div>
                        <div className='ck-list'>
                            <Checkbox.Group onChange={onChange3}>
                                <Row>
                                    <Col span={8}>
                                        <Checkbox value={13}>서울</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={14}>경기도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={15}>인천시</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={16}>부산시</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={17}>대전시</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={18}>대구시</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={19}>울산시</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={20}>세종시</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={21}>광주시</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={22}>강원도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={23}>충청북도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={24}>충청남도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={25}>경상북도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={26}>경상남도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={27}>전라북도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={28}>전라남도</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value={29}>제주도</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div className='Email-input-wrap'>
                        <p className='labal-p'>이메일</p>
                        <input type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='이메일 주소를 입력해 주세요' />
                    </div>
                    <ul className='Consent-input-wrap'>
                        <li>
                            <input type="checkbox" onChange={(e)=>{
                                if (e.target.checked == false) {
                                    setAgree(1)
                                }else{
                                    setAgree(0)
                                }
                            }} />
                            <p><a href='/Clause/Utilization' target='_blank' rel='noreferrer'>이용약관</a> 및 <a href='/Clause/Privacy_policy' target='_blank' rel='noreferrer'>개인정보처리방침</a>에 동의합니다(필수)</p>
                        </li>
                        <li>
                            <input type="checkbox" onChange={(e)=>{
                                if (e.target.checked == false) {
                                    setMarketing(1)
                                }else{
                                    setMarketing(0)
                                }
                            }} />
                            <p><a href="/Clause/Marketing" target='_blank' rel='noreferrer'>마케팅 정보 수신</a> 에 동의합니다(선택)</p>
                        </li>
                    </ul>
                    <div className='pop-btn-wrap1'>
                        <button className='close-btn' onClick={()=>{setPopOpen1("")}}>닫기</button>
                        <button className='Registration-btn' onClick={()=>{
                            // setPopOpen1("");
                            // setPopOpen2(" open");
                            registration()
                        }}>등록하기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function EmailRegistration2({popOpen2 , setPopOpen2}) {
    let navigate = useNavigate();
    return(
        <div className={'popup-bg EmailRegistrationPop2' + popOpen2}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen2("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <div className='pop-h2-wrap'>
                        <i className='xi-check-circle'></i>
                        <h2>이메일 등록 완료</h2>
                    </div>
                    <div className='pop-p-box'>
                        <p>
                            K-전세를 선택해 주셔서 감사합니다. <br />
                            K-전세 소식을 가장 먼저 알려드리겠습니다.
                        </p>
                    </div>
                    <div className='pop-btn-wrap2'>
                        <button className='close-btn' onClick={()=>{setPopOpen2("")}}>닫기</button>
                        <button className='Registration-btn' onClick={()=>{
                            navigate("/Account/JoinMBList")
                        }}>K전세 회원가입</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;