import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


function PwCompletion() {
	let navigate = useNavigate();


    return (
        <div className='account-page'>
			<div className='completion-wrap'>
                <div className='ck-img-wrap'>
                    <i className='xi-check'></i>
                </div>
                <p className='txt-top'>
                    <span>비밀번호</span>가 변경 되었습니다
                </p>
                <p className='txt-bt'>
					새로운 비밀번호로 로그인해주세요.
                </p>
                <button onClick={()=>{navigate("/Account/Login")}}>로그인 페이지로 이동</button>
            </div>
		</div>
    )
}

export default PwCompletion;