import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';


function CharterIntro() {
	let navigate = useNavigate();

    return (
        <div className='Mypage CharterIntro big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='registration-btn-wrap'>
					<div className='registration-btn w-box'>
						<div className='txt-wrap'>
							<p className='txt-top'>임대인 전세 등록</p>
							<p className='txt-bt'>
								K-전세에 전세관리 등록하고, <br />
								월세처럼 다달이 수익금을 받아보세요!
							</p>
						</div>
						<button onClick={()=>{navigate("/Mypage/LessorRG")}}><i className='xi-long-arrow-right'></i></button>
					</div>
					<div className='registration-btn w-box'>
						<div className='txt-wrap'>
							<p className='txt-top'>임차인 전세 등록</p>
							<p className='txt-bt'>
								회원님의 소중한 전세금을 <br />
								100% 안전하게 보호해 드립니다
							</p>
						</div>
						<button onClick={()=>{navigate("/Mypage/TenantRG")}}><i className='xi-long-arrow-right'></i></button>
					</div>
				</div>
			</div>
        </div>
    )
}

export default CharterIntro;