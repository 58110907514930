import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// API
import axios from 'axios';


function Privacy_policy() {
	let navigate = useNavigate();

    return (
		<div className='clause-wrap'>
			<p>
				<h1>K-전세 개인정보 처리 방침</h1>
				<br />
				㈜가족의꿈 (이하“회사”라고 합니다)는 개인정보보호법 규정에 따라 회원 및 비회원 이용자(이하 회원 및 비회원을 통칭하여 “이용자”라 합니다)분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용 목적, 개인정보의 보유 및 이용기간을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.<br />
				<br />
				<span>1.1 수집하는 개인정보의 항목회사는 다음의 개인정보를 수집ㆍ이용합니다. 수집된 개인정보는 정해진 목적 이외의 용도로는 이용되지 않으며, 수집목적이 변경될 경우 사전동의를 구할 예정입니다.<br /></span>
				1{")"} 홈페이지 회원 가입 및 관리 -  ID, 비밀번호, 성명, 생년월일, 성별, 휴대폰번호, 전화번호, 주소, 이메일주소, 본인인증값, 사업자등록번호, 부동산 관련 정보 및 관심분야<br />
				2{")"} 재화 또는 서비스의 원활한 제공 및 운영 ① 필수항목 : 본인확인 정보로써 ID와 성명, 생년월일, 비밀번호, 주소, 휴대폰번호, 전화번호, 이메일주소, 거래은행명, 은행계좌번호, 거래자성명 <br />
				3{")"} 기타 개인정보 처리 업무 : 기타 서비스이용과정에서 수집될 수 있는 개인정보의 범위는 다음과 같습니다. -  ID, 비밀번호, 성명, 생년월일, 성별, 휴대폰번호, 전화번호, 주소, 이메일주소, 본인인증값, 사업자등록번호 부동산 관련 정보 및 관심분야<br />
				<br />
				<span>1.2 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</span>
				서비스 이용 과정에서 이용자로부터 IP주소, 쿠키, 서비스이용기록, 기기정보가 생성되어 수집될 수 있습니다.<br />
				1{")"} 쿠키는 웹 서비스 제공자의 서버가 “이용자”의 컴퓨터로 전송하는 소량의 정보이며, 여기에는 방문한 웹사이트의 정보 등이 담겨 있습니다.<br />
				2{")"} 쿠키의 사용목적<br />
				2-1{")"} “이용자”가 동일한 정보를 반복하여 입력할 필요를 제거하여 시간을 절약하고 웹사이트에 로그인해 있는 동안 개인화된 콘텐츠를 서비스하기 위하여 컴퓨터에 “이용자”의 선택 사항 및 기타 정보를 저장하는데 쿠키를 사용합니다.<br />
				2-2{")"} “이용자”들이 방문한 각 서비스와 웹사이트들에 대한 방문 및 이용형태, 보안접속 여부, “이용자” 규모 등을 파악하여 “이용자”에게 광고를 포함한 최적화된 맞춤형 정보 및 서비스를 제공하기 위해 사업합니다.<br />
				3{")"} 쿠키의 설치, 운영 및 거부<br />
				3-1{")"} “이용자”는 쿠키 설치에 대한 선택권을 가지고 있습니다. “이용자”는 웹 브라우저 상단의 {"[도구 ->인터넷 옵션 -> 개인정보 -> 고급정보]"}에서 모든 쿠키의 허용, 동의를 통한 쿠키의 허용, 모든 쿠키의 차단을 스스로 결정할 수 있습니다<br />
				3-2{")"} 단, 모든 쿠키의 저장을 거부하기로 선택한 경우 일부 서비스 제공에 어려움이 있을 수 있습니다.<br />
				<br />
				<span>2.1 개인정보의 수집·이용 목적</span>
				1{")"} 재화 또는 서비스의 원활한 제공 및 운영<br />
				2{")"} 홈페이지 회원 가입 및 관리 : 회원서비스이용, 개인식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원 탈퇴 의사 확인등<br />
				3{")"} 마케팅 및 광고에의 활용: 고객별 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 정기 간행물 발송, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계 등3.회사는 모바일 애플리케이션, 웹 페이지, 서면양식, 팩스, 고객센터를 통한 전화와 온라인 상담, 이벤트 응모, 배송요청, 협력회사로부터의 제공, 생성정보 수집 툴을 통한 수집 등을 통해 개인정보를 수집합니다. 만약 서비스 이용 도중 위에서 밝힌 내용 이외에 추가적으로 개인정보 수집이 필요한 경우, 회원에게 사전에 이러한 사실을 고지하고 동의를 받습니다. 예를 들어 위치 기반 서비스와 같은 서비스 이용 시 새로운 개인정보 혹은 개인위치정보를 수집해야 하는 상황이 발생하면 회사는 회원에게 사전에 고지하고 동의 받은 회원에 한해서만 정보를 수집합니다.<br />
				<br />
				<span>2.2 수집한 개인정보의 위탁</span>
				회사는 서비스 제공 및 향상을 위하여 필요한 개인정보를 위탁하고 있으며, 관계법령에따라 위탁계약시 개인정보가 안전하게 관리될 수 있도록 필요한사항을 규정하고 있습니다. <br />
				화사의 개인정보 수탁업체 및 위탁업무의 내용은 아래와 같습니다. <br />
				───────────────────────────────────<br />
				위탁 대상자 : (주)코워커넷<br />
				위탁 업무 : 홈페이지 유지 보수 관리<br />
				보유 및 이용기간 : 회원 탈퇴 시 또는 위탁 계약 종료 시까지 <br />
				───────────────────────────────────<br />
				위탁 대상자 : Amazon Web Services Inc <br />
				위탁 업무 : 정보저장 <br />
				보유 및 이용기간 : 회원 탈퇴 시 또는 위탁 계약 종료 시까지 <br />
				───────────────────────────────────<br />
				<br />
				<span>3. 개인정보의 보유, 이용기간 및 파기</span>
				1{")"} 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br />
				2{")"} 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하고, 이용계약 종료의 경우 회사는 이용자의 개인정보를 즉시 파기하며 제3자에게 제공된 정보에 대해서도 지체 없이 파기하도록 조치합니다.<br />
				3{")"} 다음의 경우 각각 명시한 기간 동안 개인정보를 보유합니다. ① 원칙적으로 회원의 동의를 얻어 보유한 정보는 회원탈퇴 시까지 보유합니다. ② 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다.<br />
				4{")"} 회사는 이용자의 개인정보를 보호하여 개인정보 유출로 인한 피해가 발생하지 않도록 하기 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다. ① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. ② 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.5{")"} 이용자의 동의를 받아 보유하고 있는 개인정보에 대한 당해 이용자의 열람요구가 있을 경우 회사는 지체 없이 이를 열람, 확인할 수 있도록 조치합니다.6{")"} 개인정보보호법 제6장 정보통신서비스 제공자 등의 개인정보처리 등 특례에 의한 개인정보 보유 ① 이용자가 1년간 서비스 이용기록이 없는 경우 [개인정보보호법] 제 39조의6조에 근거하여 이용자에게 사전 통지하고 별도로 분리하여 저장합니다. 단, 관계 법령의 규정에 의한 보존이 필요한 경우 관계 법령에서 규정한 기간 동안 보관합니다.<br />
				7{")"} 이용자 본인의 부주의나 인터넷상의 문제로 아이디, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 ”회사”는 일체의 책임을 지지 않습니다.<br />
				8{")"} 회사의 사이트에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 “개인정보처리방침”이 적용되지 않음을 알려 드립니다.<br />
				<br />
				기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래기관에 문의 하시기바랍니다.<br />
				1.개인분쟁조정위원회 (www.1336.or.kr/1336)<br />
				2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)<br />
				3.대검찰청인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)<br />
				4.경찰청사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)
			</p>
		</div>
    )
}

export default Privacy_policy;