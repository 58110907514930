import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function Mould() {
    return (
        <>
            <Header/>
			<Outlet></Outlet>
            <Footer/>
        </>
    )
}

export default Mould;