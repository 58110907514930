import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MypageMap from './MypageMap';
// API
import axios from 'axios';


function FacilityMG() {
	let navigate = useNavigate();

	let { id } = useParams();
    console.log(id);
	
	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	
	// 메인 api 데이터
    let [data, setData] = useState();

	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/question", {
			ref_estate_list_id: id
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data);
			console.log(response)
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);

	console.log(data);

	// 임대차 구분하기
	let [sortation , setSortation] = useState(JSON.parse(localStorage.getItem("sortation")));

	console.log(sortation);
	useEffect(()=>{
		sortation.map((item,i)=>{
			console.log(item[2]);
			if (item[id] == 0) {
				localStorage.setItem("sortation2" , 0);
			}else{
				localStorage.setItem("sortation2" , 1);
			}
		})
	},[sortation])
	console.log(localStorage.getItem("sortation2"));

    return (
        <div className='Mypage charter ContractMG'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='w-box'>
					<ul className='charter-tab'>
						<li onClick={()=>{navigate("/Mypage/CharterCR")}}>전세 현황</li>
						{/* <li onClick={()=>{navigate("/Mypage/ContractMG/" + id)}}>임대차 계약 관리</li> */}
						<li className='now' onClick={()=>{navigate("/Mypage/FacilityMG/" + id)}}>계약/시설 관리 문의</li>
						<li onClick={()=>{navigate("/Mypage/ManagerAN/" + id)}}>관리자 공지</li>
					</ul>
					<div className='h3-wrap'>
						<h3>{data ? data.title.title : ""}</h3>
						<button className='registration-btn' onClick={()=>{navigate("/Mypage/FacilityMG_Registration/" + id)}}>등록하기</button>
					</div>
					<div className='table-wrap'>
						<div className='table-scroll-wrap'>
							<table className='table-st1'>
								<thead>
									<tr>
										<th>구분</th>
										<th>문의유형</th>
										<th>제목</th>
										<th>처리상태</th>
										<th>작성일</th>
										<th>상세보기</th>
									</tr>
								</thead>
								<tbody>
									{
										data ?
											data.list.map((item, i)=>{
												return(
													<tr key={i}>
														<td>{data.list.length - i}</td>
														<td>{item.category}</td>
														<td>{item.title}</td>
														{
															item.type == 0 ?
																<td className='red-td'>처리요청</td>
															: item.type == 1 ?
																<td>처리보류</td>
															: item.type == 2 ?
																<td className='blue-td'>승인완료</td>
															: item.type == 3 ?
																<td>-</td>
															: ""
														}
														<td>{item.created_at.slice(2,16)}</td>
														<td className='btn-td'>
															<div className='btn-wrap'>
																<button className='details' onClick={()=>{
																	navigate("/Mypage/FacilityMG_Details/" + item.id);
																	if (item.type == 3) {
																		localStorage.setItem("sortation2" , 2);
																	}
																}}>상세보기</button>
															</div>
														</td>
													</tr>
												)
											})
										: 
										<tr></tr>
									}
									{/* <tr>
										<td>2</td>
										<td>수도공사 견적서</td>
										<td>전세금을 올리려고 합니다...</td>
										<td>-</td>
										<td>22-01-31 16:00</td>
										<td className='btn-td'>
											<div className='btn-wrap'>
												<button className='details'>상세보기</button>
											</div>
										</td>
									</tr>
									<tr>
										<td>2</td>
										<td>수도공사 견적서</td>
										<td>전세금을 올리려고 합니다...</td>
										<td>처리보류</td>
										<td>22-01-31 16:00</td>
										<td className='btn-td'>
											<div className='btn-wrap'>
												<button className='details'>상세보기</button>
											</div>
										</td>
									</tr>
									<tr>
										<td>2</td>
										<td>수도공사 견적서</td>
										<td>전세금을 올리려고 합니다...</td>
										<td className='red-td'>처리요청</td>
										<td>22-01-31 16:00</td>
										<td className='btn-td'>
											<div className='btn-wrap'>
												<button className='details'>상세보기</button>
											</div>
										</td>
									</tr>
									<tr>
										<td>2</td>
										<td>수도공사 견적서</td>
										<td>전세금을 올리려고 합니다...</td>
										<td className='blue-td'>승인완료</td>
										<td>22-01-31 16:00</td>
										<td className='btn-td'>
											<div className='btn-wrap'>
												<button className='details'>상세보기</button>
											</div>
										</td>
									</tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default FacilityMG;