import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// 엔트디자인
import { Input } from 'antd';
import { Progress } from 'antd';
import { Image } from 'antd';
import { StarOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Select } from 'antd';

// 스와이파
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// 리코일
import { Type, Contest_id, Estate_id, AmountRecoil, Buy_token_count } from "../../state/Investment";
import { useRecoilState, 
    useRecoilValue, 
    useSetRecoilState, 
    useResetRecoilState 
} from 'recoil';

// API
import axios from 'axios';


function InvestmentDT() {
	let navigate = useNavigate();

	let { id } = useParams();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 메인 api 데이터
    let [data, setData] = useState();
	// api 데이터
	useEffect(()=>{
		axios.put("https://api.kjeonse.com/api/v1/contest/detail", {
			id: id,
		})
		.then(function (response) {
			setData(response.data.data);
			console.log(response.data.data);
		})
		.catch(function (error) {
			console.log(error);
		});
    },[]);

	// section3======================================
    // 스와이퍼 버튼
    const swiperRef1 = useRef();

	// 숫자인풋 콤마찍기
	useEffect(()=>{
		const input = document.querySelector('#number');
			input.addEventListener('keyup', function(e) {
			let value = e.target.value;
			value = Number(value.replaceAll(',', ''));
			if(isNaN(value)) {
				input.value = 0;
			}else {
				const formatValue = value.toLocaleString('ko-KR');
				input.value = formatValue;
			}
		})
	},[])

	// 첨부파일
	const [ files, setFiles ] = useState([]);
	useEffect(()=>{
		initialziedFiles();
	},[data])
	const initialziedFiles = () => {
		let files = [];
		if(data) {
			data.files.map((item, idx) => {
				if (item.type == 2) {
					files.push(
						{
							uid: idx,
							name: item.originFileName,
							status: 'done',
							url: item.fileAddress,
						},
					)	
				}
			})
		}
		setFiles(files);
	}

	const props = {
		action: 'https://kjeonse.com',
		showUploadList: {
		showDownloadIcon: true,
		downloadIcon: [<div key={"0"}>다운로드<i className='xi-download'></i></div>],
		showRemoveIcon: true,
		removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
		},
	};

	
	// 좋아요 채크박스
	const [checkItems, setCheckItems] = useState();
	useEffect(()=>{
		if (data) {
			setCheckItems(data.detail.isLike);
		}
	},[data])
	const heart = () => {
		if (checkItems == 1) {
			setCheckItems(0)
		}else{
			setCheckItems(1)
		}
		if (tokenState) {
			axios.post("https://api.kjeonse.com/api/v1/contest/like", {
				contest_id: id,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState ,
				},
			})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			})
		}else{
			navigate("/Account/Login")
		}
	};

	// 남은날짜 구하기
	function dday(e) {
		let today = new Date();
		let dday = new Date(e)
		let gap = dday.getTime() - today.getTime();
		let result = Math.ceil(gap / (1000 * 60 * 60 * 24));
		return result
	}

	// 예상수익
	let [estimatedEarnings , setEstimatedEarnings] = useState(0);

	// 공모참여버튼
	function contest_participate() {
		if (tokenState == undefined) {
			alert("로그인 후 이용가능한 서비스 입니다.");
			navigate("/Account/Login");
		}else{
			setPopOpen1(" open")
		}
	}

	

	// 팝업 =======================
	// 알림내역 팝업 열고닫기
    let [popOpen1 , setPopOpen1] = useState("");
    let [popOpen2 , setPopOpen2] = useState("");
	let [popOpen3 , setPopOpen3] = useState("");

	useEffect(() => {
		if (popOpen1 != "" || popOpen2 != "" || popOpen3 != "") {
			document.body.style= `overflow: hidden`;
		}else{
			document.body.style = `overflow: auto`
		}
	}, [popOpen1, popOpen2, popOpen3])

    return (
        <div className='sub investment-dt'>
            <div className='section1'>
				<div className='container'>
					{
						data ?
						data.detail.contest_status == 2 ?
							<button className='circle-btn' onClick={()=>{
								contest_participate()
							}}></button>
						: ""
						: ""
					}
					{
						data ?
						<div className='investment-product'>
							<div className='investment-img'>
								{
									data.files.length > 0 ?
										data.files.map((item, i)=>{
											if (item.type == 0) {
												return(
													<img key={i} src={item.fileAddress} alt="" />
												)
											}

											if (item.type != 0 && (data.files.length - 1) == i ) {
												return <img key={i} src={require("../../images/basic-img.png")} alt="" />
											}
										})
										:
										<img src={require("../../images/basic-img.png")} alt="" />
								}
							</div>
							<div className='investment-ri'>
								{
									data.detail.contest_status == 1 ?
										<span className='situation'>공모예정</span>
									: 
									data.detail.contest_status == 2 ?
										<span className='situation Progress'>공모중</span>
									:
									data.detail.contest_status == 3 ?
										<span className='situation'>공모마감</span>
									: ""
								}
								<div className='title-wrap'>
									<p className='title'>{data.detail.title}</p>
									<div className='heart-ck-wrap'>
										<input 
											type="checkbox" 
											id={"like" + id} 
											// ref={(el)=>targets.current[item.id]=el} 
											onChange={(e)=>{
												heart();
											}}
											checked={checkItems == 0 ? true : false}
										/>
										<label htmlFor={"like" + id}></label>
									</div>
								</div>
								<p className='description'>
									{data.detail.description}
								</p>
								<div className='per-bar'>
									{
										data.detail.contest_status == 2 ?
											<Progress percent={1} />
										: 
											<Progress percent={
												Math.round( data.detail.now_cost / data.detail.cost * 100)
											} />
									}
								</div>
								<div className='amount-wrap'>
									{
										data.detail.contest_status == 1 ?
											<p className='amount-p'>
												{data.detail.opened_at.slice(0,4)}년 {data.detail.opened_at.slice(5,7)}월 공모예정
											</p>
										:
											<>
												<div className='amount-le'>
													<span className='per'>{
														Math.round( (data.detail.now_cost / data.detail.cost) * 100 )
													}%</span>
													<p className='amount'>
														<span className='blue'>{data.detail.now_cost.toLocaleString()}원</span>
														/
														<span className='grey'>{(data.detail.cost - data.detail.now_cost).toLocaleString()}원</span>
													</p>
												</div>
												<span className='d-day'>{dday(data.detail.closed_at) + "일 남음"}</span>
											</>
									}
								</div>
								<ul className='Info-list'>
									<li>
										<label>
											<div className='i-wrap'><i className='xi-maker'></i></div>
											<p><span>주</span><span>소</span></p>
										</label>
										<p className='Info-p'>{data.detail.address}</p>
									</li>
									<li>
										<label>
											<div className='i-wrap'><i className='xi-calendar-check'></i></div>
											<p>공모일정</p>
										</label>
										<p className='Info-p'>
											{data.detail.opened_at.slice(0,4)}년 {data.detail.opened_at.slice(5,7)}월 {data.detail.opened_at.slice(8,10)}일 
											~
											{data.detail.closed_at.slice(0,4)}년 {data.detail.closed_at.slice(5,7)}월 {data.detail.closed_at.slice(8,10)}일 
										</p>
									</li>
									<li>
										<label>
											<div className='i-wrap'><i className='xi-money'></i></div>
											<p><span>공</span><span>모</span><span>액</span></p>
										</label>
										<p className='Info-p'>{data.detail.cost.toLocaleString()}원</p>
									</li>
									<li>
										<label>
											<div className='i-wrap'><i className='xi-library-add'></i></div>
											<p>공모수량</p>
										</label>
										<p className='Info-p'>{data.detail.token_count.toLocaleString()} KJT (1인당 최대 {data.detail.buy_token_count.toLocaleString()} KJT)</p>
									</li>
									<li>
										<label>
											<div className='i-wrap'><i className='xi-tag'></i></div>
											<p>주요특징</p>
										</label>
										<p className='Info-p'>
											{data.detail.feature}
										</p>
									</li>
								</ul>
							</div>
						</div>
						: ""
					}
				</div>
			</div>
			<div className='section2'>
				<div className='container'>
					<Swiper
						onSwiper={(swiper) => {
							swiperRef1.current = swiper;
						}}
						spaceBetween={20}
						slidesPerView={3}
						loop={true}
						breakpoints={{
							1200: {
								spaceBetween:40
							},
						}}
						>
						{
							data ?
								data.files.map((item, i)=>{
									if (item.type == 1) {
										return(
											<SwiperSlide key={i}>
												<Image src={item.fileAddress}/>
											</SwiperSlide>
										)
									}
								})
							: ""
						}
						{/* <SwiperSlide>
							<Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"/>
						</SwiperSlide> */}
					</Swiper>
					<div className='swiper-btn'>
						<button onClick={() => swiperRef1.current.slidePrev()} className="sw-le"><i className='xi-long-arrow-left'></i></button>
						<button onClick={() => swiperRef1.current.slideNext()} className="sw-ri" ><i className='xi-long-arrow-right'></i></button>
					</div>
					{/* <div className='big-img'>
						<img src={require("../../images/main-banner-1.png")} alt="" />
					</div> */}
				</div>
			</div>
			<div className='section3'>
				<div className='container'>
					<h2>배당</h2>
					<div className='flex-wrap gap40'>
						<div className='flex-box'>
							<div className='profit-box'>
								<h3>모의투자</h3>
								<div className='profit-input'>
									<input type="text" id="number" maxLength='13' onChange={()=>{
										let numberStr = document.getElementById('number').value;
										let number = numberStr.replace(/,/g, "");
										var regex = /[^0-9]/g;
										var result = number.replace(regex, "");
										setEstimatedEarnings(result * data.detail.expect_return_amount / 100);
									}}/>
									<p><span className='bold'>원</span>투자하면<span className='sm-ft'>(세전)</span></p>
								</div>
								<div className='profit'>
									<p>예상 연 배당수익</p>
									<p>{Math.round(estimatedEarnings).toLocaleString()} 원</p>
								</div>
							</div>
						</div>
						{
							data ?
							<div className='flex-box'>
								<ul className='li-st1 sm'>
									<li>
										<p className='li-le'>예상 배당 수익률</p>
										<p className='li-ri'>연 {data.detail.expect_return_amount}%</p>
									</li>
									<li>
										<p className='li-le'>배당 주기</p>
										<p className='li-ri'>{
											data.detail.allocation_type == 0 ?
												<>주배당</>
											: 
											data.detail.allocation_type == 1 ?
												<>월배당</>
											:
											data.detail.allocation_type == 2 ?
												<>연배당</>
											: ""
										}</p>
									</li>
									<li>
										<p className='li-le'>배당 시작 예정일</p>
										<p className='li-ri'>{data.detail.expect_return_date}</p>
									</li>
								</ul>
							</div>
							: ""
						}
						{/* <div className='flex-box'>
							<ul className='li-st1'>
								<li>
									<p className='li-le'>예상 배당 수익률</p>
									<p className='li-ri'>연 4.82%</p>
								</li>
								<li>
									<p className='li-le'>배당 주기</p>
									<p className='li-ri'>월배당</p>
								</li>
								<li>
									<p className='li-le'>배당 시작 예정일</p>
									<p className='li-ri'>2022.03.31</p>
								</li>
							</ul>
						</div> */}
					</div>
					<h2>{data ? data.detail.info_title : ""}</h2>
					<div className='flex-wrap'>
						<div className='flex-box'>
							<ul className='li-st1'>
								{
									data ?
										data.info.map((item, i)=>{
											return(
												<li key={i}>
													<p className='li-le'>{item.title}</p>
													<p className='li-ri'>{item.content}</p>
												</li>
											)
										})
									: ""
								}
							</ul>
						</div>
					</div>
					<h2>상세정보</h2>
					<div className='editor-wrap'>
						{
							data ?
							<div dangerouslySetInnerHTML={{__html: data.detail.content}}>
							</div>
							:""
						}
					</div>
					<div className='file-wrap'>
						<h3><PaperClipOutlined />파일첨부</h3>
						<div className='file-list'>
							<Upload {...props} fileList={files}></Upload>
						</div>
					</div>
					{
						data ?
						data.detail.contest_status == 2 ?
							<button className='Participation-btn' onClick={()=>{
								contest_participate()
							}}>
								공모 참여하기
							</button>
						: ""
						: ""
					}
				</div>
			</div>
			<div className='Popup-wrap'>
                <ContestPop1 popOpen1={popOpen1} setPopOpen1={setPopOpen1} setPopOpen2={setPopOpen2} data_detail={data ? data.detail : ""} ></ContestPop1>
                <ContestPop2 popOpen2={popOpen2} setPopOpen2={setPopOpen2}></ContestPop2>
				{/* <ContestPop3 popOpen3={popOpen3} setPopOpen3={setPopOpen3}></ContestPop3> */}
            </div>
        </div>
    )
}

function ContestPop1({popOpen1, setPopOpen1, setPopOpen2, data_detail}) {
	let { id } = useParams();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 투자가능현황 api
	let [investable_data, setInvestable_date] = useState();

	useEffect(()=>{
		if (tokenState) {
			axios.post("https://api.kjeonse.com/api/v1/dashboard/stock/info", {
				contest_id: id,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				// console.log(response);
				setInvestable_date(response.data.data);
			})
			.catch(function (error) {
				console.log(error);
			})
		}
    },[]);

	// 투자유형 선택 ================================================================
	let [investment_Type, setInvestment_Type] = useRecoilState(Type);
	const investment_Type_Change = (value) => {
		setInvestment_Type(value);
		console.log(value)
		if (value == 0) {
			setEstate_id();
			setEstate_token();
		}
	};
	useEffect(()=>{
		if (investment_Type == 0) {
			// data_detail.buy_token_count - investable_data.stockedByToken 는 전에 이 공모전에 투자한적이있으면 1인당 최대 공모 가능토큰 수량 에서 전에 투자한 토큰 금액을 뺸 토큰 수량 이다 
			if (data_detail.buy_token_count - investable_data.stockedByToken > investable_data.can_use_amount / data_detail.token_amount) {
				setAmountVal(investable_data.can_use_amount / data_detail.token_amount);
				setMaximum_token(investable_data.can_use_amount / data_detail.token_amount);
			}else{
				setAmountVal(data_detail.buy_token_count - investable_data.stockedByToken);
				setMaximum_token(data_detail.buy_token_count - investable_data.stockedByToken);
			}
		}
	},[investment_Type])
	// ===============================================================================

	// 전세계약 리스트
	let formData = [];
	let [charter_list , setCharter_list] = useState();

	useEffect(()=>{
		// 전세계약 리스트 배열
		if (investable_data) {
			investable_data.can_stock_estate_my_list.map((item,i)=>{
				let isCheck = false;
				let item2TotalSumStockAmount = 0;
				for (const item2 of investable_data.stocked_estate_history) {
					if (item.id == item2.estate_id) {
						isCheck = true;
						item2TotalSumStockAmount = item2.totalSumStockAmount;
					}
				}
				if (isCheck) {
					formData.push({
						value: item.id,
						// label: item.title + " (" + (item.contractor_total_amount).toLocaleString() + "원)",
						label: item.title + " (" + (item.contractor_total_amount - item2TotalSumStockAmount).toLocaleString() + "원)",
						price: (item.contractor_total_amount - item2TotalSumStockAmount),
					})
					return;
				}else{
					formData.push({
						value: item.id,
						// label: item.title + " (" + (item.contractor_total_amount).toLocaleString() + "원)",
						label: item.title + " (" + (item.contractor_total_amount).toLocaleString() + "원)",
						price: (item.contractor_total_amount),
					})
					return;
				}
			})
			setCharter_list(formData);
		}
	},[investable_data]);


	// 전세 계약명 선택
	// 전세금으로 투자 시, 사용한 전세금 PK
	let [estate_id, setEstate_id ] = useRecoilState(Estate_id);
	// 전세금
	let [estate_token, setEstate_token] = useState();
	const charter_Change = (value, e) => {
		console.log(value , e.price);
		setEstate_id(value)
		setEstate_token(e.price / data_detail.token_amount)
	};
	useEffect(()=>{
		if (estate_id) {
			if (data_detail.buy_token_count - investable_data.stockedByToken > estate_token) {
				setAmountVal(estate_token);
				setMaximum_token(estate_token);
			}else{
				setAmountVal(data_detail.buy_token_count - investable_data.stockedByToken);
				setMaximum_token(data_detail.buy_token_count - investable_data.stockedByToken);
			}
		}
	},[estate_id])

	// 최대수량 버튼
	let [maximum_token, setMaximum_token] = useState();
	function maximum() {
		if (investment_Type == 0 || investment_Type == 1) {
			if (investment_Type == 0) {
				// data_detail.buy_token_count - investable_data.stockedByToken 는 전에 이 공모전에 투자한적이있으면 1인당 최대 공모 가능토큰 수량 에서 전에 투자한 토큰 금액을 뺸 토큰 수량 이다 
				if (data_detail.buy_token_count - investable_data.stockedByToken > investable_data.can_use_amount / data_detail.token_amount) {
					setAmountVal(investable_data.can_use_amount / data_detail.token_amount);
					setMaximum_token(investable_data.can_use_amount / data_detail.token_amount);
				}else{
					setAmountVal(data_detail.buy_token_count - investable_data.stockedByToken);
					setMaximum_token(data_detail.buy_token_count - investable_data.stockedByToken);
				}
			}else if (investment_Type == 1) {
				if (estate_id) {
					if (data_detail.buy_token_count - investable_data.stockedByToken > estate_token) {
						setAmountVal(estate_token);
						setMaximum_token(estate_token);
					}else{
						setAmountVal(data_detail.buy_token_count - investable_data.stockedByToken);
						setMaximum_token(data_detail.buy_token_count - investable_data.stockedByToken);
					}
				}else{
					alert("전세를 선택해주세요")
				}
			}
		}else{
			alert("투자유형을 선택해주세요")
		}
	}

	// 남은날짜 구하기
	function dday(e) {
		let today = new Date();
		let dday = new Date(e)
		let gap = dday.getTime() - today.getTime();
		let result = Math.ceil(gap / (1000 * 60 * 60 * 24));
		return result
	}

	// 금액
	let [amount, setAmount] = useRecoilState(Buy_token_count);
	let [amountRecoil, setAmountRecoil] = useRecoilState(AmountRecoil)

	// 숫자인풋 콤마찍기
	// 토큰
	let [amountVal , setAmountVal] = useState(0);
	function amountChange(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			setAmountVal(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			setAmountVal(formatValue);
		}
	}
	// 금액 콤마빼고 저장
	useEffect(()=>{
		const number = amountVal.toString().split(',').join("");
		setAmount(number);
	},[amountVal])
	useEffect(()=>{
		if (data_detail) {
			setAmountRecoil(amount * data_detail.token_amount )
		}
	},[amount])
	useEffect(()=>{
		console.log(amountRecoil)
	},[amountRecoil])

	// 투자참여 ===========================
	// 투자 유효성 검사
	function investment_check() {
		if (investment_Type == 0 || investment_Type == 1) {

			if (investment_Type == 0) {
				
				// const nowAmountPrice = Number(amountVal.replace(',', ''));
				const nowAmountPrice = Number(amountVal);

				if (nowAmountPrice <= maximum_token && nowAmountPrice != 0) {

					setPopOpen1("");
					setPopOpen2(" open");

				}else if (nowAmountPrice == 0) {

					alert("투자금액을 입력해주세요")

				}else{
					
					alert("투자할수있는 투자금액을 초과하였습니다..")

				}

			}else if (investment_Type == 1) {

				if (estate_id) {

					// const nowAmountPrice = Number(amountVal.replace(',', ''));
					const nowAmountPrice = Number(amountVal);

					if ( nowAmountPrice <= maximum_token ) {

						setPopOpen1("");
						setPopOpen2(" open");

					}else if (nowAmountPrice == 0 && nowAmountPrice != 0) {

						alert("투자금액을 입력해주세요");

					}else{

						alert("투자할수있는 투자금액을 초과하였습니다.");

					}
				}else{

					alert("투자할 전세 계약명을 선택해주세요");
				}
			}
		}else{
			alert("투자금 유형을 선택해주세요");
		}
	}

    return(
        <div className={'popup-bg ContestPop1' + popOpen1}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen1("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <h2>{data_detail.title}</h2>
					<p className='p-st1'>{data_detail.description}</p>
					<div className='per-bar'>
						{
							data_detail.contest_status == 2 ?
								<Progress percent={1} />
							: 
								<Progress percent={
									Math.round(data_detail.now_cost / data_detail.cost * 100)
								} />
						}
					</div>
					<div className='amount-wrap'>
						<>
							<div className='amount-le'>
								<span className='per'>{
									Math.round( data_detail.now_cost / data_detail.cost * 100)
								}%</span>
								<p className='amount'>
									<span className='blue'>{Number(data_detail.now_cost).toLocaleString()}원</span>
									/
									<span className='grey'>{(data_detail.cost - data_detail.now_cost).toLocaleString()}원</span>
								</p>
							</div>
							<span className='d-day'>{dday(data_detail.closed_at) + "일 남음"}</span>
						</>
					</div>
					<div className='situation-wrap'>
						<div className='h3-wrap'>
							<h3>투자 가능현황</h3>
						</div>
						<ul>
							<li>
								<p>나의 일반 예치금</p>
								<p>{investable_data ? investable_data.can_use_amount.toLocaleString() : ""} 원</p>
							</li>
							<li>
								<p>나의 전세 예치금</p>
								<p>{investable_data ? investable_data.totalSumEstateAmount.toLocaleString() : ""} 원</p>
							</li>
							<li>
								<p>1인당 최대 공모 가능수량</p>
								<p>{Number(data_detail.buy_token_count).toLocaleString()} KJT</p>
							</li>
						</ul>
					</div>
					<div className='Investment-input-wrap'>
						<div className='h3-wrap'>
							<h3>투자하기</h3>
							<button onClick={()=>{
								maximum()
							}}>최대수량</button>
						</div>
						<p className='Unit-Criteria'>{Number(data_detail.token_amount).toLocaleString()}원 = 1KJT (K-전세 토큰)</p>
						<ul className='pop-input-ul'>
							<li>
								<p className='labal-p'>수량</p>
								<input type="text" id='number' maxLength='13' value={amountVal} onChange={(e)=>{amountChange(e)}} />
								<div className='Unit'>KJT</div>
							</li>
							{/* <li>
								<p className='labal-p'>가격</p>
								<input type="text" value={amountVal} />
								<div className='Unit'>원</div>
							</li> */}
							<li>
								<Select
									defaultValue="투자금 유형 선택"
									onChange={investment_Type_Change}
									options={[
										{
										value: 0,
										label: '일반 투자금',
										},
										{
										value: 1,
										label: '전세 투자금',
										},
									]}
								/>
							</li>
							{
								investment_Type == 1 ?
									<li>
										<Select
											defaultValue="전세 계약명 선택"
											onChange={charter_Change}
											options={charter_list}
										/>
									</li>
								: ""
							}
						</ul>
						<div className='total-wrap'>
							<p className='labal-p'>투자총액</p>
							<p className='Amount-p'>{(amount * data_detail.token_amount).toLocaleString()} 원</p>
						</div>
					</div>
					<div className='pop-btn-wrap1'>
						<button className='close-btn' onClick={()=>{setPopOpen1("")}}>취소</button>
						<button className='Registration-btn' onClick={()=>{
							investment_check();
						}}>투자하기</button>
					</div>
                </div>
            </div>
        </div>
    )
}

function ContestPop2({popOpen2 , setPopOpen2}) {
    let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 예치금: 0 , 잔세금: 1
	let [investment_Type, setInvestment_Type] = useRecoilState(Type);
	// 투자를 진행한 공모전 PK
	let { id } = useParams();
	// 전세금으로 투자 시, 사용한 전세금 PK
	let [estate_id, setEstate_id ] = useRecoilState(Estate_id);
	// 투자한 금액 / 공모전에서 지정한 토큰 개당 가격한 개수
	let [amount, setAmount] = useRecoilState(Buy_token_count);
	// 투자한 금액
	let [amountRecoil, setAmountRecoil] = useRecoilState(AmountRecoil)

	function Investment() {
		axios.post("https://api.kjeonse.com/api/v1/dashboard/stock", {
			type: investment_Type,
			contest_id: id,
			estate_id: estate_id,
			amount: amountRecoil,
			buy_token_count: amount
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			console.log(response);
			setPopOpen2("");
			window.location.reload();
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
	}

    return(
        <div className={'popup-bg ContestPop2' + popOpen2}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen2("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <div className='pop-h2-wrap'>
                        <i className='xi-check-circle'></i>
                        <h2>투자하기</h2>
                    </div>
                    <div className='pop-p-box'>
                        <p>
							<span>{amountRecoil.toLocaleString()}원</span> 을 이 공모에 투자하시겠습니까? <br />
							아래 투자하기 버튼을 클릭하시면 회원님의 예치금에서 <br />
							위 금액이 즉시 차감됩니다
                        </p>
                    </div>
                    <div className='pop-btn-wrap2'>
                        <button className='close-btn' onClick={()=>{setPopOpen2("")}}>취소</button>
                        <button className='Registration-btn' onClick={()=>{Investment()}}>투자하기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

// 비로그인일 경우
// function ContestPop3({popOpen3 , setPopOpen3}) {
//     let navigate = useNavigate();
//     return(
//         <div className={'popup-bg ContestPop2' + popOpen3}>
//             <div className='popup-box'>
//                 <button className='close-btn'
//                     onClick={()=>{setPopOpen3("")}}
//                 ><i className='xi-close-thin'></i></button>
//                 <div className='popup-content'>
//                     <div className='pop-h2-wrap'>
//                         <i className='xi-check-circle'></i>
//                         <h2>로그인 하세요</h2>
//                     </div>
//                     <div className='pop-p-box'>
//                         <p>
// 						로그인 후 이용가능한 서비스 입니다. <br />
// 						아래 로그인 버튼을 클릭하시면 로그인 창으로 이동합니다.
//                         </p>
//                     </div>
//                     <div className='pop-btn-wrap2'>
//                         <button className='close-btn' onClick={()=>{setPopOpen3("")}}>닫기</button>
//                         <button className='Registration-btn' onClick={()=>{
//                             navigate("/Account/Login")
//                         }}>로그인</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }


export default InvestmentDT;