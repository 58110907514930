import { atom } from 'recoil';

//  0: 예치금, 1: 전세금
const Type = atom({
	key: 'Type',
	default: 2, 
});

// 투자를 진행한 공모전 PK
const Contest_id = atom({
	key: 'Contest_id',
	default: 0, 
});

// 전세금으로 투자 시, 사용한 전세금 PK
const Estate_id = atom({
	key: 'Estate_id',
	default: undefined, 
});

// 투자한 금액
const AmountRecoil = atom({
	key: 'AmountRecoil',
	default: 0,
});

// 투자한 금액 / 공모전에서 지정한 토큰 개당 가격한 개수
const Buy_token_count = atom({
	key: 'Buy_token_count',
	default: 0,
});

export {Type, Contest_id, Estate_id, AmountRecoil, Buy_token_count};