import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';


function ManagerAN() {
	let navigate = useNavigate();
	
	let { id } = useParams();
    console.log(id);

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 메인 api 데이터
    let [data, setData] = useState();

	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/admin/notice", {
			ref_estate_list_id: id
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			console.log(response.data.data);
			setData(response.data.data);
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);

    return (
        <div className='Mypage charter ContractMG'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='w-box'>
					<ul className='charter-tab'>
						<li onClick={()=>{navigate("/Mypage/CharterCR")}}>전세 현황</li>
						{/* <li onClick={()=>{navigate("/Mypage/ContractMG/" + id)}}>임대차 계약 관리</li> */}
						<li onClick={()=>{navigate("/Mypage/FacilityMG/" + id)}}>계약/시설 관리 문의</li>
						<li className='now' onClick={()=>{navigate("/Mypage/ManagerAN/" + id)}}>관리자 공지</li>
					</ul>
					<div className='h3-wrap'>
						<h3>{data ? data.title.title : ""}</h3>
					</div>
					<div className='table-wrap'>
						<div className='table-scroll-wrap'>
							<table className='table-st1'>
								<thead>
									<tr>
										<th>구분</th>
										<th>수신</th>
										<th>제목</th>
										<th>작성일</th>
										<th>상세보기</th>
									</tr>
								</thead>
								<tbody>
									{
										data ?
											data.list.map((item , i)=>{
												return(
													<tr key={i}>
														<td>{data.list.length - i}</td>
														<td>{item.target}</td>
														<td>{item.title}</td>
														<td>{item.created_at.slice(2,16)}</td>
														<td className='btn-td'>
															<div className='btn-wrap'>
																<button className='details' onClick={()=>{navigate("/Mypage/ManagerAN_Details/" + item.id)}}>상세보기</button>
															</div>
														</td>
													</tr>
												)
											})
										: <tr></tr>
									}
									{/* <tr>
										<td>2</td>
										<td>임대인/임차인</td>
										<td>전세금을 올리려고 합니다...</td>
										<td>22-01-31 16:00</td>
										<td className='btn-td'>
											<div className='btn-wrap'>
												<button className='details'>상세보기</button>
											</div>
										</td>
									</tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default ManagerAN;