import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';
// 엔트디자인
import { Radio, Collapse } from 'antd';

// API
import axios from 'axios';

function Portfolio() {
	let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));

	// 메인 api 데이터
	// 전체투자
	let [stockInfoSection,setStockInfoSection] = useState();
    let [data, setData] = useState();
	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/stock/list", {
			user_id: userState
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data)
			setStockInfoSection(response.data.data.stockInfoSection)
			
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);
	console.log(data);
	
	// 서랍
	const { Panel } = Collapse;
	
    return (
        <div className='Mypage Portfolio big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map4"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='section1'>
					<div className='h2-wrap'>
						<h2>투자현황</h2>
					</div>
					<div className='flex-wrap4'>
						<div className='flex-box w-box'>
							<h3>투자중인 상품</h3>
							<p>{stockInfoSection ? stockInfoSection.now_stock_ing_count : ""}건</p>
						</div>
						<div className='flex-box w-box'>
							<h3>누적 투자금</h3>
							<p>{stockInfoSection ? stockInfoSection.total_sum_stock_amount.toLocaleString() : ""}원</p>
						</div>
						<div className='flex-box w-box'>
							<h3>누적 수익률</h3>
							<p>
								{
									stockInfoSection ? 
										stockInfoSection.total_sum_dividend_amount > 0 &&  stockInfoSection.total_sum_stock_amount > 0 ?
											Math.round(Number(stockInfoSection.total_sum_dividend_amount) / Number(stockInfoSection.total_sum_stock_amount) * 100)
										: 
											0
									: 
										""
								}
								%
							</p>
						</div>
						<div className='flex-box w-box'>
							<h3>투자중인 금액</h3>
							<p>{stockInfoSection ? stockInfoSection.now_stock_ing_amount.toLocaleString() : ""}원</p>
						</div>
					</div>
				</div>
				<div className='section2'>
					<ul>
						{
							data?
							data.stockList.map((item,i)=>{
								return(
									<li className='w-box' key={i}>
										<div className='flex-wrap'>
											<div className='img-wrap'>
												<img src={item.fileAddress ? item.fileAddress : require("../../images/basic-img.png")} alt="" />
											</div>
											<div className='simple-box'>
												<div className='yield-box'>
													<p>수익률</p>
													<span>+ {Math.round(Number(item.totalSumDividendAmount) / Number(item.totalSumStockAmount) * 100)}%</span>
												</div>
												<div className='title-wrap'>
													<p className='title'>{item.title}</p>
												</div>
												<p className='price price1'>
													{Number(item.totalSumStockAmount).toLocaleString()}원
													<span>({Number(item.totalSumStockTokenCount).toLocaleString()} KJT)</span>
												</p>
												<ul className='Info-li'>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-calendar-check'></i></div>
															<p>투자유형</p>
														</div>
														<p className='content-p'>
															{
																data.stockTypeList.map((item2,i2)=>{
																	if (item.id == item2.id) {
																		if (item2.type == 0) {
																			return "/ 예치금 투자금 "
																		}else if(item2.type == 1){
																			return "/ 전세금 투자금 "
																		}
																	}
																})
															}
														</p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-money'></i></div>
															<p>참 여 일</p>
														</div>
														<p className='content-p'>{item.stock_start_date.slice(0,4)}년 {item.stock_start_date.slice(5,7)}월 {item.stock_start_date.slice(8,10)}일 </p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-money'></i></div>
															<p>누적수익</p>
														</div>
														<p className='content-p'>{Number(item.totalSumDividendAmount).toLocaleString()} 원</p>
													</li>
												</ul>
											</div>
										</div>
										<div className='table-wrap'>
											<div className='table-scroll-wrap'>
												<table className='table-st1'>
													<thead>
														<tr>
															<th>구분</th>
															<th>내용</th>
															<th>거래금액</th>
															<th>거래날짜</th>
														</tr>
													</thead>
													<tbody>
														{
															data.dividendList.map((item3,i3)=>{
																if (item.id == item3.id) {
																	return(
																		<tr key={i3}>
																			<td>배당금</td>
																			<td>{item.title}</td>
																			<td className='red-td'>+ {item3.dividend_amount}</td>
																			<td>{item3.created_at}</td>
																		</tr>
																	)
																}
															})
														}
													</tbody>
												</table>
											</div>
											{/* <button className='more-btn'>더보기 <i className='xi-angle-down'></i></button> */}
										</div>
									</li>
								)
							})
							: ""
						}
						{/* <li className='w-box'>
							<div className='flex-wrap'>
								<div className='img-wrap'>
									<img src="" alt="" />
								</div>
								<div className='simple-box'>
									<div className='yield-box'>
										<p>수익률</p>
										<span>+ 15%</span>
									</div>
									<div className='title-wrap'>
										<p className='title'>송파동 아파트 2101</p>
									</div>
									<p className='price price1'>
										300,000,000원
										<span>(100,000 KJT)</span>
									</p>
									<ul className='Info-li'>
										<li>
											<div className='labal-wrap'>
												<div className='icon'><i className='xi-calendar-check'></i></div>
												<p>투자유형</p>
											</div>
											<p className='content-p'>전세 투자금</p>
										</li>
										<li>
											<div className='labal-wrap'>
												<div className='icon'><i className='xi-money'></i></div>
												<p>참 여 일</p>
											</div>
											<p className='content-p'>2022년 10월 01일</p>
										</li>
										<li>
											<div className='labal-wrap'>
												<div className='icon'><i className='xi-money'></i></div>
												<p>누적수익</p>
											</div>
											<p className='content-p'>5,000,000 원</p>
										</li>
									</ul>
								</div>
							</div>
							<div className='table-wrap'>
								<div className='table-scroll-wrap'>
									<table className='table-st1'>
										<thead>
											<tr>
												<th>구분</th>
												<th>내용</th>
												<th>거래금액</th>
												<th>잔액</th>
												<th>거래날짜</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>배당금</td>
												<td>롯데월드타워 시그니엘 1호</td>
												<td className='red-td'>+ 1,000,000</td>
												<td>5,000,000</td>
												<td>22-01-31 12:00</td>
											</tr>
											<tr>
												<td>배당금</td>
												<td>롯데월드타워 시그니엘 1호</td>
												<td className='red-td'>+ 1,000,000</td>
												<td>5,000,000</td>
												<td>22-01-31 12:00</td>
											</tr>
										</tbody>
									</table>
								</div>
								<button className='more-btn'>더보기 <i className='xi-angle-down'></i></button>
							</div>
						</li> */}
					</ul>
					
				</div>
			</div>
        </div>
    )
}

export default Portfolio;