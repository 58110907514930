import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';

// 앤트디자인
import { Select } from 'antd';
import { StarOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Upload } from 'antd';


function MyDetails() {
	let navigate = useNavigate();

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	const props = {
		beforeUpload: file => {
			return false;	// 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
		},
		defaultFileList: [
		{
			uid: '1',
			name: 'xxx.png',
			status: 'done',
			url: 'https://api.bio3s.com/storage/productProcurement/1667971731_product_손세정제_250.jpg',
		},
		{
			uid: '2',
			name: 'yyy.png',
			status: 'done',
			url: 'http://www.baidu.com/yyy.png',
		},
		],
		showUploadList: {
		showDownloadIcon: true,
		downloadIcon: [<div>다운로드<i className='xi-download'></i></div>],
		showRemoveIcon: true,
		removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
		},
	};

    return (
        <div className='Mypage charter MyDetails'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='MyDetails-box w-box'>
					<div className='h3-wrap'>
						<h3>수도고장 문의<span className='date-wrap'>22-01-31 16:00</span></h3>
						<div className='btn-wrap'>
							<Select
								defaultValue="lucy"
								onChange={handleChange}
								options={[
									{
									value: 'jack',
									label: 'Jack',
									},
									{
									value: 'lucy',
									label: 'Lucy',
									},
									{
									value: 'Yiminghe',
									label: 'yiminghe',
									},
								]}
							/>
							<button className='list-btn'>목록으로</button>
						</div>
					</div>
					<div className='editor-wrap'>
						
					</div>
					<div className='file-wrap'>
						<h3><PaperClipOutlined />파일첨부</h3>
						<div className='file-list'>
							<Upload {...props}></Upload>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default MyDetails;