import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useState , useRef } from 'react';
import { useEffect } from 'react';
import  hdLogo  from '../images/LOGO.svg';

// API
import axios from 'axios';

function Header() {
    let navigate = useNavigate();
    let [ siteMap, setSiteMap ] = useState('');

    // 회원 상태관리
    let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

    let [header_shape, setHeader_shape] = useState(false);
    useEffect(()=>{
        if (localStorage.getItem('k-Jeonse-token')) {
            setHeader_shape(true);
        }else{
            setHeader_shape(false);
        }
    })

    // 로그아웃
    function logout() {
        localStorage.removeItem('k-Jeonse-token');
        // localStorage.removeItem('k-Jeonse-user_id');
        navigate("/")
    }

    useEffect(()=>{
        if (siteMap == ' sitemap') {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('body').style.height= '100%';
        }else{
            document.querySelector('body').style.overflow = 'unset';
            document.querySelector('body').style.height= 'unset';
        };
    });

    // 알림 데이터 블러오기==================================================================
    // 알림 리스트
    let [alarm_list, setAlarm_list] = useState();
    function notification() {
        axios.post("https://api.kjeonse.com/api/v1/alarm", {
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			console.log(response.data.data);
            setAlarm_list(response.data.data);
            setPopOpen1(" open");
            localStorage.setItem( 'headr-zIndex', true );
		})
		.catch(function (error) {
			console.log(error);
			// alert("잘못된 접근입니다");
			// navigate("/");
			// window.location.reload();
		})
    }

    // 알림읽음처리
    let [alarm_check, setAlarm_check] = useState();
    useEffect(()=>{
        if (localStorage.getItem('k-Jeonse-token')) {
            axios.post("https://api.kjeonse.com/api/v1/alarm/check", {
			
            },
            {
                headers: {
                    Authorization: "Bearer " + tokenState , 
                    // "Content-type": 'application/json',
                },
            })
            .then(function (response) {
                if (response.data.data) {
                    setAlarm_check(true);
                }else{
                    setAlarm_check(false);
                }
            })
            .catch(function (error) {
                localStorage.removeItem('k-Jeonse-token');
                window.location.replace("/")
            })
        }
    })

    // 알림내역 팝업 열고닫기
    let [popOpen1 , setPopOpen1] = useState("");

    return (
        <div className={"header" + siteMap + (localStorage.getItem('headr-zIndex') ? " z-index" : "")}>
            <div className='big-container'>
                <div className='header-le'>
                    <h1 className='logo' onClick={() => {navigate("")}}>
                        <img src={hdLogo} alt="" />
                    </h1>
                </div>
                <div className='header-ri'>
                    <div className='nav-wrap'>
                        <div className='mb-logo-wrap' onClick={() => {navigate(""); setSiteMap('')}}>
                            <img src={require("../images/LOGO.png")} alt="" />
                        </div>
                        <ul className='hd-nav'>
                            <li className='sc-btn home-li' onClick={()=>{navigate(""); setSiteMap('')}}>
                                홈
                            </li>
                            <li className='sc-btn' onClick={()=>{navigate(""); setSiteMap('')}}>
                                <a href="/#K-Jeonse">K-전세</a>
                            </li>
                            <li onClick={()=>{navigate("/Investment"); setSiteMap('')}}>
                                투자
                            </li>
                            <li className='sc-btn' onClick={()=>{ setSiteMap('')}}>
                                <Link to={"/Bulletin/Announcement"}>게시판</Link>
                            </li>
                        </ul>
                        <button className='close-btn' onClick={() => { setSiteMap("") }}>
                            <i className='xi-close'></i>
                        </button>
                    </div>
                    <div className='hd-btn-wrap'>
                        {
                            !header_shape ?
                                <button className='hd-btn login-btn' onClick={()=>{navigate("/Account/Login")}}></button>
                            :
                                <>
                                    <button className='hd-btn logout-btn' onClick={()=>{logout()}}></button>
                                    {
                                        alarm_check ?
                                        <button className='hd-btn Notification-btn on' onClick={()=>{
                                            // setPopOpen1(" open");
                                            notification();
                                        }}><i className='xi-bell-o'></i></button>
                                        :
                                        <button className='hd-btn Notification-btn' onClick={()=>{
                                            // setPopOpen1(" open");
                                            notification();
                                        }}><i className='xi-bell-o'></i></button>
                                    }
                                    <button className='hd-btn mypage-btn' onClick={()=>{navigate("/Mypage/Dashboard")}}></button>
                                </>
                        }
                        <button className='mb-menu-btn' onClick={() => { setSiteMap(" sitemap") }}><i className='xi-bars'></i></button>
                    </div>
                    <div className='Popup-wrap'>
                        <NotificationPOP popOpen1={popOpen1} setPopOpen1={setPopOpen1} alarm_list={alarm_list}></NotificationPOP>
                    </div>
                </div>
            </div>
        </div>
    )
}

function NotificationPOP({popOpen1 , setPopOpen1 , alarm_list}) {
    
    return(
        <div className={'popup-bg Notification-pop' + popOpen1}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{
                        setPopOpen1("");
                        localStorage.removeItem('headr-zIndex');
                    }}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <div className='Notification-h2'>
                        <img src={require("../images/notice.png")} alt="" />
                        <h2>알림내역</h2>
                    </div>
                    {
                        alarm_list ?
                            alarm_list.length != 0 ?
                            <ul className='Notification-list'>
                                {
                                    alarm_list ?
                                        alarm_list.map((item, i)=>{
                                            return(
                                                <li key={i}>
                                                    <p>{item.title}</p>
                                                    <span className='date'>{item.created_at}</span> 
                                                </li>
                                            )
                                        })
                                    :""
                                }
                                {/* <li>
                                    <p>'송파동 아파트 2101' 의 전세 계약기간이 60일 남았습니다.</p>
                                    <span className='date'>22-01-31 12:00</span> 
                                </li> */}
                            </ul>
                            :
                            <div className='no-Notification'>
                                <img src={require("../images/empty-notice.png")} alt="" />
                                <p>알림 내용이 없습니다</p>
                            </div>
                        : ""
                    }
                </div>
            </div>
        </div>
    )
}


export default Header;