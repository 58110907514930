import React from "react";
import styled from "styled-components";

import { Checkbox, Col, Row } from "antd";
import { Radio, Collapse } from 'antd';
import { useState } from "react"; 
import { useEffect } from "react";
import axios from "axios";


const AddInfoJoin = () => {

    const [ id, setId ] = useState('');
    const [ type, setType ] = useState('');

    const [ email,  setEmail ]  = useState('');
    const [ phone,  setPhone ]  = useState('');
    const [ name,   setName ]   = useState('');
    const [ birth,  setBirth ]  = useState('');
    const [ survey_first,     setSurveyFirst ]      = useState([]);
    const [ survey_second,    setSurveySecond ]     = useState();
    const [ survey_third,     setSurveyThird ]      = useState([]);
    const [ privacyInfo,      setPrivacyInfo ]      = useState(false);
    const [ privacyMarketing, setPrivacyMarketing ] = useState(false);
    const [ auth, setAuth ] = useState('');

    // 인증상태 관리 
    // [ 0: 미인증, 1: 인증요청(타이머), -2: 인증실패(시간초과)-, 3: 인증완료 ]
    const [ authStatus, setAuthStatus ] = useState(0);

    // [ 0: 휴대폰번호 13자리 미만, 1: 휴대폰번호 13자리 충족, 2: 인증요청, 3: 인증완료 ]
    const [ authStatusCss, setAuthStatusCss ] = useState(0);

    const [ timer, setTimer ] = useState(180);
    
    // 휴대폰 정규식
    const phoneRegHandle = (e) => {
        
        if(!email) {  alert('이메일을 먼저 입력해주세요.');  return; }

        const regex = /^[0-9\b -]{0,11}$/;

        const TARGET_VALUE = e.target.value;

		if (regex.test(TARGET_VALUE)) {
            
            if(TARGET_VALUE.length == 11) {
                setAuthStatusCss(1)
            } else {
                setAuthStatusCss(0);
            }
                
			setPhone(e.target.value);
		}

    }

    // 설문 섹션1. 관심항목 다중 선택
    const survey_group_first = (e) => { setSurveyFirst(e); }
    
    // 설문 섹션2. 임대, 임차 전세금
    const survey_group_second = (e) => { setSurveySecond(e); }

    // 설문 섹션3. 관심항목 다중 선택
    const survey_group_third = (e) => { setSurveyThird(e); }

    // privacy 1
    const privacyInfoOnChangeHandle = (e) => { setPrivacyInfo(e.target.checked); }

    // privacy 2
    const privacyMarketOnChangeHandle = (e) => { setPrivacyMarketing(e.target.checked); }

    // 인증번호 요청
    const request_certificate = () => {

        // request auth sms api
        let url = 'https://api.kjeonse.com/api/v1/sms';
        axios.post(url, {
            'email' : email,
            'phone' : phone,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {

            console.log(res.data);

            if(res.data.success) {
                if(res.data.data.invalid) {

                    alert('인증번호 : ' + res.data.data.sms_code);

                    // [ 0: 미인증, 1: 인증요청(타이머), 2: 인증실패(시간초과), 3: 인증완료 ]
                    setAuthStatus(1);

                    // [ 0: 휴대폰번호 13자리 미만, 1: 휴대폰번호 13자리 충족, 2: 인증요청, 3: 인증완료 ]
                    setAuthStatusCss(2);
                    
                } else {

                    if(res.data.msg) {
                        alert(res.data.msg);
                    }
                }

            } else {
                if(res.data.msg) {
                    alert(res.data.msg);
                }
            }
            
        });

    }

    // 입력된 인증번호 저장
    const auth_code_onChagne = (e) => {

        const regex = /^[0-9\b -]{0,6}$/;
        const TARGET_VALUE = e.target.value;

		if (regex.test(TARGET_VALUE)) {
            setAuth(e.target.value); 
        }
    }

    // 인증번호 확인 요청
    const auth_validation = () => {

        // auth sms check api
        if (!auth) {
			alert("인증번호를 입력해주세요")
		}else{
			axios.post("https://api.kjeonse.com/api/v1/check/sms", {
				email: email,
				phone: phone,
				code: auth,
			})
			.then(function (res) {

				alert(res.data.msg);
				if (res.data.data == 0) {
					setAuthStatus(3);
				}else{
					console.log(res);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}

    }

    // 타이머 함수
    function setTimeOutTimer(timer) {

        if(timer == 0) {
            setAuthStatus(0);
            setAuthStatusCss(1);
            setTimer(180);
        }

        let minute = Math.floor(Number(timer / 60));
        let second = Math.floor(Number(timer % 60));

        if(second < 10) {
            second = "0"+second;
        }

        return "0" + minute + ":" + second;

    }

    // join complete
    const completeJoin = () => {

        // update api

        if(!email || !phone || !name || !birth) {
            alert('이메일 및 전화번호 등 필수 입력 값을 확인해주세요.');

        } else {

            if(!id || !type) {
                alert('정상적인 접근이 아닙니다.');
                window.location.href = '/Account/Login';
            }

            if(authStatus != 3) { alert('휴대폰 인증상태가 올바르지 않습니다.'); return; }

            if(!privacyInfo) {
                alert('이용약관 동의는 필수입니다.');
                return;
            }

            let formData = new FormData();

            formData.append('id', id);
            formData.append('type', type);
            formData.append('email', email);
            formData.append('name', name);
            formData.append('birth', birth);
            formData.append('phone', phone);
            formData.append('privacy_agree', privacyInfo ? 0 : 1 );
            formData.append('privacy_marketing', privacyMarketing ? 0 : 1);

            let surveySecond = survey_second ? survey_second.target.value : null;
            let surveyArray = [ ...survey_first, surveySecond, ...survey_third]; 
            
            surveyArray.map((item) => {
                formData.append('survy[]', item);
            });

            let url = 'https://api.kjeonse.com/api/v1/info';

            axios.post(url, formData)
            .then((res) => {

                if(res.data.success) {

                    window.localStorage.removeItem('k-Jeonse-user_id');
                    window.localStorage.removeItem('k-Jeonse-user-type');
                    window.localStorage.setItem('k-Jeonse-token', res.data.data.token.access_token);
                    window.location.href = '/Mypage/Dashboard';
                }

            });

        }

    }

    // timer
    useEffect(() => {
        
        if(authStatus == 1) {

            let auth_timer = setTimeout(() => {
                let time = timer - 1;
                setTimer(time);
            }, 1000);

            if(timer == 0) {
                clearTimeout(auth_timer); 
                return;
            }
        }

    }, [authStatus, timer]);

    useEffect(() => {
        
        setId(window.localStorage.getItem('k-Jeonse-user_id'));        
        setType(window.localStorage.getItem('k-Jeonse-user-type'));

    }, []);

    return(

        <Container>

            <ImageLogo>
                <img src={require("../../images/LOGO.png")} alt="" />
            </ImageLogo>

            <Main>

                {/*  */}
                <JoinHeader>
                    <h2>Sign up</h2>
                    <p>* 필수입력</p>
                </JoinHeader>

                {/* Email */}
                <InfoRow>

                    <InputHeader>
                        <span>이메일</span>
                        <p>*</p>
                    </InputHeader>

                    <Input 
                        placeholder="이메일을 통해 각종 정보 및 알람을 전달해드립니다"
                        onChange={(e) => setEmail(e.target.value)}
                    />

                </InfoRow>

                {/* Phone */}
                <InfoRow>

                    <InputHeader>
                        <span>본인인증</span>
                        <p>*</p>
                    </InputHeader>

                    <InfoCol>

                        <Input 
                            width={0}
                            placeholder="휴대폰 번호를 입력하세요(-없이 숫자만)"
                            className={authStatusCss == 2 ? 'can_request_auth_input' : null}
                            value={phone}
                            onChange={(e) => phoneRegHandle(e)}
                            disabled={authStatus != 0 ? true : false}
                        />
                        <ActiceButton
                            type={authStatusCss}
                            className={authStatusCss != 1 ? 'can_request_auth_button' : null}
                            onClick={request_certificate}
                            disabled={authStatus != 0 ? true : false}
                        >
                            {authStatus == 3 ? "인증완료" : "인증하기"}
                        </ActiceButton>

                    </InfoCol>

                    {
                        authStatus == 1 ?
                                <InfoCol>
                                    <Input 
                                        width={0}
                                        placeholder="인증번호를 입력하세요."
                                        value={auth}
                                        onChange={(e) => auth_code_onChagne(e)}
                                    />

                                    <div className="timer">
                                        {setTimeOutTimer(timer)}
                                    </div>

                                    <ActiceButton
                                        type={authStatusCss}
                                        onClick={auth_validation}
                                    >
                                        인증
                                    </ActiceButton>
                                </InfoCol>
                            :
                                ''
                    }

                </InfoRow>

                {/* Name */}
                <InfoRow>

                    <InputHeader>
                        <span>이름</span>
                        <p>*</p>
                    </InputHeader>

                    <Input 
                        type="text"
                        placeholder="이름을 입력해주세요"
                        onChange={(e) => setName(e.target.value)}
                    />
                    
                </InfoRow>

                {/* Birth */}
                <InfoRow>

                    <InputHeader>
                        <span>생년월일</span>
                        <p>*</p>
                    </InputHeader>

                    <Input 
                        type="text"
                        placeholder="ex. 1994-09-05"
                        onChange={(e) => setBirth(e.target.value)}
                    />

                </InfoRow>

                <hr />
                
                {/* Survey first Section  */}
                <InfoRow>

                    <InputHeader 
                        className="displayBlock"
                    >
                        <span>
                            K-전세에서 관심있는 항목을 선택하세요
                        </span>
                        <p className="gray">
                            (다중선택 가능)
                        </p>
                    </InputHeader>

                    <hr />

                    <Checkbox.Group 
                        onChange={survey_group_first}
                    >
                        <Row>
                            <Col span={8}>
                                <Checkbox value={1}>임대</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={2}>임차</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={3}>투자</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={4}>기타</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>

                    <hr />

                </InfoRow>

                {/* Survey Second Section  */}
                <InfoRow>

                    <InputHeader 
                        className="displayBlock"
                    >
                        <span>
                            임대 또는 임차하고자 하는 전세금은 대략 얼마인가요?
                        </span>
                        <p className="gray">
                            (다중선택 가능)
                        </p>
                    </InputHeader>

                    <hr />

                    <Radio.Group 
                        onChange={survey_group_second} 
                    >
                        <Radio value={5}>1억 이하</Radio>
                        <Radio value={6}>1억 ~ 2억</Radio>
                        <Radio value={7}>2억 ~ 3억</Radio>
                        <Radio value={8}>3억 ~ 4억</Radio>
                        <Radio value={9}>4억 ~ 5억</Radio>
                        <Radio value={10}>5억 ~ 7억</Radio>
                        <Radio value={11}>7억 ~10억</Radio>
                        <Radio value={12}>10억 이상</Radio>
                    </Radio.Group>

                    <hr />

                </InfoRow>

                {/* Survey Third Section  */}
                <InfoRow>

                    <InputHeader 
                        className="displayBlock"
                    >
                        <span>
                            K-전세에서 관심있는 항목을 선택하세요
                        </span>
                        <p className="gray">
                            (다중선택 가능)
                        </p>
                    </InputHeader>

                    <hr />

                    <Checkbox.Group 
                        onChange={survey_group_third}
                    >
                        <Row>
                            <Col span={8}>
                                <Checkbox value={13}>서울</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={14}>경기도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={15}>인천시</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={16}>부산시</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={17}>대전시</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={18}>대구시</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={19}>울산시</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={20}>세종시</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={21}>광주시</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={22}>강원도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={23}>충청북도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={24}>충청남도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={25}>경상북도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={26}>경상남도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={27}>전라북도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={28}>전라남도</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={29}>제주도</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>

                    <hr />

                </InfoRow>

                {/* Privacy */}
                <InfoRow>

                    <InputHeader 
                        className="displayBlock"
                    >
                        <span>
                            약관동의
                        </span>
                    </InputHeader>
                    
                    <AgreeSection>

                        <AgreeCheckBox 
                            type='checkbox' 
                            checked={privacyInfo} 
                            onChange={(e) => privacyInfoOnChangeHandle(e)}
                        />
                        {/* 이용약관 및 개인정보처리방침에 동의합니다(필수) */}
                        <p>
                            <a href="/Clause/Utilization" target="_blank" rel="noreferrer">이용약관</a> 및 {" "}
                            <a href="/Clause/Privacy_policy" target="_blank" rel="noreferrer">개인정보처리방침</a> 에 동의합니다(필수) 
                        </p>                        

                    </AgreeSection>

                    <AgreeSection>

                        <AgreeCheckBox 
                            type='checkbox' 
                            checked={privacyMarketing} 
                            onChange={(e) => privacyMarketOnChangeHandle(e)}
                        />
                        {/* 마케팅 정보 수신 에 동의합니다(선택) */}
                        <p>
                            <a href="/Clause/Marketing" target="_blank" rel="noreferrer">마케팅 정보 수신</a>에 동의합니다(선택)
                        </p>

                    </AgreeSection>

                </InfoRow>

                <JoinButton
                    onClick={completeJoin}
                >
                    가입완료
                </JoinButton>
            </Main>

        </Container>

    );
};

export default AddInfoJoin;


const Container = styled.div`

    min-width: 320px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 20px;

`;

const Main = styled.div`

    width: 100%;
    max-width: 744px;
    min-width: 320px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 71px;
    padding: 72px;
    box-sizing: border-box;

    hr {
        margin-bottom: 40px;
    }

    @media (max-width: 768px) {
        padding: 25px;
    }
`;

const ImageLogo = styled.div`

    max-width: 204px;
`;

const JoinHeader = styled.div`

    width: 100%;
    height: 120px;
    border-bottom: 1px solid #333;
    margin-bottom: 60px;

    h2 { 
        font-family: Pretendard;
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.67;
        letter-spacing: normal;
        text-align: center;
        color: #005ac8;
    }

    p {
        margin: 35px 0 39.5px 21px;
        font-family: Pretendard;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.14;
        letter-spacing: -0.45px;
        text-align: right;
        color: #f00;
    }

    @media (max-width: 768px) {
        margin-top: 50px;
    }

`;

const InputHeader = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    margin-bottom: 16px;

    span {

        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #333333;
        margin-right: 10px;
    }

    p {

        color: #ff0000;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;

    }

    .gray {

        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #b7b7bf;
    }

`;

const Input = styled.input`
    
    width: 100%;
    height: 64px;
    padding-left: 17px;
    border-radius: 4px;
    background-color: #fff;
    border: solid 2px #d8d8d8;

    font-size: 18px;
    line-height: 2.5px;
    margin-bottom: 5px;

    margin-right: 15px;

    @media (min-width: 768px) {
        width: ${props => 
            props.width == 0 ? 'calc(100% - 144px)' : '100%'
        };
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
    }

`;

const InfoRow = styled.div`

    width: 100%;
    margin-bottom: 40px;

    .ant-col-8 {
        width: 25%;
        flex: none;
    }

    .ant-checkbox-group {
        width: 100%;
    }

    .ant-checkbox+span {
        color: #b7b7bf;
        margin-bottom: 20px;
    }

    .ant-checkbox-inner {
        
        border: 2px solid #b7b7bf;
        width: 20px;
        height: 20px;

        @media (min-width:640px) {
            width: 24px;
            height: 24px;
        }
    }

    .ant-radio-wrapper {

        align-items: center;
        width: calc(100% / 3);
        margin-right: 0px;
        margin-bottom: 15px;
        color: #b7b7bf;

        .ant-radio-inner {
            width: 24px;
            height: 24px;
        }

        span {
            color: #b7b7bf;
        }
        
        @media (min-width: 680px) {
            width: calc(100% / 4);
        }
    }
    
    .ant-radio-inner {
        border: 2px solid #b7b7bf;
    }

    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
            margin-bottom: 0px;
        }
    }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: thin dotted #dcdcdc;
    }

    @media (max-width: 768px) {

        .displayBlock {

            flex-direction: column;
            
            span {
                font-size: 16px;
                width: 100%;
                margin-right: 0;
            }

            p {
                width: 100%;
            }
        }   
    }

    @media (max-width: 360px) {
        
        .displayBlock {

            flex-direction: column;
            
            span {
                font-size: 12px;
                width: 100%;
                margin-right: 0;
            }

            p {
                width: 100%;
            }

            .gray {
                font-size: 12px;
            }
        }   
    }

    @media (max-width: 680px) {

        span.ant-radio+* {
            font-size: 14px;
            padding: initial;
            padding-left: 4px; 
        }

        .ant-checkbox+span {
            font-size: 14px;
        }
        
        .ant-col-8 {
            width: calc(100% / 3);
        }
    }


`;

const InfoCol = styled.div`

    width: 100%;  
    display: flex;
    flex-direction: row;
    position: relative;

    .can_request_auth_button {
        background-color: #959595;
    }

    .can_request_auth_input {
        background-color: #dedbdb;
    }

    .timer {
        position: absolute;
        font-size: 18px;
        color: red;
        left: 62%;
        top: 28%;
    }

    @media (max-width: 768px) {

        flex-direction: column;

        .timer {
            left: 75%;
            top: 16%;
        }
    }
`;

// 휴대폰 인증하기
const ActiceButton = styled.button`

    width: 144px;
    height: 64px;
    border-radius: 4px;
    color: white;
    font-size: 18px;
    font-family: 'Pretendard-Bold';
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.14;
    letter-spacing: -0.45px;
    box-sizing: border-box;

    margin-bottom: 15px;

    background-color: ${props => 
        props.type == 0 ? '#959595' : '#005ac8'
    };

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const AgreeSection = styled.div`
    width: 100%;
    height: 30px;
    /* background-color: #dedede; */
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        text-decoration: underline;
    }
`;

const AgreeCheckBox = styled.input`
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    touch-action: manipulation;
    outline: none;
    vertical-align: middle;
    overflow: visible;
    margin-right: 10px;
`;

const JoinButton = styled.button`
    background-color: #005ac8;
    color: #fefefe;
    font-size: 20px;
    font-weight: 700;
    height: 88px;
    width: 100%;
    max-width: 744px;
`;