import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';

// 앤트디자인
import { Select } from 'antd';
import { StarOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Upload } from 'antd';


function FacilityMG_Details() {
	let navigate = useNavigate();

	let { id } = useParams();
    console.log(id);

	// 임대차 구분
	// localStorage.getItem("sortation2")

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 메인 api 데이터
    let [data, setData] = useState();
	
	const [ type, setType ] = useState(0);
	const [ files, setFiles ] = useState([]);

	const [ content, setContent ] = useState('');

	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/question/detail", {
			ref_estate_list_id: id
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data);
			setType(response.data.data.type)
			const htmlCode = "<div>" + response.data.data.detail.content + "</div>";
			setContent(htmlCode);
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
		
    },[]);

	const handleChange = (value) => {

		console.log(`selected ${value}`);

		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/update/question", {
			category_id: value,
			ref_estate_list_id: id
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				"Content-type": 'application/json',
			},
		})
		.then(function (response) {
			if(response.data.success) {
				setType(value);
				alert('상태 변경이 완료되었습니다.');
			}
		})
		.catch(function (error) {
			console.log(error);
		})


	};

	// 첨부파일
	useEffect(()=>{
		initialziedFiles();
	},[data])

	const initialziedFiles = () => {
		let files = [];
		if(data) {
			data.files.map((item, idx) => {
				files.push(
					{
						uid: idx,
						name: item.originFileName,
						status: 'done',
						url: item.fileAddress,
					},
				)	
			})
		}
		setFiles(files);
	}


	const props = {
		action: 'https://kjeonse.com',
		showUploadList: {
		showDownloadIcon: true,
		downloadIcon: [<div key={"0"}>다운로드<i className='xi-download'></i></div>],
		showRemoveIcon: true,
		removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
		},
	};

    return (
        <div className='Mypage charter MyDetails'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='MyDetails-box w-box'>
					<div className='h3-wrap'>
					<h3>{data ? data.detail.title : ""}<span className='date-wrap'>{data ? data.detail.created_at.slice(2,16) : ""}</span></h3>
						<div className='btn-wrap'>
							{
								localStorage.getItem("sortation2") == 1 ?
								<Select
									value={type || 0}
									onChange={handleChange}
									options={[
										{
										value: 0,
										label: '처리요청',
										},
										{
										value: 1,
										label: '처리보류',
										},
										{
										value: 2,
										label: '승인완료',
										},
									]}
								/>
								: ""
							}
							{/* <Select
								defaultValue={0}
								onChange={handleChange}
								options={[
									{
									value: 0,
									label: '처리요청',
									},
									{
									value: 1,
									label: '처리보류',
									},
									{
									value: 2,
									label: '승인완료',
									},
								]}
							/> */}
							<button className='list-btn' onClick={()=>{navigate(-1)}}>목록으로</button>
						</div>
					</div>
					<div className='editor-wrap' dangerouslySetInnerHTML={{ __html: content}}>
					</div>
					<div className='file-wrap'>
						<h3><PaperClipOutlined />파일첨부</h3>
						<div className='file-list'>
							<Upload {...props} fileList={files}></Upload>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default FacilityMG_Details;