import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';


function Reject_collection() {
	let navigate = useNavigate();

    return (
        <div className='Reject_collection'>
            <div className='icon-wrap'>
				<i className='xi-lock'></i>
			</div>
			<p className='p1'>이메일 무단 수집 거부</p>
			<p className='p2'>
				본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 <br />
				그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, <br />
				이를 위반시 정보통신망법에 의해 형사처벌됨을 유의하시기 바랍니다.
			</p>
        </div>
    )
}

export default Reject_collection;