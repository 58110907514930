import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import  ftLogo  from '../images/ftLOGO.svg';
import { BackTop } from 'antd';

function Footer() {
    return (
        <div className="footer">
            <div className='ft-logo'>
                <img src={ftLogo} alt="" />
            </div>
            <ul className='policy-ul'>
                <li className=''>
                    <a href='/Clause/Privacy_policy' target='_blank' rel='noreferrer'>개인정보처리방침</a>
                </li>
                <li>|</li>
                <li className=''>
                    <a href='/Clause/Utilization' target='_blank' rel='noreferrer'>이용약관</a>
                </li>
                <li>|</li>
                <li className=''>
                    <Link to="/Clause/Reject_collection">이메일무단수집거부</Link>
                </li>
            </ul>
            <div className='address-wrap'>
                <p>Email. kjeonse@gmail.com</p>
            </div>
            <p className='duty-p'>COPYRIGHT 2022 K전세 ALL RIGHT RESERVED.</p>
            <BackTop>
                <div className='up-btn'>
                    <i className='xi-arrow-up'></i>
                </div>
            </BackTop>
        </div>
    )
}

export default Footer;