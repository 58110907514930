import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// API
import axios from 'axios';


function Marketing() {
	let navigate = useNavigate();

    return (
		<div className='clause-wrap'>
			<p>
				<h1>K-전세 마케팅 정보 수신</h1>
				㈜가족의꿈 (이하 “회사”라고 합니다)은 개인정보보호법 및 정보통신망 이용촉진 및 정보보호등에 관한법률에 따라 광고·마케팅목적의 정보전송을 위한 사전 수신동의를 받고 있습니다. <br />
				<br />
				*전송방법: 회원 모바일 앱/웹 푸시, 이동전화번호, 문자, 알림톡, 이메일을 통해 전달될 수 있습니다.<br />
				<br />
				*전송내용: K-전세 서비스에서 제공되는 이벤트 및 혜택 정보, 투자 관련 정보, 임대차 관련 정보, 관련 기사 등 제휴사의 상품·서비스에 대한 광고, 홍보, 프로모션 제공<br />
				단 {"<"}광고성 정보 이외 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다.<br />
				<br />
				*철회안내: 수신동의 이후라도 회사 고객센터에 전화를 통해 동의를 철회할 수 있으며 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용할 수 있습니다.<br /> 단, 본 마케팅 정보 수신에 대한 동의를 거부하실 수 있으며, 이 경우 회원가입은 가능하나 일부 서비스 이용 및 각종 광고, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 서비스 제공이 제한될 수 있습니다.<br />
			</p>
		</div>
    )
}

export default Marketing;