import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


function JoinMBCompletion() {
	let navigate = useNavigate();


    return (
        <div className='account-page'>
			<div className='completion-wrap'>
                <div className='ck-img-wrap'>
                    <i className='xi-check'></i>
                </div>
                <p className='txt-top'>
                    <span>K-전세</span> 회원가입이 완료되었습니다
                </p>
                <p className='txt-bt'>
                    K-전세 회원가입을 진심으로 환영합니다. <br />
                    로그인 후 K-전세의 모든 서비스를 이용하실 수 있습니다.
                </p>
                <button onClick={()=>{navigate("/Account/Login")}}>로그인 페이지로 이동</button>
            </div>
		</div>
    )
}

export default JoinMBCompletion;