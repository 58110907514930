import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';


function ContractMG() {
	let navigate = useNavigate();

	let { id } = useParams();
    console.log(id);

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));

	// 메인 api 데이터
    let [data, setData] = useState();
	let [data_estate_contractor, setData_estate_contractor] = useState();

	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/contractor", {
			ref_estate_list_id: id
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data);
			setData_estate_contractor(response.data.data.list);
			console.log(response);
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);

	// useEffect(()=>{
	// 	if (data) {
	// 		let copy = [...data.list]
	// 		console.log(copy);
	// 	}
	// },[data])

	// 삭제버튼
	const onRemove = (id) => {

		if (window.confirm("정말 삭제합니까?")) {
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/delete/contractor", {
				id: id
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response);
				let copy = []
				data_estate_contractor.map((item, i)=>{
					if (item.id != id) {
						copy.push(item);
						console.log(copy);
						setData_estate_contractor(copy);
					}
				})
				alert("삭제되었습니다.");
			})
			.catch(function (error) {
				console.log(error);
			})
		} else {
			alert("취소합니다.");
		}
	};

    return (
        <div className='Mypage charter ContractMG'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='w-box'>
					<ul className='charter-tab'>
						<li onClick={()=>{navigate("/Mypage/CharterCR")}}>전세 현황</li>
						{/* <li className='now' onClick={()=>{navigate("/Mypage/ContractMG/" + id)}}>임대차 계약 관리</li> */}
						<li onClick={()=>{navigate("/Mypage/FacilityMG/" + id)}}>계약/시설 관리 문의</li>
						<li onClick={()=>{navigate("/Mypage/ManagerAN/" + id)}}>관리자 공지</li>
					</ul>
					<div className='h3-wrap'>
						<h3>{data ? data.title.title : ""}</h3>
						<button className='registration-btn' onClick={()=>{navigate("/Mypage/ContractMG_Registration/" + id)}}>등록하기</button>
					</div>
					<div className='table-wrap'>
						<div className='table-scroll-wrap'>
							<table className='table-st1'>
								<thead>
									<tr>
										<th>구분</th>
										<th>제목</th>
										<th>작성자</th>
										<th>작성일</th>
										<th>상세보기</th>
									</tr>
								</thead>
								<tbody>
									{
										data ?
										data_estate_contractor.map((item, i)=>{
											return(
												<tr key={i}>
													<td>{data_estate_contractor.length - i}</td>
													<td>{item.title}</td>
													<td>{item.writerType}</td>
													<td>{item.created_at.slice(2,16)}</td>
													<td className='btn-td'>
														{
															item.writer == 1 ?
																<div className='btn-wrap'>
																	<button className='correct' onClick={()=>{navigate("/Mypage/ContractMG_update/" + item.id)}}>수정</button>
																	<button className='cancel' onClick={()=>{onRemove(item.id)}}>삭제</button>
																</div>
															:
																<div className='btn-wrap'>
																	<button className='details' onClick={()=>{navigate("/Mypage/ContractMG_Details/" + item.id)}}>상세보기</button>
																</div>
														}
													</td>
												</tr>
											)
										})
										:	
										<tr></tr>
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default ContractMG;