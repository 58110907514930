import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// 엔트디자인
import { Input } from 'antd';
import { Radio, Collapse } from 'antd';
import { Progress } from 'antd';
import { Pagination } from 'antd';

// API
import axios from 'axios';

function Question() {
	let navigate = useNavigate();
	
	// fap api 데이터
	let [data, setData] = useState();
	useEffect(()=>{
		axios.get("https://api.kjeonse.com/api/v1/list/faq").then((res) => {
			setData(res.data.data);
		}).catch(() => {console.log("실패");});
	},[]);
	console.log(data);

	// fap 카테고리 데이터
	let [categoryData, setCategoryData] = useState();
	useEffect(()=>{
		axios.get("https://api.kjeonse.com/api/v1/category/board/faq").then((res) => {
			setCategoryData(res.data);
		}).catch(() => {console.log("실패");});
	},[]);
	console.log(categoryData);


	// 검색
	const { Search } = Input;
	const onSearch = (e) => {
		axios.get("https://api.kjeonse.com/api/v1/search/faq?search="+ e +"&type=" + nowCategory).then((res) => {
			setData(res.data.data);
		}).catch(() => {console.log("실패");});
	}

	// 토글
	const { Panel } = Collapse;

	// 카테고리 텝
	let [nowCategory, setNowCategory] = useState("");
	const tabChange = (e) => {
        console.log(`radio checked:${e.target.value}`);
		if (e.target.value == "all") {
			axios.get("https://api.kjeonse.com/api/v1/list/faq").then((res) => {
				setData(res.data.data);
			}).catch(() => {console.log("실패");});
			setNowCategory("");
		}else{
			axios.get("https://api.kjeonse.com/api/v1/list/faq?type=" + e.target.value).then((res) => {
				setData(res.data.data);
			}).catch(() => {console.log("실패");});
			setNowCategory(e.target.value);
		}
    };

	// 페이지네이션
	const [current, setCurrent] = useState(1);
	const PaginationChange = (page) => {
		console.log(page);
		setCurrent(page);
		axios.get("https://api.kjeonse.com/api/v1/list/faq?page=" + page).then((res) => {
            setData(res.data.data);
        }).catch(() => {console.log("실패");});
	};

    return (
        <div className='sub Bulletin-page Question-page'>
            <div className='section1'>
				<div className='container'>
					<ul className='tab-li'>
						<li onClick={()=>{navigate("/Bulletin/Announcement")}}>이용안내</li>
						<li onClick={()=>{navigate("/Bulletin/News")}}>K-전세 소식</li>
						<li className='now' onClick={()=>{navigate("/Bulletin/Question")}}>자주하는 질문</li>
					</ul>
				</div>
			</div>
			<div className='section2'>
				<div className='container'>
					<div className='top-title'>
						<p className='title-p'>자주하는 질문</p>
					</div>
					<div className='Q-tab-wrap'>
						<div className='Q-tab-list'>
							<Radio.Group onChange={tabChange} defaultValue="all">
								<Radio.Button value="all">전체</Radio.Button>
								{
									categoryData ?
										categoryData.map((item, i)=>{
											return(
												<Radio.Button key={i} value={item.id}>{item.title}</Radio.Button>
											)
										})
									: ""
								}
								{/* <Radio.Button value="b">K-전세</Radio.Button>
								<Radio.Button value="c">투자</Radio.Button>
								<Radio.Button value="d">이용안내</Radio.Button> */}
							</Radio.Group>
						</div>
						<div className='search-wrap'>
							<Search
							placeholder="검색어를 입력하세요"
							enterButton={<i className='xi-search'></i>}
							size="large"
							onSearch={onSearch}
							/>
						</div>
					</div>
					<div className='question-list'>
						<Collapse accordion>
							{
								data ?
									data.list.data == 0 ?
										<div className='noData-wrap'>
											<p>검색 결과가 없습니다.</p>
										</div>
									:
									data.list.data.map((item, i)=>{
										return(
											<Panel header={
												<div className='panerl-hd'>
													<div className='question-wrap'>
														<span className='classification'>
															{item.category}
														</span>
														<p className='question'>
															{item.title}
														</p>
													</div>
													<i className='xi-angle-down'></i>
												</div>
											} key={i}>
												<p>
													{item.content}
												</p>
											</Panel>
										)
									})
								: ""
							}
                            {/* <Panel header={
                                <div className='panerl-hd'>
                                    <div className='question-wrap'>
                                        <span className='classification'>
                                            K-전세
                                        </span>
                                        <p className='question'>
                                            K-전세에 전세 매물을 등록하고 싶습니다
                                        </p>
                                    </div>
                                    <i className='xi-angle-down'></i>
                                </div>
                            } key="2">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.
                                </p>
                            </Panel> */}
                        </Collapse>
					</div>
					<Pagination current={current} onChange={PaginationChange} total={data? data.list.total : ""} defaultPageSize={10} />
				</div>
			</div>
        </div>
    )
}

export default Question;