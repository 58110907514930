import { atom } from 'recoil';

//  아이디
const Recoil_Email = atom({
	key: 'Recoil_Email',
	default: "", 
});

// 전화번호
const Recoil_Phone = atom({
	key: 'Recoil_Phone',
	default: "", 
});

// 이름
const Recoil_name = atom({
	key: 'Recoil_name',
	default: "", 
});

export {Recoil_Email , Recoil_Phone, Recoil_name};