import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';

// 다음주소검색
import DaumPostcode from 'react-daum-postcode';

// 앤트 디자인
import { DatePicker, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

function LessorRG() {
	let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 등록할떄 데이터들 ====================================================================
	// 계약명
	let [title , setTitle] = useState();
	// 우편번호
	let [postCode , setPostCode] = useState();
	// 주소
	let [address , setAddress] = useState();
	// 상세주소
	let [address_detail , setAddress_detail] = useState();
	// 전세 계약 시작 날짜
	let [open_at , setOpen_at] = useState();
	// 전세 계약 종료 날짜
	let [close_at , setClose_at] = useState();
	// 전세금
	let [total_payment_comma , setTotal_payment_comma] = useState();
	let [total_payment , setTotal_payment] = useState();

	useEffect(()=>{setTotal_payment(total_payment_comma ? commaOut(total_payment_comma) : "")},[total_payment_comma]);

	// 계약일
	let [contractor_create_at , setContractor_create_at] = useState();
	// 계약금
	let [payment_comma , setPayment_comma] = useState();
	let [payment , setPayment] = useState();

	useEffect(()=>{setPayment(payment_comma ? commaOut(payment_comma) : "")},[payment_comma]);

	// 계약금 지급 날짜
	let [payment_at , setPayment_at] = useState();
	// 중도금
	let [interim_comma , setInterim_comma] = useState();
	let [interim , setInterim] = useState();

	useEffect(()=>{setInterim(interim_comma ? commaOut(interim_comma) : "")},[interim_comma]);

	// 중도금 지급 날짜
	let [interim_at , setInterim_at] = useState();
	// 잔금
	let [balance_comma , setBalance_comma] = useState();
	let [balance , setBalance] = useState();

	useEffect(()=>{setBalance(balance_comma ? commaOut(balance_comma) : "")},[balance_comma]);

	// 잔금 지급 날짜
	let [balance_at , setBalance_at] = useState();
	// 임대인명
	let [lessor_name, setLessor_name] = useState();
	// 임대인 전화번호
	let [lessor_phone , setLessor_phone] = useState();
	// 임대인 비밀번호
	let [password , setPassword] = useState();
	// 첨부파일
	let [file , setFile] = useState();

	// 등록하기
	let formData = new FormData();
	formData.append('password', password);
	formData.append('lessor_name', lessor_name);
	formData.append('lessor_phone', lessor_phone);
	formData.append('title', title);
	formData.append('postCode', postCode);
	formData.append('address', address);
	formData.append('address_detail', address_detail);
	formData.append('open_at', open_at);
	formData.append('close_at', close_at);
	formData.append('payment_at', payment_at);
	formData.append('interim_at', interim_at);
	formData.append('balance_at', balance_at);
	formData.append('payment', payment);
	formData.append('interim', interim);
	formData.append('balance', balance);
	formData.append('contractor_create_at', contractor_create_at);
	formData.append('total_payment', total_payment);

	if(file) {
		if(file[0]) {
			formData.append('file', file ? file[0].originFileObj : "");
		}
		
	}
	

	function Registration() {
		// 검사
		if (!title || !postCode || !address || !address_detail || !open_at || !close_at || !total_payment || !lessor_name || !lessor_phone || !password || !file) {
			alert("필수내용을 입력해주세요");
		}else if (Number(total_payment) < (Number(payment) + Number(interim) + Number(balance) )) {
			alert("계약금 중도금 잔금의 합이 전세금을 초과하였습니다");
		}else{
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/register/lessor",formData,
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response);
				setPopOpen2(" open"); 
			})
			.catch(function (error) {
				console.log(error);
				alert("잘못된 접근이 감지되었습니다");
			})
		}
	}

	// data달력 =====================================
	// 전세기간
	const { RangePicker } = DatePicker;
	function lease_term(date, dateString) {
		// 전세 계약 시작 날짜
		setOpen_at(dateString[0]);
		// 전세 계약 종료 날짜
		setClose_at(dateString[1]);
	}

	// 콤마찍는 함수
	function comma(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			return(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			return(formatValue);
			
		}
	}
	// 콤마 지우기 함수
	function commaOut(e) {
		const number = Number(e.toString().split(',').join(""));
		return(number)
	}

	// 파일업로드
	const props = {

		name: 'file',

		beforeUpload: file => {
			return false;	// 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
		},

		onChange(info) {
			if (info.file.status !== 'uploading') {
				// console.log(info.file, info.fileList);
				if (info.fileList) {
					setFile(info.fileList);
					console.log(info.fileList)
				}else{
					setFile()
				}
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onRemove(info) {
			console.log(info);
			setFile()
		}
	};



	// 팝업 =======================
	// 주소검색 팝업
    let [popOpen1 , setPopOpen1] = useState("");
	let [modalState , setModalState] = useState(true)
	// 둥록완료 팝업
    let [popOpen2 , setPopOpen2] = useState("");

	const phoneSetOnChangeHandle = (e) => {

		const regex = /^[0-9\b -]{0,11}$/;

        const TARGET_VALUE = e.target.value;

		if (regex.test(TARGET_VALUE)) {
            setLessor_phone(TARGET_VALUE);
			console.log(TARGET_VALUE);
		}
		
	};
	
    return (
        <div className='Mypage charter-RG big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>임대인 전세 등록</h2>
				</div>
				<div className='flex-wrap'>
					<div className='flex-le w-box'>
						<ul className='RG-input-list'>
							<li>
								<p className='labal-p'>계약명<span>*</span></p>
								<div className='input-wrap'>
									<input 
										type="text" 
										value={title || ''} 
										onChange={(e)=>{setTitle(e.target.value)}} 
										placeholder='다른 계약과 구분할 수 있는 이름을 적어주세요. 예) 송파동 아파트 1002호' 
									/>
								</div>
							</li>
						</ul>
						<ul className='RG-input-list'>
							<li>
								<p className='labal-p'>주소<span>*</span></p>
								<div className='input-wrap address-wrap'>
									<input 
										type="text" 
										placeholder="우편번호" 
										value={postCode || ''} 
										readOnly
									/>
									<button 
										className='address-sc' 
										onClick={()=>{setPopOpen1(" open"); setModalState(true)}}
									>
										주소검색
									</button>

									<input 
										type="text" 
										placeholder="주소" 
										value={address || ''} 
										readOnly
									/>
									<input 
										type="text" 
										placeholder="상세주소" 
										value={address_detail || ''} 
										onChange={(e)=>{setAddress_detail(e.target.value)}} 
									/>
								</div>
							</li>
							<li>
								<p className='labal-p'>전세기간<span>*</span></p>
								<div className='input-wrap'>
									<RangePicker onChange={lease_term}/>
								</div>
							</li>
							<li>
								<p className='labal-p'>전세금<span>*</span></p>
								<div className='input-wrap'>
									<input 
										type="text" 
										className='add-unit' 
										value={total_payment_comma || 0} 
										onChange={(e)=>{setTotal_payment_comma(comma(e))}} 
									/>
									<div className='Unit'>
										<p>원</p>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>계약일</p>
								<div className='input-wrap'>
									<DatePicker onChange={(e,date)=>{setContractor_create_at(date)}} />
								</div>
							</li>
							<li>
								<p className='labal-p'>계약금</p>
								<div className='input-wrap'>
									<input 
										type="text" 
										className='add-unit' 
										value={payment_comma || 0} 
										onChange={(e)=>{setPayment_comma(comma(e))}} 
									/>
									<div className='Unit'>
										<p>원</p>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>계약금<br />지급날짜</p>
								<div className='input-wrap'>
									<DatePicker onChange={(e,date)=>{setPayment_at(date)}} />
								</div>
							</li>
							<li>
								<p className='labal-p'>중도금</p>
								<div className='input-wrap'>
									<input 
										type="text" 
										className='add-unit' 
										value={interim_comma || 0} 
										onChange={(e)=>{setInterim_comma(comma(e))}} 
									/>
									<div className='Unit'>
										<p>원</p>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>중도금<br />지급날짜</p>
								<div className='input-wrap'>
									<DatePicker onChange={(e,date)=>{setInterim_at(date)}} />
								</div>
							</li>
							<li>
								<p className='labal-p'>잔금</p>
								<div className='input-wrap'>
									<input 
										type="text" 
										className='add-unit' 
										value={balance_comma || 0} 
										onChange={(e)=>{setBalance_comma(comma(e))}} 
									/>
									<div className='Unit'>
										<p>원</p>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>잔금<br />지급날짜</p>
								<div className='input-wrap'>
									<DatePicker onChange={(e,date)=>{setBalance_at(date)}} />
								</div>
							</li>
						</ul>
						<ul className='RG-input-list'>
							<li>
								<p className='labal-p'>임대인명<span>*</span></p>
								<div className='input-wrap'>
									<input 
										type="text" 
										value={lessor_name || ''} 
										onChange={(e)=>{setLessor_name(e.target.value)}} 
									/>
								</div>
							</li>
							<li>
								<p className='labal-p'>전화번호<span>*</span></p>
								<div className='input-wrap'>
									<input 
										type="text" 
										value={lessor_phone || ''} 
										onChange={(e) => phoneSetOnChangeHandle(e)} 
										placeholder="휴대폰 번호를 입력하세요 (-없이 숫자만)"
									/>
								</div>
							</li>
							<li>
								<p className='labal-p'>비밀번호<span>*</span></p>
								<div className='input-wrap'>
									<input 
										type="text" 
										value={password || ''} 
										onChange={(e)=>{setPassword(e.target.value || '')}} 
									/>
								</div>
							</li>
						</ul>
						<ul className='RG-input-list'>
							<li>
								<p className='labal-p'>첨부파일<span>*</span></p>
								<div className='input-wrap'>
									<Upload {...props} maxCount={1}>
										<Button icon={<UploadOutlined />}>파일 업로드</Button>
									</Upload>
								</div>
							</li>
						</ul>
						<button className='charter-RG-btn' onClick={()=>{Registration()}}>임대인 전세 등록</button>
					</div>
					<div className='flex-ri w-box'>
						<div className='txt-wrap'>
							<p className='txt-top'>
								<span>전세금으로</span> 배당받는 <br />
								대한민국의 <br />
								새로운 전세 시스템
							</p>
							<p className='txt-bt'>
								전세로 계약하고 월세처럼 다달이 수익금을 지급받으세요
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='Popup-wrap'>
                <AddressSearch1 popOpen1={popOpen1} setPopOpen1={setPopOpen1} setPostCode={setPostCode} setAddress={setAddress} modalState={modalState} setModalState={setModalState}></AddressSearch1>
                <AddressSearch2 popOpen2={popOpen2} setPopOpen2={setPopOpen2}></AddressSearch2>
            </div>
        </div>
    )
}

function AddressSearch1({popOpen1, setPopOpen1, setPostCode, setAddress, modalState, setModalState}) {

	function onCompletePost(data) {
		setPopOpen1("")
		setAddress(data.address)
		setPostCode(data.zonecode)
		setModalState(false);
	}

    return(
        <div className={'popup-bg AddressSearch1' + popOpen1}>
            <div className='popup-box'>
				<div className='AddressSearch-h2-wrap'>
					<h2>주소찾기</h2>
					<button className='close-btn'
						onClick={()=>{setPopOpen1("")}}
					><i className='xi-close-thin'></i></button>
				</div>
                {
					modalState ?
						<div>
							<DaumPostcode className='DaumPostcode'
								onComplete={onCompletePost}
							></DaumPostcode>
						</div>
					: null
				}
            </div>
        </div>
    )
}

function AddressSearch2({popOpen2 , setPopOpen2}) {
    let navigate = useNavigate();
	
    return(
        <div className={'popup-bg AddressSearch2' + popOpen2}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen2("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <div className='pop-h2-wrap'>
                        <i className='xi-check-circle'></i>
                        <h2>전세 등록 완료</h2>
                    </div>
                    <div className='pop-p-box'>
                        <p>
							전세 등록이 완료되었습니다. <br />
							관리자 승인 후 {"[마이페이지>전세 부동산 관리]"} 를 통해 <br />
							등록된 전세계약 건을 확인하실 수 있습니다.
                        </p>
                    </div>
                    <div className='pop-btn-wrap2'>
                        <button className='close-btn' onClick={()=>{navigate("/Mypage/CharterCR")}}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessorRG;