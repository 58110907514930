import { atom } from 'recoil';

// 은행
const BankId = atom({
	key: 'BankId',
	default: 0, 
});

// 계좌번호
const AccountNum = atom({
	key: 'AccountNum',
	default: 0, 
});

// 금액
const Amount = atom({
	key: 'Amount',
	default: 0, 
});


export {BankId, AccountNum, Amount};