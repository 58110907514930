import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';


function CharterCR() {
	let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));

	// 메인 api 데이터
    let [data, setData] = useState();

	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/estate/", {
			
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data);
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);

	console.log(data);

	// 임차 임대 구분하기
	let lease_sortation = [];
	let [sortation , setSortation] = useState()
	useEffect(()=>{
		if (data) {
			for (let i = 0; i < data.list.length; i++) {
				if (data.list[i].isLessor == 0) {
					console.log("임차인")
					lease_sortation.push({[data.list[i].id] : 0})
				}else{
					lease_sortation.push({[data.list[i].id] : 1})
				}
			}
		}
		setSortation(lease_sortation);
	},[data])
	console.log(sortation);
	localStorage.setItem("sortation" , JSON.stringify(sortation));
	console.log(localStorage.getItem("sortation"));

    return (
        <div className='Mypage charter charterCR'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
					<div className='btn-wrap'>
						<button onClick={()=>{navigate("/Mypage/TenantRG")}}>임차인 전세 등록</button>
						<button onClick={()=>{navigate("/Mypage/LessorRG")}}>임대인 전세 등록</button>
					</div>
				</div>
				<ul className='charter-list'>
					{
						data ?
							data.list.map((item, i)=>{
								return(
									<li className='w-box' key={i}>
										{
											item.admin_approve == 1 ?
											<ul className='charter-tab'>
												<li className='now' onClick={()=>{navigate("/Mypage/CharterCR")}}>전세 현황</li>
												{/* <li onClick={()=>{navigate("/Mypage/ContractMG/" + item.id)}}>임대차 계약 관리</li> */}
												<li onClick={()=>{navigate("/Mypage/FacilityMG/" + item.id)}}>계약/시설 관리 문의</li>
												<li onClick={()=>{navigate("/Mypage/ManagerAN/" + item.id)}}>관리자 공지</li>
											</ul>
											:
											<div className='stand-by'>
												관리자 승인 대기중
											</div>
										}
										<div className='flex-wrap'>
											<div className='img-wrap'></div>
											<div className='simple-box'>
												<div className='title-wrap'>
													<p className='title'>{item.title}</p>
													{item.isLessor == 0 ? <span>임차</span> : <span>임대</span>}
												</div>
												<p className='price price1'>
													{(item.contractor_total_amount).toLocaleString()}원
												</p>
												<ul className='Info-li'>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-maker'></i></div>
															<p>주    소</p>
														</div>
														<p className='content-p'>{item.address} {item.address_detail}</p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-calendar-check'></i></div>
															<p>전세기간</p>
														</div>
														<p className='content-p'>
															{item.contractor_open_at.slice(0,4)}년 {item.contractor_open_at.slice(5,7)}월 {item.contractor_open_at.slice(8,10)}일 
															~ 
															{item.contractor_closed_at.slice(2,4)}년 {item.contractor_closed_at.slice(5,7)}월 {item.contractor_closed_at.slice(8,10)}일 
														</p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-user'></i></div>
															<p>임차인명</p>
														</div>
														<p className='content-p'>{item.name}</p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-call'></i></div>
															<p>전화번호</p>
														</div>
														<p className='content-p'>{item.phone}</p>
													</li>
													<li>
														<div className='labal-wrap'>
															<div className='icon'><i className='xi-tag'></i></div>
															<p>비밀번호</p>
														</div>
														<p className='content-p'>{item.password}</p>
													</li>
												</ul>
											</div>
										</div>
										<div className='charter-date-wrap'>
											<ul className='date-box'>
												<li>
													<p className='date-le'>계약일</p>
													<p className='date-ri'>{
														item.contractor_created_at ?
															item.contractor_created_at != "0000-00-00" ?
																item.contractor_created_at
															: "-"
														: "-"
													}</p>
												</li>
												<li>
													<p className='date-le'>계약금</p>
													<p className='date-ri'>{item.contractor_payment.toLocaleString()}원</p>
												</li>
												<li>
													<p className='date-le'>계약금 지급날짜</p>
													<p className='date-ri'>{
														item.contractor_payment_at ? 
															item.contractor_payment_at != "0000-00-00" ?
																item.contractor_payment_at
															: "-"
														: "-"
													}</p>
												</li>
											</ul>
											<ul className='date-box'>
												<li>
													<p className='date-le'>중도금</p>
													<p className='date-ri'>{item.contractor_interim.toLocaleString()}원</p>
												</li>
												<li>
													<p className='date-le'>중도금 지급날짜</p>
													<p className='date-ri'>{
														item.contractor_interim_at ? 
															item.contractor_interim_at != "0000-00-00" ?
																item.contractor_interim_at
															: "-"
														: "-"
													}</p>
												</li>
											</ul>
											<ul className='date-box'>
												<li>
													<p className='date-le'>잔금</p>
													<p className='date-ri'>{item.contractor_balance.toLocaleString()}원</p>
												</li>
												<li>
													<p className='date-le'>잔금 지급날짜</p>
													<p className='date-ri'>{
														item.contractor_balance_at ?
															item.contractor_balance_at != "0000-00-00" ?
																item.contractor_balance_at
															: "-"
														: "-"
													}</p>
												</li>
											</ul>
										</div>
									</li>
								)
							})
						: ""
					}
					{/* <li className='w-box'>
						<ul className='charter-tab'>
							<li className='now' onClick={()=>{navigate("/Mypage/CharterCR")}}>전세 현황</li>
							<li onClick={()=>{navigate("/Mypage/ContractMG")}}>임대차 계약 관리</li>
							<li onClick={()=>{navigate("/Mypage/FacilityMG")}}>시설 관리 / 문의</li>
							<li onClick={()=>{navigate("/Mypage/ManagerAN")}}>관리자 공지</li>
						</ul>
						<div className='flex-wrap'>
							<div className='img-wrap'></div>
							<div className='simple-box'>
								<div className='title-wrap'>
									<p className='title'>송파동 아파트 2101</p>
									<span>임대</span>
								</div>
								<p className='price price1'>
									300,000,000원
								</p>
								<ul className='Info-li'>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-maker'></i></div>
											<p>주    소</p>
										</div>
										<p className='content-p'>서울시 송파구 송파동 234 송파아파트 102-2101</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-calendar-check'></i></div>
											<p>전세기간</p>
										</div>
										<p className='content-p'>2021년 05월 31일 ~ 23년 05월 31일</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-user'></i></div>
											<p>임차인명</p>
										</div>
										<p className='content-p'>임차인</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-call'></i></div>
											<p>전화번호</p>
										</div>
										<p className='content-p'>01012341234</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-tag'></i></div>
											<p>비밀번호</p>
										</div>
										<p className='content-p'>12341234</p>
									</li>
								</ul>
							</div>
						</div>
						<div className='charter-date-wrap'>
							<ul className='date-box'>
								<li>
									<p className='date-le'>계약일</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
								<li>
									<p className='date-le'>계약금</p>
									<p className='date-ri'>30,000,000원</p>
								</li>
								<li>
									<p className='date-le'>계약일</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
							</ul>
							<ul className='date-box'>
								<li>
									<p className='date-le'>중도금</p>
									<p className='date-ri'>30,000,000원</p>
								</li>
								<li>
									<p className='date-le'>중도금 지급날짜</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
							</ul>
							<ul className='date-box'>
								<li>
									<p className='date-le'>잔금</p>
									<p className='date-ri'>30,000,000원</p>
								</li>
								<li>
									<p className='date-le'>잔금 지급날짜</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
							</ul>
						</div>
					</li> */}
					{/* <li className='w-box'>
						<div className='stand-by'>
							관리자 승인 대기중
						</div>
						<ul className='charter-tab'>
							<li className='now' onClick={()=>{navigate("/Mypage/CharterCR")}}>전세 현황</li>
							<li onClick={()=>{navigate("/Mypage/ContractMG")}}>임대차 계약 관리</li>
							<li onClick={()=>{navigate("")}}>시설 관리 / 문의</li>
							<li onClick={()=>{navigate("")}}>관리자 공지</li>
						</ul>
						<div className='flex-wrap'>
							<div className='img-wrap'></div>
							<div className='simple-box'>
								<div className='title-wrap'>
									<p className='title'>송파동 아파트 2101</p>
									<span>임대</span>
								</div>
								<p className='price price1'>
									300,000,000원
								</p>
								<ul className='Info-li'>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-maker'></i></div>
											<p>주    소</p>
										</div>
										<p className='content-p'>서울시 송파구 송파동 234 송파아파트 102-2101</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-calendar-check'></i></div>
											<p>전세기간</p>
										</div>
										<p className='content-p'>2021년 05월 31일 ~ 23년 05월 31일</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-user'></i></div>
											<p>임차인명</p>
										</div>
										<p className='content-p'>임차인</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-call'></i></div>
											<p>전화번호</p>
										</div>
										<p className='content-p'>01012341234</p>
									</li>
									<li>
										<div className='labal-wrap'>
											<div className='icon'><i className='xi-tag'></i></div>
											<p>비밀번호</p>
										</div>
										<p className='content-p'>12341234</p>
									</li>
								</ul>
							</div>
						</div>
						<div className='charter-date-wrap'>
							<ul className='date-box'>
								<li>
									<p className='date-le'>계약일</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
								<li>
									<p className='date-le'>계약금</p>
									<p className='date-ri'>30,000,000원</p>
								</li>
								<li>
									<p className='date-le'>계약일</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
							</ul>
							<ul className='date-box'>
								<li>
									<p className='date-le'>중도금</p>
									<p className='date-ri'>30,000,000원</p>
								</li>
								<li>
									<p className='date-le'>중도금 지급날짜</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
							</ul>
							<ul className='date-box'>
								<li>
									<p className='date-le'>잔금</p>
									<p className='date-ri'>30,000,000원</p>
								</li>
								<li>
									<p className='date-le'>잔금 지급날짜</p>
									<p className='date-ri'>2021. 04. 01</p>
								</li>
							</ul>
						</div>
					</li> */}
				</ul>
			</div>
        </div>
    )
}

export default CharterCR;