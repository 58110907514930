import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';

// 앤트 디자인

function TenantRG() {
	let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));


	const onChange = (date, dateString) => {
		console.log(dateString);
	};

	// 주고 값 저장
	// 우편번호
	let [postal_code, setPostal_code] = useState("");
	// 주소
	let [address, setAddress] = useState("");
	// 상세주소
	let [address_detail, setAddress_detail] = useState("");
	// 선택한 주소의 PK
	let [address_PK, setAddress_PK] = useState();
	// 임차인명
	let [name, setName] = useState();
	// 임차인 핸드폰
	let [phone, setPhone] = useState();
	// 임대인이 전세 등록 시 입력한 패스워드를 입력
	let [password, setPassword] = useState();

	function Registration() {
		// 검사
		if (!address_PK || !name || !phone || !password) {
			alert("필수내용을 입력해주세요");
		}else{
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/register/lessee",{
				id: address_PK,
				password: password,
				phone: phone,
				name: name
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response);
				if (response.data.msg) {
					alert(response.data.msg)
				}else{
					setPopOpen2(" open");
				}

			})
			.catch(function (error) {
				console.log(error);
				alert("잘못된 접근이 감지되었습니다");
				navigate("/")
			})
		}
	}



	// 팝업 =======================
	// 주소검색 팝업
    let [popOpen1 , setPopOpen1] = useState("");
	// 둥록완료 팝업
    let [popOpen2 , setPopOpen2] = useState("");

    return (
        <div className='Mypage charter-RG big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>임차인 전세 등록</h2>
				</div>
				<div className='flex-wrap'>
					<div className='flex-le w-box'>
						<ul className='RG-input-list'>
							<li>
								<p className='labal-p'>주소<span>*</span></p>
								<div className='input-wrap address-wrap'>
									<input type="text" placeholder="우편번호" value={postal_code} />
									<button className='address-sc' onClick={()=>{setPopOpen1(" open")}}>주소검색</button>
									<input type="text" placeholder="주소" value={address} />
									<input type="text" placeholder="상세주소" value={address_detail} />
								</div>
							</li>
						</ul>
						<ul className='RG-input-list'>
							<li>
								<p className='labal-p'>임차인명<span>*</span></p>
								<div className='input-wrap'>
									<input type="text" placeholder='임차인' value={name} onChange={(e)=>{setName(e.target.value)}} />
								</div>
							</li>
							<li>
								<p className='labal-p'>전화번호<span>*</span></p>
								<div className='input-wrap'>
									<input type="text" placeholder='임차인 연락처' value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
								</div>
							</li>
							<li>
								<p className='labal-p'>비밀번호<span>*</span></p>
								<div className='input-wrap'>
									<input type="text" placeholder='임대인이 등록한 비밀번호' value={password} onChange={(e)=>{setPassword(e.target.value)}} />
								</div>
							</li>
						</ul>
						{/* <ul className='RG-input-list'>
							<li>
								<p className='labal-p'>임대인명</p>
								<div className='input-wrap'>
									<input type="text" placeholder='임대인' />
								</div>
							</li>
							<li>
								<p className='labal-p'>전화번호</p>
								<div className='input-wrap'>
									<input type="text" placeholder='임대인 연락처' />
								</div>
							</li>
						</ul> */}
						<button className='charter-RG-btn' onClick={()=>{
							// setPopOpen2(" open")
							Registration()
							}}>임차인 전세 등록</button>
					</div>
					<div className='flex-ri w-box tenant-box'>
						<div className='txt-wrap'>
							<p className='txt-top'>
								모두가 만족하는 <br />
								<span>안전한 전세,</span> <br />
								K-전세에서 <span>현실</span>이 됩니다
							</p>
							<p className='txt-bt'>
								K-전세 펀드는 검증된 운용사가 안전하게 운용합니다.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='Popup-wrap'>
                <AddressSearch1 popOpen1={popOpen1} setPopOpen1={setPopOpen1} setPostal_code={setPostal_code} setAddress={setAddress} setAddress_PK={setAddress_PK} setAddress_detail={setAddress_detail}></AddressSearch1>
                <AddressSearch2 popOpen2={popOpen2} setPopOpen2={setPopOpen2}></AddressSearch2>
            </div>
        </div>
    )
}

function AddressSearch1({popOpen1, setPopOpen1, setPostal_code, setAddress, setAddress_detail, setAddress_PK}) {
	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 주소검색
	let [search_word , setSearch_word] = useState("");
	// 검색 결과
	let [search_Result , setSearch_Result] = useState();

	function search_btn() {
		if (search_word) {
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/search/estate", {
				address: search_word
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response.data.data);
				setSearch_Result(response.data.data);
			})
			.catch(function (error) {
				console.log(error);
				alert("잘못된 접근입니다");
				// navigate("/");
				window.location.reload();
			})
		}else{
			alert("검색어를 입력해주세요")
		}
	}

    return(
        <div className={'popup-bg AddressSearch1' + popOpen1}>
            <div className='popup-box'>
				<div className='AddressSearch-h2-wrap'>
					<h2>주소찾기</h2>
					<button className='close-btn' onClick={()=>{setPopOpen1("");}}><i className='xi-close-thin'></i></button>
				</div>
				<div className='popup-content'>
                    <div className='AddressSearch-input'>
						<input type="text" value={search_word} onChange={(e)=>{setSearch_word(e.target.value)}} placeholder='예) 행동대로 517, 삼성동 150' />
						<button onClick={()=>{search_btn()}}><i className='xi-search'></i></button>
					</div>
					{
						!search_Result ?
						<div className='tip-wrap'>
							<span>tip</span>
							<div className='tip-ri'>
								<p>
									도로명이나 지역명을 이용해서 검색해 보세요. 건물번호, 번지를 함께 입력하시면 <br />
									더욱 정확한 결과가 검색됩니다.
								</p>
								<ul>
									<li>· 도로명+건물번호</li>
									<li>· 지역명(동/리)+번지</li>
									<li>· 지역명(동/리)+건물명(아파트명)</li>
									<li>· 사서함명+번호</li>
								</ul>
							</div>
						</div>
						:
						<ul className='search_Result_list'>
							{
								search_Result ? 
									search_Result.map((item, i)=>{
										return(
											<li key={i} onClick={()=>{
												setPostal_code(item.post_code);
												setAddress(item.address);
												setAddress_detail(item.address_detail)
												setAddress_PK(item.id);
												setPopOpen1("");
											}}>
												<div>
													<span>우편번호</span>
													<p>{item.post_code}</p>
												</div>
												<div>
													<span>주 소</span>
													<p>{(item.address)+ " " + (item.address_detail)}</p>
												</div>
											</li>
										)
									})
								: ""
							}
						</ul>
					}
                </div>
            </div>
        </div>
    )
}

function AddressSearch2({popOpen2 , setPopOpen2}) {
    let navigate = useNavigate();


    return(
        <div className={'popup-bg AddressSearch2' + popOpen2}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen2("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <div className='pop-h2-wrap'>
                        <i className='xi-check-circle'></i>
                        <h2>전세 등록 완료</h2>
                    </div>
                    <div className='pop-p-box'>
                        <p>
							전세 등록이 완료되었습니다. <br />
							관리자 승인 후 {"[마이페이지>전세 부동산 관리]"} 를 통해 <br />
							등록된 전세계약 건을 확인하실 수 있습니다.
                        </p>
                    </div>
                    <div className='pop-btn-wrap2'>
                        <button className='close-btn' onClick={()=>{navigate("/Mypage/CharterCR")}}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TenantRG;