import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// 엔트디자인
import { Input } from 'antd';
import { Radio, Collapse } from 'antd';
import { Progress } from 'antd';
import { Pagination } from 'antd';

// API
import axios from 'axios';

// 리코일
import { AnnouncementState } from "../../state/AnnouncementState";
import { useRecoilState, 
    useRecoilValue, 
    useSetRecoilState, 
    useResetRecoilState 
} from 'recoil';

function Investment() {
	let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));


	// 메인 api 데이터
    let [data, setData] = useState();
	

	// api 데이터
	useEffect(()=>{
		if (tokenState) {
			axios.post("https://api.kjeonse.com/api/v1/auth/contest", {
				user_id: userState,
				type: "",
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response)
				setData(response.data.data)
			})
			.catch(function (error) {
				console.log(error);
			})
		}else{
			axios.put("https://api.kjeonse.com/api/v1/contest/list", {
				type: "",
			})
			.then(function (response) {
				console.log(response)
				setData(response.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
		}
    },[]);
	console.log(data);

	// 검색
	const { Search } = Input;
	let [now_keyword,setNow_keyword]= useState(""); 
	const onSearch = (e) => {
		setNow_keyword(e)
		if (tokenState) {
			if (now_type == "all") {
				axios.post("https://api.kjeonse.com/api/v1/auth/contest", {
					user_id: userState,
					type: "",
					keyword : e,
				},
				{
					headers: {
						Authorization: "Bearer " + tokenState , 
						// "Content-type": 'application/json',
					},
				})
				.then(function (response) {
					console.log(response.data.data);
					setData(response.data.data)
				})
				.catch(function (error) {
					console.log(error);
				})
			}else{
				axios.post("https://api.kjeonse.com/api/v1/auth/contest", {
					user_id: userState,
					type: now_type,
					keyword : e,
				},
				{
					headers: {
						Authorization: "Bearer " + tokenState , 
						// "Content-type": 'application/json',
					},
				})
				.then(function (response) {
					console.log(response.data.data);
					setData(response.data.data)
				})
				.catch(function (error) {
					console.log(error);
				})
			}
		}else{
			if (now_type == "all") {
				axios.put("https://api.kjeonse.com/api/v1/contest/list", {
					type: "",
					keyword : e,
				})
				.then(function (response) {
					console.log(response.data.data);
					setData(response.data.data);
				})
				.catch(function (error) {
					console.log(error);
				});
			}else{
				axios.put("https://api.kjeonse.com/api/v1/contest/list", {
					type: now_type,
					keyword : e,
				})
				.then(function (response) {
					console.log(response.data.data);
					setData(response.data.data);
				})
				.catch(function (error) {
					console.log(error);
				});
			}
		}
	};





	// 텝
	let [now_type, setNow_type] = useState("all");
	const tabChange = (e) => {
		console.log(e.target.value);
		setNow_type(e.target.value);
        console.log(`radio checked:${e.target.value}`);
		if (tokenState) {
			if (e.target.value == "all") {
				axios.post("https://api.kjeonse.com/api/v1/auth/contest", {
					user_id: userState,
					type: "",
					keyword : now_keyword,
				},
				{
					headers: {
						Authorization: "Bearer " + tokenState , 
						// "Content-type": 'application/json',
					},
				})
				.then(function (response) {
					setData(response.data.data)
				})
				.catch(function (error) {
					console.log(error);
				})
			}else{
				axios.post("https://api.kjeonse.com/api/v1/auth/contest", {
					user_id: userState,
					type: e.target.value,
					keyword : now_keyword,
				},
				{
					headers: {
						Authorization: "Bearer " + tokenState , 
						// "Content-type": 'application/json',
					},
				})
				.then(function (response) {
					setData(response.data.data)
				})
				.catch(function (error) {
					console.log(error);
				})
			}
		}else{
			if (e.target.value == "all") {
				axios.put("https://api.kjeonse.com/api/v1/contest/list", {
					type: "",
					keyword : now_keyword,
				})
				.then(function (response) {
					setData(response.data.data);
				})
				.catch(function (error) {
					console.log(error);
				});
			}else{
				axios.put("https://api.kjeonse.com/api/v1/contest/list", {
					type: e.target.value,
					keyword : now_keyword,
				})
				.then(function (response) {
					setData(response.data.data);
				})
				.catch(function (error) {
					console.log(error);
				});
			}
		}
    };
	// 좋아요 채크박스
	const heart = (id , e) => {
		if (tokenState) {
			axios.post("https://api.kjeonse.com/api/v1/contest/like", {
				user_id: userState,
				contest_id: id,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState ,
				},
			})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			})
		}else{
			navigate("/Account/Login")
		}
	};
	// 체크된 아이템을 담을 배열
	const [checkItems, setCheckItems] = useState([]);

	useEffect(()=>{
		let earlyCheck = [];
		if (data) {
			for (let i = 0; i < data.contest.data.length; i++) {
				if (data.contest.data[i].like == 0) {
					earlyCheck.push(data.contest.data[i].id);
					setCheckItems(earlyCheck);
				}
			}
		}
	},[data])

	// 체크박스 단일 선택
	const handleSingleCheck = (checked, id) => {
		if (checked) {
			// 단일 선택 시 체크된 아이템을 배열에 추가
			setCheckItems(prev => [...prev, id]);
		} else {
			// 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
			setCheckItems(checkItems.filter((el) => el !== id));
		}
	};
	
	// 페이지네이션
	const [current, setCurrent] = useState(1);
	const PaginationChange = (page) => {
		setCurrent(page);
		if (tokenState) {
			if (now_type == "all") {
				axios.post("https://api.kjeonse.com/api/v1/auth/contest", {
					user_id: userState,
					page: page,
					type: "",
					keyword : now_keyword,
				},
				{
					headers: {
						Authorization: "Bearer " + tokenState , 
						// "Content-type": 'application/json',
					},
				})
				.then(function (response) {
					setData(response.data.data)
				})
				.catch(function (error) {
					console.log(error);
				})
			}else{
				axios.post("https://api.kjeonse.com/api/v1/auth/contest", {
					user_id: userState,
					page: page,
					type: now_type,
					keyword : now_keyword,
				},
				{
					headers: {
						Authorization: "Bearer " + tokenState , 
						// "Content-type": 'application/json',
					},
				})
				.then(function (response) {
					setData(response.data.data)
				})
				.catch(function (error) {
					console.log(error);
				})
			}
		}else{
			if (now_type === "all") {
				console.log("asdasdasdasdasdasdasd")
				axios.put("https://api.kjeonse.com/api/v1/contest/list", {
					page: page,
					type: "",
					keyword : now_keyword,
				})
				.then(function (response) {
					setData(response.data.data);
				})
				.catch(function (error) {
					console.log(error);
				});
			}else{
				console.log("123123123123123123123123123")
				axios.put("https://api.kjeonse.com/api/v1/contest/list", {
					page: page,
					type: now_type,
					keyword : now_keyword,
				})
				.then(function (response) {
					setData(response.data.data);
				})
				.catch(function (error) {
					console.log(error);
				});
			}
		}
	};

	// 남은날짜 구하기
	function dday(e) {
		let today = new Date();
		let dday = new Date(e)
		let gap = dday.getTime() - today.getTime();
		let result = Math.ceil(gap / (1000 * 60 * 60 * 24));
		return result
	}

    return (
        <div className='sub investment-page'>
            <div className='section1'>
				<div className='container'>
					<div className='txt-wrap'>
						<p className='txt-top'>
							<span>전세</span> 주고 <br />
							<span>월세</span> 받는 <br />
							신개념 전세 펀드
						</p>
						<p className='txt-bt'>
							전세로 계약하고 월세처럼 다달이 <br /> 수익금을 지급받으세요
						</p>
					</div>
					<div className='search-wrap'>
						<Search
						placeholder="투자 상품명, 건물명을 입력하세요"
						enterButton={<i className='xi-search'></i>}
						size="large"
						onSearch={onSearch}
						/>
					</div>
					<div className='scroll-img-wrap'></div>
				</div>
			</div>
			<div className='section2'>
				<div className='container'>
					<div className='investment-tab'>
						<Radio.Group onChange={tabChange} defaultValue="all">
                            <Radio.Button value="all">전체</Radio.Button>
                            <Radio.Button value="2">공모중</Radio.Button>
							<Radio.Button value="1">공모예정</Radio.Button>
							<Radio.Button value="3">공모마감</Radio.Button>
                        </Radio.Group>
					</div>
					<ul className='investment-list'>
						{
							data ?
							data.contest != 0 ?
								data.contest.data.map((item, i)=>{
									return(
										<li key={i}>
											<div className='investment-product'>
												<div className='investment-img' onClick={()=>{navigate("/Investment/InvestmentDT/" + item.id)}}>
													<img src={item.fileAddress ? item.fileAddress : require("../../images/basic-img.png")} alt="" />
													<div className='plus-wrap'><i className='xi-plus-thin'></i></div>
												</div>
												<div className='investment-ri'>
													{
														item.contest_status == 1 ?
															<span className='situation'>공모예정</span>
														: 
														item.contest_status == 2 ?
															<span className='situation Progress'>공모중</span>
														:
														item.contest_status == 3 ?
															<span className='situation'>공모마감</span>
														: ""
													}
													<div className='title-wrap'>
														<p className='title' onClick={()=>{navigate("/Investment/InvestmentDT/" + item.id)}}>
															{item.title}
														</p>
														<div className='heart-ck-wrap'>
															<input 
																type="checkbox" 
																id={"like" + item.id} 
																onChange={(e)=>{
																	heart(item.id , e);
																	handleSingleCheck(e.target.checked, item.id);
																}}
																checked={checkItems.includes(item.id) ? true : false}
															/>
															<label htmlFor={"like" + item.id}></label>
														</div>
													</div>
													<p className='description'>
														{item.description}
													</p>
													<div className='per-bar'>
														{
															item.contest_status == 2 ?
																<Progress percent={1} />
															: 
																<Progress percent={
																	Math.round( item.now_cost /item.cost * 100)
																} />
														}
													</div>
													<div className='amount-wrap'>
														{
															item.contest_status == 1 ?
																<p className='amount-p'>
																	{item.opened_at.slice(0,4)}년 {item.opened_at.slice(5,7)}월 공모예정
																</p>
															:
																<>
																	<div className='amount-le'>
																		<span className='per'>{
																			Math.round(item.now_cost /item.cost * 100)
																		}%</span>
																		<p className='amount'>
																			<span className='blue'>{item.now_cost.toLocaleString()}원</span>
																			/
																			<span className='grey'>{(item.cost - item.now_cost).toLocaleString()}원</span>
																		</p>
																	</div>
																	<span className='d-day'>{dday(item.closed_at) + "일 남음"}</span>
																</>
														}
													</div>
													<ul className='Info-list'>
														<li>
															<label>
																<div className='i-wrap'><i className='xi-maker'></i></div>
																<p><span>주</span><span>소</span></p>
															</label>
															<p className='Info-p'>{item.address}</p>
														</li>
														<li>
															<label>
																<div className='i-wrap'><i className='xi-calendar-check'></i></div>
																<p>공모일정</p>
															</label>
															<p className='Info-p'>
																{item.opened_at.slice(0,4)}년 {item.opened_at.slice(5,7)}월 {item.opened_at.slice(8,10)}일 
																~
																{item.closed_at.slice(0,4)}년 {item.closed_at.slice(5,7)}월 {item.closed_at.slice(8,10)}일 
															</p>
														</li>
														<li>
															<label>
																<div className='i-wrap'><i className='xi-money'></i></div>
																<p><span>공</span><span>모</span><span>액</span></p>
															</label>
															<p className='Info-p'>{item.cost.toLocaleString()}원</p>
														</li>
														<li>
															<label>
																<div className='i-wrap'><i className='xi-library-add'></i></div>
																<p>공모수량</p>
															</label>
															<p className='Info-p'>{item.token_count.toLocaleString()} KJT (1인당 최대 {item.buy_token_count.toLocaleString()} KJT)</p>
														</li>
														<li>
															<label>
																<div className='i-wrap'><i className='xi-tag'></i></div>
																<p>주요특징</p>
															</label>
															<p className='Info-p'>
																{item.feature}
															</p>
														</li>
													</ul>
												</div>
											</div>
										</li>
									)
								})
								: ""
							: ""
						}
					</ul>
					<Pagination current={current} onChange={PaginationChange} total={data? data.contest.total :""} defaultPageSize={5} />
				</div>
			</div>
        </div>
    )
}

export default Investment;