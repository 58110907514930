import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// API
import axios from 'axios';

// 앤트디자인
import { Checkbox, Col, Row } from "antd";
import { Radio, Collapse } from 'antd';

function JoinMBList() {
	
	let navigate = useNavigate();

	// 회원가입 모든 데이터
	// 이메일
	let [email, setEmail] = useState();
	// 비밀번호
	let [password, setPassword] = useState();
	let [passwordCheck, setPasswordCheck] = useState();
	// 본인인증 번호
	let [phone, setPhone] = useState("");
	// 이름
	let [name, setName] = useState();
	// 생년월일
	let [birth, setBirth] = useState();
	// 질문
	let [question1, setQuestion1] = useState();
	let [question2, setQuestion2] = useState();
	let [question3, setQuestion3] = useState();
	let [question, setQuestion] = useState();
	// 약관동의
	// 이용약관,개인정보처리방침
	let [agree, setAgree] = useState(1);
	// 마케팅
	let [marketing, setMarketing] = useState(1);

	// 등록할 데이터들
	let formData = new FormData();
	formData.append('email', email);
	formData.append('password', password);
	formData.append('name', name);
	formData.append('birth', birth);
	formData.append('phone', phone);
	formData.append('privacy_agree', agree);
	formData.append('privacy_marketing', marketing);

	// 질문 저장============================================================
	const onChange1 = (checkedValues) => {
		console.log("checked = ", checkedValues);
		setQuestion1(checkedValues);
	};
	const onChange2 = (e) => {
		console.log('radio checked', e.target.value);
		setQuestion2(e.target.value);
	};
	const onChange3 = (checkedValues) => {
		console.log("checked = ", checkedValues);
		setQuestion3(checkedValues);
	};
	useEffect(()=>{
		let all_question = [];
		let copy = all_question.concat(question1, question2, question3)
		setQuestion(copy);
	},[question1, question2, question3]);

	useEffect(()=>{
		if (question) {
			question.map(item => {
				if (item != undefined) {
					formData.append('survy[]', item);
				}
			})
		}
		// for (let key of formData.keys()) {
		// 	console.log(key, ":", formData.get(key));
		// }
	},[question])

	// 인증하기
	function Authenticating() {

		if (!phone) {
			alert("인증번호를 입력해주세요");

		}else if (!email) {
			alert("이메일을 입력해주세요");

		}else{
			axios.post("https://api.kjeonse.com/api/v1/sms", {
				email: email,
				phone: phone,
			})
			.then(function (response) {
				console.log(response.data);
				if (response.data.data.invalid) {
					window.alert('인증번호 : ' + response.data.data.sms_code);
					stop_watch();
				}else{
					alert(response.data.msg);
				}
			})
			.catch(function (error) {
				console.log(error);
			})

		}
	}
	// 인증하기 누른후 인증하기버튼 비활성화
	let [button_activation, setButton_activation] = useState(true);
	// 인증번호
	let [certified_num, Certified_num] = useState("");
	// 인증완료 확인
	let [authentication_Chak, setAuthentication_Chak] = useState(false);

	// 타이머
	let [timer, setTimer] = useState();
	let time = 180;
	let min = ""; //분
	let sec = ""; //초

	// 타이머
	function stop_watch() {
		setButton_activation(false);
		
		let x = setInterval(() => {	
			min = Math.floor(time/60);
			sec = time%60;
			setTimer(min + "분" + sec + "초");
			time--
			if (time < 0) {
				clearInterval(x)
				setTimer("3분00초")
			}
		}, 1000);

		setTimeout(function(){
			setButton_activation(true);
		}, 180000);
	}
	// 인증확인
	function certified() {
		if (!certified_num) {
			alert("인증번호를 입력해주세요")
		}else{
			axios.post("https://api.kjeonse.com/api/v1/check/sms", {
				email: email,
				phone: phone,
				code: certified_num,
			})
			.then(function (response) {
				console.log(response.data);
				alert(response.data.msg);
				if (response.data.data == 0) {
					setAuthentication_Chak(true);
					setButton_activation(true);
				}else{
					setAuthentication_Chak(false);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	}

	function join() {
		if (question) {
			question.map(item => {
				if (item != undefined) {
					formData.append('survy[]', item);
				}
			})
		}

		if (!email || !password || !phone || !name || !birth || agree == 1) {
			console.log('1', email);
			console.log('2', password);
			console.log('3', phone);
			console.log('4', name);
			console.log('5', birth);
			console.log('6', agree);
			alert("필수내용를 입력해주세요")
		}else if (password != passwordCheck) {
			alert("비밀번호와 재확인비밀번호가 다릅니다")
		}else if (!authentication_Chak) {
			alert("본인인증이 필요합니다")
		}else{
			axios.post("https://api.kjeonse.com/api/v1/join", formData)
			.then(function (response) {
				console.log(response);
				navigate("/Account/JoinMBCompletion")
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	}

	const setPhoneHandle = (e) => {

		const regex = /^[0-9\b -]{0,13}$/;
		if (regex.test(e.target.value)) {
			setPhone(e.target.value);
		}

		
	}

	const setBirthHandle = (e) => {
		console.log('testtest111');
		setBirth(e.target.value);
	}

    return (
        <div className='account-page JoinMB'>
			<div className='account-bg'>
				<div className='logo-wrap'>
					<a href='/'>
						<img src={require("../../images/LOGO.png")} alt="" />
					</a>
				</div>
				<div className='w-box JoinMB-box'>
					<div className='JoinMB-box2'>
						<div className='h2-wrap'>
							<h2>Sign up</h2>
							<p>* 필수입력</p>
						</div>
						<ul className='input-li1'>
							<li>
								<p className='labal-p'>이메일 <span>*</span></p>
								<input type="text" placeholder='이메일 (이메일은 아이디로 사용됩니다)' onChange={(e)=>{setEmail(e.target.value)}} />
							</li>
							<li>
								<p className='labal-p'>비밀번호 <span>*</span></p>
								<div className='input-wrap1'>
									<input type="password" placeholder='비밀번호' onChange={(e)=>{setPassword(e.target.value)}} />
									<input type="password" placeholder='비밀번호 재확인' onChange={(e)=>{setPasswordCheck(e.target.value)}} />
								</div>
							</li>
							<li>
								<p className='labal-p'>본인인증 <span>*</span></p>
								<div className='input-wrap2'>
									{
										authentication_Chak ?
											<input 
												className='Deactivation' 
												type="text" 
												value={phone} 
												onChange={(e)=>{setPhoneHandle(e)}} 
											/>
											:
											button_activation ?
												<input 
													type="text" 
													value={phone} 
													onChange={(e)=>{setPhoneHandle(e)}} 
													placeholder="휴대폰 번호를 입력하세요(-없이 숫자만)"
												/>
												:
												<input 
													className='Deactivation' 
													type="text" 
													value={phone} 
													onChange={(e)=>{setPhoneHandle(e)}} 
												/>
									}
									{
										authentication_Chak ?
										<button className='certified-btn completion'>인증완료</button>
										:
										button_activation ?
											phone == "" ?
											<button className='certified-btn Deactivation' onClick={()=>{Authenticating()}}>인증하기</button>
											:
											<button className='certified-btn' onClick={()=>{Authenticating()}}>인증하기</button>
										:
										<button className='certified-btn Deactivation'>인증하기</button>
									}
									{
										!button_activation ?
										<>
										<input type="number" value={certified_num} onChange={(e)=>{Certified_num(e.target.value)}} placeholder='인증번호를 입력해주세요' />
										<button className='certified-btn' onClick={()=>{certified()}}>인증확인</button>
										<p className='timer-p'>{timer}</p>
										</>
										:
										""
									}
								</div>
							</li>
							<li>
								<p className='labal-p'>이름(실명) <span>*</span></p>
								<input type="text" placeholder='이름' onChange={(e)=>{setName(e.target.value)}} />
							</li>
							<li>
								<p className='labal-p'>생년월일 <span>*</span></p>
								<input 
									type="text" 
									placeholder='ex. 1994-09-05' 
									onChange={(e) => setBirthHandle(e)} 
								/>
							</li>
						</ul>
						<div className='ck-wrap'>
							<p>K-전세에서 관심있는 항목을 선택하세요 <span>(다중선택 가능)</span></p>
							<div className='ck-list'>
								<Checkbox.Group onChange={onChange1}>
									<Row>
										<Col span={8}>
											<Checkbox value={1}>임대</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={2}>임차</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={3}>투자</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={4}>기타</Checkbox>
										</Col>
									</Row>
								</Checkbox.Group>
							</div>
						</div>
						<div className='ck-wrap'>
							<p>임대 또는 임차하고자 하는 전세금은 대략 얼마인가요?</p>
							<div className='ck-list'>
								<Radio.Group onChange={onChange2} value={question2}>
									<Radio value={5}>1억 이하</Radio>
									<Radio value={6}>1억 ~ 2억</Radio>
									<Radio value={7}>2억 ~ 3억</Radio>
									<Radio value={8}>3억 ~ 4억</Radio>
									<Radio value={9}>4억 ~ 5억</Radio>
									<Radio value={10}>5억 ~ 7억</Radio>
									<Radio value={11}>7억 ~10억</Radio>
									<Radio value={12}>10억 이상</Radio>
								</Radio.Group>
							</div>
						</div>
						<div className='ck-wrap'>
							<p>K-전세에서 관심있는 항목을 선택하세요 <span>(다중선택 가능)</span></p>
							<div className='ck-list'>
								<Checkbox.Group onChange={onChange3}>
									<Row>
										<Col span={8}>
											<Checkbox value={13}>서울</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={14}>경기도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={15}>인천시</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={16}>부산시</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={17}>대전시</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={18}>대구시</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={19}>울산시</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={20}>세종시</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={21}>광주시</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={22}>강원도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={23}>충청북도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={24}>충청남도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={25}>경상북도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={26}>경상남도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={27}>전라북도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={28}>전라남도</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value={29}>제주도</Checkbox>
										</Col>
									</Row>
								</Checkbox.Group>
							</div>
						</div>
						<div className='consent-wrap'>
							<div className='consent-title'>
								<p className='title'>약관동의</p>
							</div>
							<ul className='Consent-input-wrap'>
								<li>
									<input type="checkbox" onChange={(e)=>{
										if (e.target.checked == false) {
											setAgree(1)
										}else{
											setAgree(0)
										}
									}} />
									<p><a href='/Clause/Utilization' target='_blank' rel='noreferrer'>이용약관</a> 및 <a href='/Clause/Privacy_policy' target='_blank' rel='noreferrer'>개인정보처리방침</a>에 동의합니다(필수)</p>
								</li>
								<li>
									<input type="checkbox" onChange={(e)=>{
										if (e.target.checked == false) {
											setMarketing(1)
										}else{
											setMarketing(0)
										}
									}} />
									<p><a href="/Clause/Marketing" target='_blank' rel='noreferrer'>마케팅 정보 수신</a> 에 동의합니다(선택)</p>
								</li>
							</ul>
						</div>
					</div>
					<button className='JoinMB-btn' onClick={()=>{join()}}>회원가입</button>
				</div>
			</div>
		</div>
    )
}

export default JoinMBList;