import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import util from '../util/util';


function JoinMBList() {

	let navigate = useNavigate();
	// const { naver, google } = window;

	
	// const kakaoLoginHandle = () => {

	// 	const scope = 'account_email';

	// 	window.Kakao.Auth.login({
	// 		scope,
	// 		success: function(response) {
	// 			window.Kakao.Auth.setAccessToken(response.access_token);
	// 		},
	// 		fail: function(error) {
	// 			console.log(error);
	// 		}
	// 	});

	// 	window.Kakao.API.request({
	// 		url: "/v2/user/me",
	// 		success: function({id}) {
				
	// 			axios.post('https://api.kjeonse.com/api/v1/sns/kakao', {

	// 				id: id,

	// 			}).then((res) => {
					
	// 				if(res.data.success) {
	// 					const resData = res.data.data;
	// 					if(resData.code == 1) {
							
	// 						localStorage.setItem( 'k-Jeonse-token', resData.token.access_token);
	// 						window.location.href = '/Mypage/Dashboard';

	// 					} else {

	// 						if(window.confirm("등록된 계정이 아닙니다. 카카오 소셜 회원가입을 바로 진행하시겠습니까?")) {
								
	// 							axios.post('https://api.kjeonse.com/api/v1/sns/register/kakao', {
	// 								id: id
	// 							}).then((registerRes) => {
									

	// 								if(registerRes.data.success) {
										
	// 									const tokenRes = registerRes.data.data;
										
	// 									if(tokenRes.code == 1 && tokenRes.token) {
	// 										localStorage.setItem( 'k-Jeonse-token', tokenRes.token.access_token );
	// 										window.location.href = '/mypage/Dashboard';
	// 									}
										
										
	// 								}

	// 							}) 

	// 						}

	// 					}

	// 				}

	// 			})

	// 		},
	// 		fail: function(error) {
				
	// 		}
	// 	});
	// }


	// const naverLoginHandle = () => {
		
	// 	const naver_client_id="AF74uxAzabWw_ddU3zjx";
		
	// 	const naverLogin = new naver.LoginWithNaverId({
	// 		clientId: naver_client_id,
	// 		callbackUrl: "https://kjeonse.com/oauth/naver",
	// 		isPopup: true,
	// 		loginButton: { color: 'green', type: 1}
	// 	});

	// 	naverLogin.init();
	// }


	// const setUpGoogleLogin = () => {

	// 	const src = "https://accounts.google.com/gsi/client";

	// 	let googleScript = util.checkScript('gsi/client', src, setUp, 'google');

	// 	function setUp() {
	// 		window.google.accounts.id.initialize({
	// 			client_id: "300209435760-b0m425lpd5j396lidaa0t92iql0v69a1.apps.googleusercontent.com",
	// 			callback: (res) => {
					
	// 				const payload = util.decodePaylod(res.credential)

	// 				console.log({ payload }, payload.name, payload.email, payload.picture)


	// 				axios.post('https://api.kjeonse.com/api/v1/sns/google', {

	// 					id: payload.aud,

	// 				}).then((res) => {
						
	// 					if(res.data.success) {
	// 						const resData = res.data.data;
	// 						if(resData.code == 1) {
								
	// 							localStorage.setItem( 'k-Jeonse-token', resData.token.access_token);
	// 							window.location.href = '/Mypage/Dashboard';

	// 						} else {

	// 							if(window.confirm("등록된 계정이 아닙니다. 카카오 소셜 회원가입을 바로 진행하시겠습니까?")) {
									
	// 								axios.post('https://api.kjeonse.com/api/v1/sns/register/google', {
	// 									id: payload.aud
	// 								}).then((registerRes) => {
										
	// 									if(registerRes.data.success) {
											
	// 										const tokenRes = registerRes.data.data;
											
	// 										if(tokenRes.code == 1 && tokenRes.token) {
	// 											localStorage.setItem( 'k-Jeonse-token', tokenRes.token.access_token );
	// 											window.location.href = '/mypage/Dashboard';
	// 										}
											
	// 									}

	// 								}) 

	// 							}

	// 						}

	// 					}

	// 				})
					

	// 				util.removeScript(googleScript)

	// 				const headChildren = document.head.children

	// 				for (let i = 0; i < headChildren.length; i++) {
	// 					if (headChildren[i]?.id?.includes('googleidentityservice') ?? false) {
	// 						util.removeScript(headChildren[i])
	// 					}
	// 				}
	// 			}
	// 		});

	// 		window.google.accounts.id.renderButton(
	// 			document.getElementById('googleLogin'),
	// 			{ theme: "outline", size: "large" }
	// 			// { type: "", shape: 'rectangle' }
	// 		)
	// 	}

	// }
	
	
	// // kakao, naver login sdk init
	// useEffect(() => {

	// 	const kakako_client_id="4c6ca53dec6e3fdc0d1073387f46dff2";

	// 	if(!window.Kakao.isInitialized()) {
	// 		window.Kakao.init(kakako_client_id);
	// 	}

	// 	naverLoginHandle();
	// 	setUpGoogleLogin();
	
	// }, [])

    return (
        <div className='account-page JoinMBList'>
			<div className='logo-wrap'>
				<img onClick={()=>{navigate("/")}} src={require("../../images/W-LOGO.png")} alt="" />
				<p>
					모두가 만족하는 안전한 전세, <br />
					K-전세에서 현실이 됩니다	
				</p>
			</div>
			<div className='JoinMBList-wrap'>
				<div className='JoinMBList-box'>
					<div className='JoinMBList-box2'>
						<p className='txt-top'>일반 회원가입</p>
						<p className='txt-bt'>이메일 아이디를 사용해 K-전세 회원으로 가입합니다.</p>
						<button className='JoinMB-btn' onClick={()=>{navigate("/Account/JoinMB")}}>
							회원가입
						</button>
					</div>
					{/* <div className='JoinMBList-box2'>
						<p className='txt-top'>일반 회원가입</p>
						<p className='txt-bt'>이메일 아이디를 사용해 K-전세 회원으로 가입합니다.</p>
						<ul className='sns-list'>
							<li>
								<button
									className='kakao' 
									onClick={kakaoLoginHandle}
								>
									<img src={require("../../images/login-kakao.png")} alt="" />
								</button>
							</li>
							<li>
								<button 
									className='naver'
									onClick={naverLoginHandle}
								>
									<img
										id='naverIdLogin' 
										src={require("../../images/login-naver.png")} 
										alt="" 
									/>
								</button>
							</li>
							<li>
								<button
									className='google' 
									id='googleLogin' 
								>
									<img src={require("../../images/login-google.png")} alt="" />
								</button>
							</li>
							<li>
								<button>
									<img src={require("../../images/login-apple.png")} alt="" />
								</button>
							</li>
						</ul>
					</div> */}
				</div>
			</div>
		</div>
    )
}

export default JoinMBList;