import { atom } from 'recoil';

// 이용안내 페이지
const AnnouncementState = atom({
	key: 'AnnouncementState',
	default: 1, 
});

// k전세-소식 페이지
const NewsState = atom({
	key: 'NewsState',
	default: 1, 
});


export {AnnouncementState, NewsState};