import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';
// 엔트디자인
import { Progress } from 'antd';

// API
import axios from 'axios';

function InterestInvestment() {
	let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));

	// 메인 api 데이터
    let [data, setData] = useState();
	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/contest/like/list", {
			user_id: userState
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data)
			
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);
	console.log(data)

	// 좋아요 채크박스
	const heart = (e) => {
		if (tokenState) {
			axios.post("https://api.kjeonse.com/api/v1/contest/like", {
				user_id: userState,
				contest_id: e,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState ,
				},
			})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			})
		}else{
			navigate("/Account/Login")
		}
	};

	
	// 체크된 아이템을 담을 배열
	const [checkItems, setCheckItems] = useState([]);

	useEffect(()=>{
		let earlyCheck = [];
		if (data) {
			for (let i = 0; i < data.list.length; i++) {
				earlyCheck.push(data.list[i].id);
				setCheckItems(earlyCheck);
			}
		}
	},[data])
	// 체크박스 단일 선택
	const handleSingleCheck = (checked, id) => {
		if (checked) {
			// 단일 선택 시 체크된 아이템을 배열에 추가
			setCheckItems(prev => [...prev, id]);
		} else {
			// 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
			setCheckItems(checkItems.filter((el) => el !== id));
		}
	};

	// 남은날짜 구하기
	function dday(e) {
		let today = new Date();
		let dday = new Date(e)
		let gap = dday.getTime() - today.getTime();
		let result = Math.ceil(gap / (1000 * 60 * 60 * 24));
		return result
	}

    return (
        <div className='Mypage InterestInvestment big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map2"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>관심 투자 목록</h2>
				</div>
				<ul className='InterestInvestment-list'>
					{
						data ?
						data.list.length != 0 ?
							data.list.map((item, i)=>{
								return(
									<li key={i} className="w-box">
										<div className='investment-product'>
											<div className='investment-img' onClick={()=>{navigate("/Investment/InvestmentDT/" + item.id)}}>
												<img src={item.fileAddress ? item.fileAddress : require("../../images/basic-img.png")} alt="" />
												<div className='plus-wrap'><i className='xi-plus-thin'></i></div>
											</div>
											<div className='investment-ri'>
												{
													item.contest_status == 1 ?
														<span className='situation'>공모예정</span>
													: 
													item.contest_status == 2 ?
														<span className='situation Progress'>공모중</span>
													:
													item.contest_status == 3 ?
														<span className='situation'>공모마감</span>
													: ""
												}
												<div className='title-wrap'>
													<p className='title' onClick={()=>{navigate("/Investment/InvestmentDT/" + item.id)}}>
														{item.title}
													</p>
													<div className='heart-ck-wrap'>
														<input 
															type="checkbox" 
															id={"like" + item.id} 
															// ref={(el)=>targets.current[item.id]=el} 
															onChange={(e)=>{
																heart(item.id , e);
																handleSingleCheck(e.target.checked, item.id);
															}}
															checked={checkItems.includes(item.id) ? true : false}
														/>
														<label htmlFor={"like" + item.id}></label>
													</div>
												</div>
												<p className='description'>
													{item.description}
												</p>
												<div className='per-bar'>
													{
														item.contest_status == 2 ?
															<Progress percent={1} />
														: 
															<Progress percent={
																Math.round(item.cost / item.now_cost)
															} />
													}
												</div>
												<div className='amount-wrap'>
													{
														item.contest_status == 1 ?
															<p className='amount-p'>
																{item.opened_at.slice(0,4)}년 {item.opened_at.slice(5,7)}월 공모예정
															</p>
														:
															<>
																<div className='amount-le'>
																	<span className='per'>
																		{Math.round((item.now_cost/item.cost)*100)}%
																	</span>
																	<p className='amount'>
																		<span className='blue'>{item.now_cost.toLocaleString()}원</span>
																		/
																		<span className='grey'>{(item.cost - item.now_cost).toLocaleString()}원</span>
																	</p>
																</div>
																<span className='d-day'>{dday(item.closed_at) + "일 남음"}</span>
															</>
													}
												</div>
												<ul className='Info-list'>
													<li>
														<label>
															<div className='i-wrap'><i className='xi-maker'></i></div>
															<p><span>주</span><span>소</span></p>
														</label>
														<p className='Info-p'>{item.address}</p>
													</li>
													<li>
														<label>
															<div className='i-wrap'><i className='xi-calendar-check'></i></div>
															<p>공모일정</p>
														</label>
														<p className='Info-p'>
															{item.opened_at.slice(0,4)}년 {item.opened_at.slice(5,7)}월 {item.opened_at.slice(8,10)}일 
															~
															{item.closed_at.slice(0,4)}년 {item.closed_at.slice(5,7)}월 {item.closed_at.slice(8,10)}일 
														</p>
													</li>
													<li>
														<label>
															<div className='i-wrap'><i className='xi-money'></i></div>
															<p><span>공</span><span>모</span><span>액</span></p>
														</label>
														<p className='Info-p'>{item.cost.toLocaleString()}원</p>
													</li>
													<li>
														<label>
															<div className='i-wrap'><i className='xi-library-add'></i></div>
															<p>공모수량</p>
														</label>
														<p className='Info-p'>{item.token_count.toLocaleString()} KJT (1인당 최대 {item.buy_token_count.toLocaleString()} KJT)</p>
													</li>
													<li>
														<label>
															<div className='i-wrap'><i className='xi-tag'></i></div>
															<p>주요특징</p>
														</label>
														<p className='Info-p'>
															{item.feature}
														</p>
													</li>
												</ul>
											</div>
										</div>
									</li>
								)
							})
						: <div className='no_content w-box'>관심 투자상품이 없습니다</div>
						: ""
					}
				</ul>
			</div>
        </div>
    )
}

export default InterestInvestment;