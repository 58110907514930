import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// 리코일
import { Recoil_Email, Recoil_name } from "../../state/PwFind";
import { useRecoilState} from 'recoil';

function IdCompletion() {
	let navigate = useNavigate();

    // 리코일로 이베일, 전화번호 저장
	let [recoilName , setRecoilName] = useRecoilState(Recoil_name);
	let [recoilEmail , setRecoilEmail] = useRecoilState(Recoil_Email);

    return (
        <div className='account-page IdCompletion'>
			<div className='completion-wrap'>
                <div className='ck-img-wrap'>
                    <i className='xi-check'></i>
                </div>
                <p className='txt-top'>
					{recoilName}님의 아이디는 <span>{recoilEmail}</span> 입니다.
                </p>
                <p className='txt-bt'>
					비밀번호가 기억나지 않으신가요?
                </p>
				<Link to={"/Account/PWfind1"}>비밀번호 재설정하기</Link>
                <button onClick={()=>{navigate("/Account/Login")}}>로그인 페이지로 이동</button>
            </div>
		</div>
    )
}

export default IdCompletion;