import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageMap from './MypageMap';

// 엔트디자인
import { DatePicker, Space } from 'antd';
import { Select } from 'antd';
// API
import axios from 'axios';

// 리코일
import { BankId, AccountNum, Amount } from "../../state/Withdrawal";
import { useRecoilState, 
    useRecoilValue, 
    useSetRecoilState, 
    useResetRecoilState 
} from 'recoil';

function ProfitMg() {
	let navigate = useNavigate();
	const { RangePicker } = DatePicker;

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	let [userState , setUserState] = useState(localStorage.getItem('k-Jeonse-user_id'));

	// 메인 api 데이터
    let [data, setData] = useState();
	// 출입금 내역
	let [dividendList, setDividendList] = useState();

	const [ sumWithDrawAmount, setSumWithDrawAmount] = useState(0);

	// api 데이터
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/dividend", {

		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data)
			setDividendList(response.data.data.dividendList)
			setSumWithDrawAmount(Number(response.data.data.dividendListSection.widthList));
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
    },[]);

	// 검색
	function inquiry() {
		if (startDay == "" || lastDay == "" ) {
			alert("날자를 입력해주세요");
		}else{
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/search/stock", {
				start_date: startDay,
				end_date: lastDay,
				type: type1,
			},
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response.data.data.dividendList)
				setDividendList(response.data.data.dividendList)
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	};

	// 날짜
	let [startDay , setStartDay] = useState("");
	let [lastDay , setLastDay] = useState("");
	let days = (value, mode) => {
		setStartDay(mode[0]);
		setLastDay(mode[1])
	}

	// 구분
	let [type1 , setType] = useState(null)
	const handleChange = (value) => {
		console.log(`selected ${value}`);
		if (value == "all") {
			setType(null);
		}else{
			setType(value);
		}
	};

	// 팝업 =======================
	// 알림내역 팝업 열고닫기
    let [popOpen1 , setPopOpen1] = useState("");
    let [popOpen2 , setPopOpen2] = useState("");

	useEffect(() => {
		if (popOpen1 != "" || popOpen2 != "") {
			document.body.style= `overflow: hidden`;
		}else{
			document.body.style = `overflow: auto`
		}
	}, [popOpen1, popOpen2])


	// 더보기
	let [ addNumber, setAddNumber ] = useState(10);
	let [ addDtn, setAddDtn ] = useState("");
	useEffect(()=>{
		if(data != null){
            const count = Object.keys(dividendList).length;
            if ( count <= addNumber ) {
                setAddDtn(" dp-none")
            }
        }
	});

	function comma(num){

		var len, point, str;  
		  
		num = num + "";  
		point = num.length % 3 ;
		len = num.length;  
	  
		str = num.substring(0, point);  
		while (point < len) {  
		    if (str != "") str += ",";  
		    str += num.substring(point, point + 3);  
		    point += 3;  
		}  
		
		return str;
	
	}

    return (
        <div className='Mypage ProfitMg big-container'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map5"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='section1'>
					<div className='h2-wrap'>
						<h2>수익관리</h2>
					</div>
					<div className='flex-wrap4'>
						<div className='flex-box w-box'>
							<h3>누적 수익금</h3>
							<p>{data ? Number(data.dividendListSection.total_sum_dividend_amount).toLocaleString() : "0"}원</p>
						</div>
						<div className='flex-box w-box'>
							<h3>누적 배당 횟 수</h3>
							<p>{data ? data.dividendListSection.total_sum_dividend_count :"0"}회</p>
						</div>
						<div className='flex-box w-box'>
							<h3>최근 출금액</h3>
							<p>{comma(sumWithDrawAmount)}원</p>
						</div>
						<div className='flex-box w-box blue'>
							<h3>출금 가능 금액 <button onClick={()=>{setPopOpen1(" open")}}>출금하기</button></h3>
							<p>{data ? data.dividendListSection.can_use_deposit_amount.toLocaleString() : "0"}원</p>
						</div>
					</div>
				</div>
				<div className='section2 w-box'>
					<div className='h2-wrap'>
						<h2>출입금 내역</h2>
						<div className='inquiry-wrap'>
							<RangePicker onChange={days} />
							<Select
								defaultValue="all"
								style={{ width: 120 }}
								onChange={handleChange}
								options={[
									{
									value: 'all',
									label: '전체',
									},
									{
									value: '0',
									label: '입금',
									},
									{
									value: '1',
									label: '투자',
									},
									{
									value: '2',
									label: '출금',
									},
									{
									value: '3',
									label: '배당금',
									},
								]}
							/>
							<button className='inquiry-btn' onClick={()=>{inquiry()}}>조회</button>
						</div>
					</div>
					<div className='table-wrap'>
						<div className='table-scroll-wrap'>
							<table className='table-st1'>
								<thead>
									<tr>
										<th>구분</th>
										<th>내용</th>
										<th>거래금액</th>
										<th>잔액</th>
										<th>거래날짜</th>
									</tr>
								</thead>
								<tbody>
									{
										dividendList ?
											dividendList.length != 0 ?
												dividendList.map((item, i)=>{
													if ( i < addNumber ) {
														return(
															<tr key={i}>
																<td>
																	{
																		item.type == 0 ? "입금" :
																		item.type == 1 ? "투자" :
																		item.type == 2 ? "출금" :
																		item.type == 3 ? "배당금" : ""
																	}
																</td>
																<td>{item.title ? item.title : ""}</td>
																{
																	item.type == 0 || item.type == 3 ? 
																	<td className='red-td'>+ {item.amount.toLocaleString()}</td> 
																	: 
																	<td className='blue-td'>- {item.amount.toLocaleString()}</td>
																}
																<td>{item.last_amount.toLocaleString()}</td>
																<td>{item.created_at.slice(2,16)}</td>
															</tr>
														)
													}
												})
											: <tr>
												<td colSpan={5}>검색결과가 없습니다</td>
											</tr>
										: <tr></tr>
									}
								</tbody>
							</table>
						</div>
						<button className={'more-btn' + addDtn} onClick={()=>{setAddNumber(addNumber + 10)}}>더보기 <i className='xi-angle-down'></i></button>
					</div>
				</div>
			</div>
			<div className='Popup-wrap'>
                <Withdrawal1 
					popOpen1={popOpen1} 
					setPopOpen1={setPopOpen1} 
					setPopOpen2={setPopOpen2} 
					can_use_deposit_amount={data ? data.dividendListSection.can_use_deposit_amount : ""}
				></Withdrawal1>
                <Withdrawal2 
					popOpen2={popOpen2} 
					setPopOpen2={setPopOpen2}
				></Withdrawal2>
            </div>
        </div>
    )
}

function Withdrawal1({popOpen1, setPopOpen1, setPopOpen2, can_use_deposit_amount}) {
	// 은행리스트 데이터 관리
	let [bankData , setBankData] = useState();
	let formData = [];
	let [formDataCopy , setFormDataCopy] = useState();
	// 은행리스트 api 받아오기
	useEffect(()=>{
        axios.get("https://api.kjeonse.com/api/v1/list/bank").then((res) => {
            setBankData(res.data);
        }).catch(() => {console.log("실패");});
    },[]);
	useEffect(()=>{
		if (bankData) {
			bankData.map((item,i)=>{
				formData.push({
					value: item.id,
					label: item.bank_name
				})
			})
			setFormDataCopy(formData);
		}
	},[bankData])

	// 은행선택
	let [bankid, setBankNum] = useRecoilState(BankId);
	const handleChange = (value) => {
		// console.log(`selected ${value}`);
		setBankNum(value);
		console.log(bankid);
	};

	// 게좌번호
	let [accountNum, setAccountNum] = useRecoilState(AccountNum);
	// 금액
	let [amount, setAmount] = useRecoilState(Amount);

	// 숫자인풋 콤마찍기
	let [amountVal , setAmountVal] = useState(0);
	function amountChange(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			setAmountVal(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			setAmountVal(formatValue);
		}
	}
	// 금액 콤마빼고 저장
	useEffect(()=>{
		const number = amountVal.toString().split(',').join("");
		setAmount(number);
		console.log(accountNum);
	},[amountVal])


	function request() {
		if (amount == 0) {
			alert("출금금액을 입력해주세여")
		}else if (can_use_deposit_amount < amount) {
			alert("최대출금금액을 초과했습니다. ")
		}else if (accountNum == 0) {
			alert("계좌번호를 입력해주세요")
		}else if (bankid == 0) {
			alert("은행을 선택해주세요")
		}else if (can_use_deposit_amount >= amount ) {
			setPopOpen1("");
			setPopOpen2(" open");
		}
	}

    return(
        <div className={'popup-bg Withdrawal1' + popOpen1}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen1("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <h2>출금 요청</h2>
					<p className='p-st1'>
						입금 받으실 계좌를 정확하게 입력해 주세요. <br />
						출금요청은 최대 5영업일 이내에 처리됩니다.
					</p>
					<div className='Account-input-wrap'>
						<div className='h3-wrap'>
							<h3>입금 받으실 계좌</h3>
						</div>
						<ul className='pop-input-ul'>
							<li>
								<Select
									defaultValue="은행 선택"
									onChange={handleChange}
									options={formDataCopy}
								/>
							</li>
							<li>
								<input type="number" placeholder='계좌번호 입력' className='normal-input' onChange={(e)=>{setAccountNum(e.target.value)}} />
							</li>
						</ul>
					</div>
					<div className='Account-input-wrap'>
						<div className='h3-wrap'>
							<h3>출금요청</h3>
						</div>
						<ul className='pop-input-ul'>
							<li>
								<p className='labal-p'>금액</p>
								<input type="text" id='number' maxLength='13' value={amountVal} onChange={(e)=>{amountChange(e)}} />
								<div className='Unit'>원</div>
							</li>
						</ul>
						<div className='Maximum-btn-wrap'>
							<button className='Maximum-btn' onClick={()=>{setAmountVal(can_use_deposit_amount.toLocaleString()); setAmount(can_use_deposit_amount);}}>최대금액</button>
						</div>
					</div>
					<div className='total-wrap'>
							<p className='labal-p'>출금금액</p>
							<p className='Amount-p'>{amountVal} 원</p>
						</div>
					<div className='pop-btn-wrap1'>
						<button className='close-btn' onClick={()=>{setPopOpen1("")}}>취소</button>
						<button className='Registration-btn' onClick={()=>{request()}}>출금요청</button>
					</div>
                </div>
            </div>
        </div>
    )
}

function Withdrawal2({popOpen2 , setPopOpen2}) {
	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));
	// 은행선택
	let [bankid, setBankNum] = useRecoilState(BankId);
	// 게좌번호
	let [accountNum, setAccountNum] = useRecoilState(AccountNum);
	// 금액
	let [amount, setAmount] = useRecoilState(Amount);
	
	function request() {
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/withDrawal", {
			bank_id : bankid,
			bank_account_number : accountNum,
			stock_amount : amount,
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
			},
		})
		.then(function (response) {
			if (response.data.success) {
				alert("출금요청이 완료되었습니다")
				setPopOpen2("");
				window.location.reload();
			}else{
				alert(response.data.msg);
			}
			
		})
		.catch(function (error) {
			console.log(error);
		})
	}

    return(
        <div className={'popup-bg Withdrawal2' + popOpen2}>
            <div className='popup-box'>
                <button className='close-btn'
                    onClick={()=>{setPopOpen2("")}}
                ><i className='xi-close-thin'></i></button>
                <div className='popup-content'>
                    <div className='pop-h2-wrap'>
                        <i className='xi-check-circle'></i>
                        <h2>출금요청</h2>
                    </div>
                    <div className='pop-p-box'>
                        <p>
							<span>{Number(amount).toLocaleString()}원</span> 을 입력하신 계좌로 출금하시겠습니까? <br />
							아래 출금 버튼을 클릭하시면 회원님의 예치금에서 <br />
							위 금액이 즉시 차감됩니다
                        </p>
                    </div>
                    <div className='pop-btn-wrap2'>
                        <button className='close-btn' onClick={()=>{setPopOpen2("")}}>취소</button>
                        <button className='Registration-btn' onClick={()=>{request()}}>출금</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfitMg;