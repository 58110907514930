import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// API
import axios from 'axios';

function MypageMap ({now}) {
	let navigate = useNavigate();

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 메인 api 데이터
    let [data, setData] = useState();

	// useEffect(()=>{
	// 	axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/estate/check", {
			
	// 	},
	// 	{
	// 		headers: {
	// 			Authorization: "Bearer " + tokenState , 
	// 			// "Content-type": 'application/json',
	// 		},
	// 	})
	// 	.then(function (response) {
	// 		setData(response.data.data);
	// 	})
	// 	.catch(function (error) {
	// 		console.log(error);
	// 		alert("잘못된 접근입니다");
	// 		navigate("/");
	// 		window.location.reload();
	// 	})
    // },[]);

	function charter_Chak() {
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/estate/check", {
			
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			// setData(response.data.data);
			if ( 0 < response.data.data.estate_count ) {
				navigate("/Mypage/CharterCR")
			}else{
				navigate("/Mypage/CharterIntro")
			}
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
	}

	return(
		<div className={"MypageMap " + now}>
			<h1 className="logo-wrap" onClick={()=>{navigate("/")}}>
				<img src={require("../../images/W-LOGO.png")} alt="" />
			</h1>
			<ul className="Mypage-list">
				<li onClick={()=>{navigate("/Mypage/Dashboard")}}>
					<i className="xi-layout-snb-o"></i>
					대시보드
				</li>
				<li onClick={()=>{navigate("/Mypage/InterestInvestment")}}>
					<i className="xi-heart-o"></i>
					관심 투자 목록
				</li>
				<li onClick={()=>{navigate("/Mypage/ChangeInfo")}}>
					<i className="xi-user"></i>
					정보 변경
				</li>
				<li onClick={()=>{navigate("/Mypage/Portfolio")}}>
					<i className="xi-bank"></i>
					포트폴리오
				</li>
				<li onClick={()=>{navigate("/Mypage/ProfitMg")}}>
					<i className="xi-money"></i>
					수익관리
				</li>
				<li onClick={()=>{
					// if ( 0 < data.estate_count ) {
					// 	navigate("/Mypage/CharterCR")
					// }else{
					// 	navigate("/Mypage/CharterIntro")
					// }
					charter_Chak()
				}}>
					<i className="xi-home-o"></i>
					전세 부동산 관리
				</li>
			</ul>
			<div className="logout-btn-wrap">
				<button className="logout-btn">
					<i className="xi-log-out"></i>
					로그아웃
				</button>
			</div>
		</div>
	)
}
export default MypageMap;