import { useEffect, useState, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MypageMap from './MypageMap';

// API
import axios from 'axios';

// 앤트디자인
import { Select } from 'antd';
import { StarOutlined, PaperClipOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

// 에디터
import ReactQuill from 'react-quill';
import  'quill/dist/quill.snow.css' ;

function ContractMG_update() {
	let navigate = useNavigate();

	let { id } = useParams();
    console.log(id);

	// 회원 상태관리
	let [tokenState , setTokenState] = useState(localStorage.getItem('k-Jeonse-token'));

	// 기존에 있던 내용 데이더
    let [data, setData] = useState();
	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/contractor/detail", {
			ref_estate_list_id: id
		},
		{
			headers: {
				Authorization: "Bearer " + tokenState , 
				// "Content-type": 'application/json',
			},
		})
		.then(function (response) {
			setData(response.data.data);
			console.log(response.data.data);
		})
		.catch(function (error) {
			console.log(error);
			alert("잘못된 접근입니다");
			navigate("/");
			window.location.reload();
		})
	},[])
	

	// 등록할 데이터 관리
	// 임대차 계약 리스트의 PK
	let [estate,setEstate ] = useState(id);
	// 제목
	let [title, setTitle] = useState("");
	// 내용
	let [content, setContent] = useState();
	// 업로드 파일 리스트
	let [file_list, setFile_list] = useState();
	// 기존 파일 리스트
	const [fileList, setFileList] = useState([]);
	// 삭제 파일
	let [file_remove_list, setFile_remove_list] = useState([]);

	// 기존 내용 넣기
	useEffect(()=>{
		if (data) {
			setTitle(data.detail.title);
			setContent(data.detail.content);
		}
	},[data])

	// 기존 첨부파일 데이터
	useEffect(()=>{
		initialziedFiles();
	},[data])
	const initialziedFiles = () => {
		let files = [];
		if(data) {
			data.files.map((item, idx) => {
				files.push(
					{
						id : item.id,
						uid: idx,
						name: item.originFileName,
						status: 'done',
						url: item.fileAddress,
					},
				)	
			})
		}
		console.log(files);
		setFileList(files);
	}

	// 등록할 데이터들
	let formData = new FormData();
	formData.append('id', estate);
	formData.append('title', title);
	formData.append('content', content);

	// // 새로 올린 파일
	useEffect(()=>{
		if (file_list) {
			file_list.map(item => {
				if (item.originFileObj != undefined) {
					formData.append('files[]', item.originFileObj);
				}
			})
		}

		// 삭제 파일
		file_remove_list.map(item => {
			formData.append('remove[]', item);
		})
	},[file_list])

	// // 삭제한 파일
	// useEffect(()=>{
	// 	file_remove_list.map(item => {
	// 		formData.append('remove[]', item);
	// 	})
	// 	console.log(file_remove_list)
	// },[file_remove_list])
	// function file_remove(file_id) {
	// 	if (file_id) {
	// 		console.log(file_id);
	// 		formData.append('remove[]', file_id);
	// 	}
	// 	// formData.append('remove[]', file_id);
	// }





	// 등록하기
	function Register() {
		if(title == ""){
			alert("제목을 입력해주세요")
		}else if(content == undefined){
			alert("내용을 입력해주세요")
		}else{
			axios.post("https://api.kjeonse.com/api/v1/dashboard/mypage/update/contractor", formData,
			{
				headers: {
					Authorization: "Bearer " + tokenState , 
					// "Content-type": 'application/json',
				},
			})
			.then(function (response) {
				console.log(response);
				alert("수정이 완료되었습니다");
				navigate(-1);
			})
			.catch(function (error) {
				console.log(error);
			})
			
			// navigate(-1);
		}
	}
	
	// 파일업로드
	const props = {
		name: 'file',
		beforeUpload: file => {
			return false;	// 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
		},
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			let newFileList = [...info.fileList];

			newFileList = newFileList.map((file) => {
				if (file.response) {
				  // Component will show file.url as link
					file.url = file.response.url;
				}
				return file;
			});
			setFileList(newFileList);

			if (info.file.status !== 'uploading') {
				// console.log(info.file, info.fileList);
				setFile_list(info.fileList);
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		// 파일 삭제
		onRemove(info) {
			setFile_remove_list([ ...file_remove_list, info.id]);
		}
	};
	
	// 내용 에디터
	const QuillRef  = useRef();
	/* --- Editor Image Save API [ to server ] --- */ 
    const imageHandler = () => {

        // 파일을 업로드 하기 위한 input 태그 생성
        const input = document.createElement("input");
        let url = "https://api.bio3s.com/api/" + "register/editor/image";

        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // 파일이 input 태그에 담기면 실행 될 함수 
        input.onchange = async () => {

            const file = input.files;

            if (file !== null) {

                try {

                    const res = axios.post(
                                url, 
                                {   
                                    id: data.id,
                                    type: "editor",
                                    image: file[0],
                                }, 
                                {
                                    headers: {
                                        'Authorization' : `Bearer ${tokenState}`
                                    }
                                }).then((res) => {

                                    url = res.data.data.url;
                                    const range = QuillRef.current?.getEditor().getSelection()?.index;

                                    if (range !== null && range !== undefined) {

                                        let quill = QuillRef.current?.getEditor();

                                        quill?.setSelection(range, 1);
                                        
                                        quill?.clipboard.dangerouslyPasteHTML(
                                        range,
                                        `<img src=${url} />`
                                        );

                                    }
                                });

                    return { ...res, success: true };

                } catch (error) {

                    // const err = error as AxiosError;
                    // return { ...err.response, success: false };
					console.log(error)

                }
            }
        };
    };
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { size: ["small", false, "large", "huge"] }, 
                        { color: ["red", "blue", "yellow", "pink", "black"] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    ["image", "video"],
                ],
                // handlers: {
                //     image: imageHandler,
                // },
            },
    } },[]);

	function handleChangeEditor(editor) {
        console.log('background', editor);
        setContent(editor);
    };

    return (
        <div className='Mypage charter MyRegistration'>
            <div className='MypageMould-wrap'>
				<MypageMap now="map6"></MypageMap>
			</div>
			<div className='MyContent-wrap'>
				<div className='h2-wrap'>
					<h2>전세 부동산 관리</h2>
				</div>
				<div className='MyRegistration-box w-box'>
					<div className='title-input'>
						<p>제목</p>
						<input type="text" name="" id="" value={title} placeholder='제목을 입력하세요' onChange={(e)=>{setTitle(e.target.value)}}/>
					</div>
					<div className='editor-wrap'>
						<ReactQuill theme="snow" 
							ref={(element) => {QuillRef.current = element;}}
							value={content} 
							// modules={modules} 
							onChange={handleChangeEditor} 
						/>
					</div>
					<div className='file-wrap2'>
						<h4>파일첨부</h4>
						<Upload {...props} fileList={fileList}>
							<Button icon={<UploadOutlined />}>Upload</Button>
						</Upload>
					</div>
					<div className='registration-btn-wrap'>
						<button className='cancel-btn' onClick={()=>{navigate(-1)}}>취소</button>
						<button className='completion-btn' onClick={()=>{
							Register()
						}}>수정하기</button>
					</div>
				</div>
			</div>
        </div>
    )
}

export default ContractMG_update;